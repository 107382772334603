/*
 * Return function used in Array.sort() such that
 * indexer() converts any element of the array
 * into a numbered ordinal used for sorting.
 */
export function comparator({indexer = (e) => Number(e), ascending = false} = {}) {
    return (ascending)
        ? (a, b) => indexer(a) - indexer(b)
        : (a, b) => indexer(b) - indexer(a);
}


/*
 * Split array into chunks using an array of filters.
 */
export function subdivide(array, classifiers) {
    return classifiers.map((f) => array.filter(f));
}


/*
 * Collapse an n-dimensional array into a one-dimensional array.
 */
export function flatten(array) {
    return array.reduce((flat, toFlatten) => {
        return flat.concat(Array.isArray(toFlatten)
            ? flatten(toFlatten)
            : toFlatten);
    }, []);
}

/*
 * Convert a nested iterable structure to a nested array structure.
 * By default, string are ignored.
 */
export function iterableGraphToArray(iterable, proceedIf = (e) => typeof e !== 'string') {
    return Array.from(iterable).map((e) => ((e[Symbol.iterator] && proceedIf(e))
        ? iterableGraphToArray(e, proceedIf)
        : e));
}


/*
 * Split array into an n-dimensional array of permuted elements, where
 * n = 1 + classifierList.length. Useful for heirarchial sorting when
 * paired with flatten().
 *
 * This function is more easily understood by intended use and
 * example. See unit test for both.
 */
export function decompose(array, classifierList) {
    return (classifierList.length === 0)
        ? array
        : subdivide(array, classifierList[0]).map(
            (g) => decompose(g, classifierList.slice(1)));
}


/*
 * Set operations. Beware that the order of results are not guarenteed.
 * You must sort the results yourself.
 */
export function union(...arrays) {
    return Array.from(new Set(flatten(arrays)));
}

export function intersect(arrA, arrB) {
    const subject = new Set(arrB);

    return Array.from(new Set(arrA.filter((x) => subject.has(x))));
}

export function difference(arrA, arrB) {
    const subject = new Set(arrB);

    return Array.from(new Set(arrA.filter((x) => !subject.has(x))));
}

export function unique(array, sortfn) {
    const jumble = Array.from(new Set(array));

    return (typeof sortfn === 'function')
        ? jumble.sort(sortfn)
        : jumble;
}

export function project(array, key, { allowFalsey = false } = {}) {
    const dataPredicate = (allowFalsey)
        ? (o, k) => k in o
        : (o, k) => Boolean(o[k]);

    return array
        .filter((o) => (
            typeof o === 'object' &&
            o !== null &&
            (dataPredicate(o, key))))
        .map((o) => o[key]);
}

// Copied from https://stackoverflow.com/a/6274398/394397
// Adapted to be non-destructive
export function fisherYatesShuffle(src) {
    let j;
    let x;

    const a = src.slice(0);

    for (let i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }

    return a;
}
