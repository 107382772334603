import { m, strings, harness } from '#/browser-framework';

import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';

import { Dropdown } from '#/browser-framework/comps';


const PROVIDERS = {
    'American Red Cross': 'american_red_cross',
    'American Heart Association': 'american_heart_association',
};

const PROVIDER_DISPLAY_NAMES = strings.alphabetize(Object.keys(PROVIDERS));

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            metadata: {
                description,
            },
            isValid,
        } = q;

        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(LineInputBlock, {id: 'cpr-provider'},
            description,
            m(Dropdown, harness('provider-cpr-interview-dropdown', {
                id: 'cpr-provider',
                disabled: false,
                select(i) {
                    q.provider = i;
                },
                value: q.provider,
                options: ['Select'].concat(PROVIDER_DISPLAY_NAMES),
            }))),
        m(LineInputBlock, {id: 'cpr-cert-id'},
            'Enter your CPR certificate ID',
            m('input', harness('id-cpr-interview-input', {
                value: q.cert_id,
                oninput: (e) => {
                    q.cert_id = e.target.value;
                },
            }))));
    },
};


export default function Cpr() {
    const _state = {
        view: Form,
        provider: 0,
        cert_id: '',
        encode() {
            return {
                [_state.attrType]: {
                    shareWith: _state.shareWith,
                    value: {
                        '$objectType': 'CPRData',
                        'cert_id': _state.cert_id,
                        'provider': _state.getSelectedProvider(),
                    },
                },
            };
        },

        getSelectedProvider() {
            // Subtract 1 to shift dummy "Select" value off.
            return PROVIDERS[PROVIDER_DISPLAY_NAMES[_state.provider - 1]];
        },

        isValid() {
            const validators = {
                'american_red_cross': (s) => s.length === 6,
                'american_heart_association': (s) => s.length > 0,
            };

            const validateCertId = (
                validators[_state.getSelectedProvider()] || (() => false)
            );

            return (
                _state.provider > 0 &&
                validateCertId(_state.cert_id)
            );
        },
    };

    return _state;
}
