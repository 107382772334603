import { m, router } from '#/browser-framework';

import { Controls, PrimaryButton, SecondaryButton } from '#/ido-lib/views/InterviewControls';
import { IdScanImageCaptureFront } from '#/ido-lib/views/images';

import { MobileIdScanForm } from './MobileIdScanForm';
import { CountryDropdown } from './CountryDropdown';
import { ChangeDocumentControl } from './ChangeDocumentControl';


export const MobileDocumentSelection = {
    view({
        attrs: {
            q,
        },
    }) {
        return m('.MobileDocumentSelection',
            m(IdScanImageCaptureFront),
            m(CountryDropdown, { q }),
            m(Controls,
                m(SecondaryButton, {
                    onpress() {
                        router.go('/overview');
                    },
                }),
                m(PrimaryButton, {
                    disabled: !q.countrySelected(),
                    onpress() {
                        q.workflowView = MobileIdScanForm;
                    },
                }, 'Continue')),
            (q.mayChangeDocumentType) && m(ChangeDocumentControl, { q }));
    },
};
