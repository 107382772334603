import { blobs, harness, m, spa } from '#/browser-framework';
import { FileDropbox, Named, pointable, Snackbar } from '#/browser-framework/comps';

import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';


/*
Shows a file's thumbnail (if available), name and an icon used to
remove the file from memory.
*/
const FilePreview = {
    view({
        attrs: {
            submitting,
            removeFile,
            fileStore: {
                filename,
                dataUrl,
                mimeType,
            },
        },
    }) {
        const uploadDone = dataUrl.length > 0 && !submitting;

        const mimeT = mimeType || 'unknown';

        const Icon = m(Named.Icon, {name: (uploadDone) ? 'delete' : 'cancel'});
        const previewIcon = (mimeT.startsWith('image'))
            ? m('img.FilePreview__image', {src: dataUrl})
            : m(Named.Icon, {name: 'file'});

        return m('.FilePreview',
            m('span.FilePreview__display', previewIcon),
            m('span.FilePreview__fileName',
                filename,
                !uploadDone && m('.IndefiniteProgressBar')),
            !submitting && m(pointable('span.FilePreview__removeIcon'), {
                onpointerup: () => {
                    removeFile();
                },
            }, Icon));
    },
};

const FileInput = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            submitting,
            files,
            acceptedExtensions,
            metadata: {
                description,
            },
        } = q;

        // Reversing the list places the most recent upload at the top.
        const previews = files.slice(0).reverse().map((fileStore, index) => {
            const fileOrdinal = files.length - 1 - index;

            return m(FilePreview, {
                submitting,
                index: fileOrdinal,
                removeFile() {
                    q.removeFromUploads(fileOrdinal);
                },
                fileStore,
            });
        });

        const upcased = acceptedExtensions.map((ext) => ext.toUpperCase());
        const formats = `${upcased.slice(0, -1).join(', ')}, and ${upcased[upcased.length - 1]}`;

        const preamble = (!description || description[description.length - 1] === '.')
            ? description
            : `${description}.`;

        return m(DefaultQuestionForm, {
            maySubmit: q.maySubmit(),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m('.FileQuestion', harness('file-set-interview-input', {}),
            m('.FileQuestion__instructions',
                `${preamble} Supported file formats include ${formats}.`),
            m(FileDropbox, {
                id: 'generic-document',
                acceptedExtensions,
                onchange(fileSet) {
                    q.addFiles(fileSet);
                },
            }),
            previews.length > 0 && m('.FileQuestion__previews', previews)),
        m(Snackbar, q.snackbar));
    },
};


function stagedFile(file) {
    const self = {
        filename: file.name,
        dataUrl: '',
        usable: false,
        mimeType: null,
    };

    return blobs.fileToDataUrl(file).then(spa.redrawAfter((dataUrl) => {
        const laymanParse = dataUrl.match(/^data:([^;]+)/);

        self.dataUrl = dataUrl;
        self.mimeType = (laymanParse && laymanParse[1])
            ? dataUrl.match(/^data:([^;]+)/)[1]
            : null;

        self.usable = self.mimeType && self.dataUrl;

        return self;
    }));
}

export const IMAGE_FILE_EXTS = ['bmp', 'gif', 'jpeg', 'jpg', 'png'];
export const DOCUMENT_FILE_EXTS = [ 'doc', 'docx', 'pdf', 'txt', 'xls', 'xlsx' ];
export const IMAGE_MIME_TYPES = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png'];
export const DOCUMENT_MIME_TYPES = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
];

export const ALL_ALLOWED_FILE_EXTS = IMAGE_FILE_EXTS.concat(DOCUMENT_FILE_EXTS);
export const ALL_ALLOWED_MIME_TYPES = IMAGE_MIME_TYPES.concat(DOCUMENT_MIME_TYPES);

export const selectFileExtensionWhitelist = ({ imagesOnly = false } = {}) =>
    (imagesOnly
        ? IMAGE_FILE_EXTS
        : ALL_ALLOWED_FILE_EXTS);

export const selectMimeTypeWhitelist = ({ imagesOnly = false } = {}) =>
    (imagesOnly
        ? IMAGE_MIME_TYPES
        : ALL_ALLOWED_MIME_TYPES);

export default function FileSetUpload(inputAttr) {
    const imagesOnly = inputAttr.metadata.inputType === 'image';
    const acceptedExtensions = selectFileExtensionWhitelist({ imagesOnly });
    const mimeTypeWhitelist = selectMimeTypeWhitelist({ imagesOnly });

    const _state = {
        files: [],
        acceptedExtensions,
        view: FileInput,

        encode() {
            const imageContent = _state
                .files
                .filter(({ mimeType }) =>
                    mimeTypeWhitelist.indexOf(mimeType) > -1)
                .map((fileStore) => {
                    const { dataUrl, mimeType } = fileStore;
                    const decomp = dataUrl.split(',');

                    return {
                        content: {
                            $objectType: 'BinaryData',
                            data: decomp[1],
                            mime_type: mimeType,
                        },
                        $objectType: 'DocumentPage',
                        capture_method: null,
                        captured_at: null,
                    };
                });

            return {
                [_state.attrType]: {
                    shareWith: _state.shareWith,
                    value: {
                        $objectType: 'CapturedDocument',
                        content: imageContent,
                        description: null,
                        title: null,
                    },
                },
            };
        },

        addFiles: spa.redrawAfter((files) => {
            const vowStaged = Promise.all(files.map((f) => stagedFile(f)));

            return vowStaged.then((staged) => {
                const ok = staged.filter(({ usable }) => usable);
                const failedNames = staged
                    .filter(({ usable }) => !usable)
                    .map(({ filename }) => filename);

                _state.files.push(...ok);

                if (failedNames.length > 0) {
                    _state.snackbar.display(`The following files are empty, corrupted
                        or unsupported: ${failedNames.join(', ')}.`, false);
                }
            });
        }),

        removeFromUploads(index) {
            _state.files.splice(index, 1);
        },

        isValid() {
            return _state.files.every(({dataUrl}) => {
                return dataUrl.length > 0;
            });
        },
    };

    _state.snackbar = Snackbar.makeViewModel();

    return _state;
}
