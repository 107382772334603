import m from 'mithril';
import debounce from 'debounce';
import { tx, harness, pm } from '../taxonomy';
import { pointable } from './decorators';

// Return event handler tied to button disabled state and press handler.
const _createOnPointerUpHandler = (disabled, onpress) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!disabled) {
        onpress(e);
    }
};

// Return event handler with an optional tantrum guard.
const _makeHandlerWithGuardIfNeeded = (tantrumGuardDelay, disabled, onpress) =>
    (((Number.isInteger(tantrumGuardDelay) && tantrumGuardDelay > 0)
        ? debounce(_createOnPointerUpHandler(disabled, onpress), tantrumGuardDelay, true)
        : _createOnPointerUpHandler(disabled, onpress)));

export default {
    view({
        attrs: {
            onpress,
            flags,
            disabled,
            'data-harness': dh = '',

            // Delay, in milliseconds, that must elapse before a new onpress event fires.
            // Feature is only enabled for positive integer values, so setting this to
            // zero, null, etc. will allow every pointerup event to call onpress.
            tantrumGuardDelay = 200,
        },
        children,
    }) {
        const buttonAttrs = {
            onclick(e) {
                e.preventDefault();
                e.stopPropagation();
            },
            ontouchend(e) {
                e.preventDefault();
                e.stopPropagation();
            },
            onpointerup: _makeHandlerWithGuardIfNeeded(
                tantrumGuardDelay,
                disabled,
                onpress),
        };

        const modifier = pm(Object.assign({ disabled }, flags));

        return m(pointable(tx('Button', `Button--${modifier}`)), harness(dh, buttonAttrs), children);
    },
};
