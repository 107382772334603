import * as objects from '#/universal-framework/objects';


export const specializedConsentRegex = /^consent\./;

// Transforms consent.fcra.* namespace
export function curateConsent(aggregatedRequest) {
    const inputAttributes = aggregatedRequest.input;
    const namespace = 'consent.fcra';
    const contained = (k) => k.startsWith(namespace);
    const types = Object.keys(inputAttributes);

    const fcraAttributeTypes = types.filter(contained);

    if (fcraAttributeTypes.length > 0) {
        if (!(aggregatedRequest.consentHtml['consent.fcra.reviewed_disclosure'] && aggregatedRequest.consentHtml['consent.fcra.authorized'])) {
            delete inputAttributes['consent.fcra.authorized'];
            if (inputAttributes['consent.fcra.reviewed_disclosure'].metadata && inputAttributes['consent.fcra.reviewed_disclosure'].metadata.title) {
                inputAttributes['consent.fcra.reviewed_disclosure'].metadata.title = 'Background Check Disclosure and Authorization Agreement';
            }
        }

        if (inputAttributes['consent.fcra.requested_free_copy_state']) {
            delete inputAttributes['consent.fcra.requested_free_copy_state'];
        }

        const filtered = objects.keyExclude(inputAttributes, fcraAttributeTypes);
        // Magic hack to overwrite the curated consent.fcra step if a more specialized FCRA attribute exists.

        /*
        Specialized Consent Input Attribute Pattern
            * `consent.{whatever}.fcra`
        Generic Consent Input Attribute Pattern
            * `consent.fcra`
        */
        aggregatedRequest.input = (Object.keys(filtered).some((k) => specializedConsentRegex.test(k)))
            ? objects.keyExclude(filtered, ['consent.fcra'])
            : aggregatedRequest.input;
    }
    return aggregatedRequest;
}
