import { errors, router, spa, support } from '#/browser-framework';

import { appController } from './controllers/app';

import '#/ido-facing/screen.less';


spa.start(() => {
    const app = appController();

    // We need local storage to refresh the page anywhere.
    if (support.localStorage) {
        spa.viewModel = app;

        router.start({
            titleBrand: 'Evident',
            titleSeparator: ' - ',
            fallback() {
                router.go('/');
            },
        });
    } else {
        spa.error = errors.exception({
            reason: 'local-storage-required',
        });

        app.errors.goToErrorPage();
    }
});
