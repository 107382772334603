import { interviewModel } from './interviewModel';
import { contentModel } from './contentModel';
import { aggregate } from './aggregation';
import { curate } from './curation';
import { actuate, makeWorstCase } from './actuation';


// Returns a new interviewModel using service-level data.
export function buildInterviewByPipeline(verificationRequest, identity) {
    verificationRequest.consentHtml = identity.consentHtml;
    return actuate(curate(aggregate(verificationRequest))).then((requirements) => {
        const interview = interviewModel();

        interview.content = contentModel(requirements, identity || {});
        interview.questions = requirements.input;

        interview.consentGiven = requirements.consent;
        interview.balance = requirements.balance;

        return interview;
    });
}


// Returns a new interviewModel using service-level data.
export function buildInterview(identityAndRequest) {
    if (deploy.WEB_PUBLIC_DEVELOPER_MODE) {
        return makeWorstCase().then((mockedRequirements) => {
            const interview = interviewModel();

            interview.content = contentModel(
                {
                    summary: 'DEVELOPER MODE',
                    description: 'Request description',
                },
                {
                    displayName: 'RP display name',
                    idoConfirmationMessage: {
                        heading: 'Confirmation heading',
                        message: 'Confirmation message',
                    },
                    primaryColor: '#000',
                    wordmarkUri: '',
                }
            );

            interview.questions = mockedRequirements.input;

            interview.consentGiven = mockedRequirements.consent;
            interview.balance = mockedRequirements.balance;

            return interview;
        });
    } else {
        const {
            verificationRequest,
            identity,
        } = identityAndRequest;

        return buildInterviewByPipeline(verificationRequest, identity);
    }
}
