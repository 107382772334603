import {m} from '#/browser-framework';


export default {
    initialsFromName(fullname) {
        if (typeof fullname !== 'string' || fullname.length === 0) {
            return ['?', '?'];
        } else {
            const names = fullname.split(' ');

            if (names.length === 1) {
                return [names[0][0], '?'];
            } else {
                return [
                    (names[0])
                        ? names[0][0]
                        : '?',
                    (names[names.length - 1])
                        ? names[names.length - 1][0]
                        : '?',
                ];
            }
        }
    },

    view({attrs: {
        firstInitial = '?',
        lastInitial = '?',
        headshot,
        diameter = 36,
        bgcolor = '#730',
    }}) {
        return m('span.Avatar', {
            style: {
                'border-radius': '999px',
                'color': 'white',
                'background-size': 'cover',
                'font-family': 'monospace',
                'user-select': 'none',
                'text-align': 'center',
                'text-transform': 'uppercase',
                'font-size': '0px',
                'position': 'relative',
                'padding': `${diameter / 2}px`,
                'background-color': (headshot)
                    ? 'transparent'
                    : bgcolor,
                'background-image': (typeof headshot === 'string')
                    ? `url(${headshot})`
                    : 'none',
            },
        }, m('.Avatar__initials', {
            style: {
                'position': 'absolute',
                'font-size': `${Math.floor(diameter / (8 / 3))}px`,
                'left': '50%',
                'top': '50%',
                'display': 'block',
                'transform': 'translate(-50%, -50%)',
            },
        }, (headshot) ? null : `${firstInitial}${lastInitial}`));
    },
};
