import { logger } from '#/universal-framework';
import load from './loader';

async function loadSentrySdk() {
    if (window.Sentry) {
        throw new Error('Sentry already bound');
    }
    await load({
        script: 'https://browser.sentry-cdn.com/5.2.0/bundle.min.js',
        setup(scriptElement) {
            scriptElement.setAttribute('crossorigin', 'anonymous');
        },
    });
    return window.Sentry;
}

async function bindSentry(dsn, config = {}) {
    const sentry = await loadSentrySdk();
    sentry.init({
        dsn,
        ...config,
    });
    logger.setSentry(sentry);
}

logger.bindSentry = bindSentry;

export default logger;
