import m from 'mithril';

import { americentrism, loadCountryData } from '#/universal-framework/countries';
import { bem } from '#/browser-framework/taxonomy';

import Dropdown from './Dropdown';


const btx = bem`
    AddressForm
        city
        country
        state
        street1
        street2
        postalcode`;


export default {
    oncreate: ({ state }) =>
        loadCountryData()
            .then((countries) => {
                state.countries = americentrism(countries.map(Object.values));
                m.redraw();
            }),
    onchange: (attrs, name, value) => {
        attrs.onchange(Object.assign({
            $objectType: 'Address',
            street_number: '',
            street_name: '',
            street_unit: '',
            neighborhood: '',
            city: '',
            state: '',
            postal_code: '',
            postal_code_suffix: '',
            county: '',
            country: '',
        }, {
            [name]: value,
        }));
    },
    view({
        attrs: {
            addressLineOne = '',
            addressLineTwo = '',
            city = '',
            state = '',
            postalCode = '',
            assumeCountry,
            countries,
            selectedCountryIndex,
        },
        state: vnodeState,
    }) {
        const countryOptions = (countries || vnodeState.countries) || [];

        return m(btx.block,
            m(`input${btx.street1}`, {
                placeholder: 'Street Address',
                value: addressLineOne,
            }),
            m(`input${btx.street2}`, {
                placeholder: 'Apartment, suite, unit, building, floor, etc.',
                value: addressLineTwo,
            }),
            m(`input${btx.city}`, {
                placeholder: 'City',
                value: city,
            }),
            m(`input${btx.postalcode}`, {
                placeholder: 'ZIP / Postal Code',
                value: postalCode,
            }),
            m(`input${btx.state}`, {
                placeholder: 'State / Province',
                value: state,
            }),
            (!assumeCountry) &&
                m(Dropdown, {
                    placeholder: (countryOptions.length === 0)
                        ? 'Loading...'
                        : 'Select a Country',
                    options: countryOptions,
                    value: selectedCountryIndex,
                }));
    },
};
