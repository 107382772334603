import { m } from '#/browser-framework';
import pointable from './pointable';


/*
 * A reinvented checkbox that allows you to stop wasting time trying to
 * hack custom native HTML checkboxes. The caveat to cosmetic freedom
 * is the need to handle tab indexes and focus state yourself.
 */
export default function toggle(comp, {bias = true} = {}) {
    const trigger = pointable('span');

    return {
        view({attrs, children}) {
            const {
                state,
                onchange,
                onfocus,
                onblur,
                onhover,
            } = attrs;

            const shallow = Object.assign({}, attrs);

            delete shallow.onchange;
            delete shallow.onfocus;
            delete shallow.onblur;
            delete shallow.onhover;

            return m(trigger, {
                onfocus,
                onblur,
                onhover,
                onpointerup: () => {
                    onchange((state === undefined)
                        ? bias
                        : !(state));
                },
            }, m(comp, shallow, children));
        },
    };
}

