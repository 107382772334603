import m from 'mithril';

import { purecomp } from '#/browser-framework/vcomps';
import { Named } from '#/browser-framework/comps';
import { bem } from '#/browser-framework/taxonomy';

const PaginatedBtx = bem`PaginatedView nav title arrow arrow--active arrow--inactive`;

const pageNav = purecomp(({
    unit,
    pages,
    activePageIndex,
    nextPage,
    previousPage,
    isNextPageAvailable,
    isPreviousPageAvailable,
}) =>
    m(PaginatedBtx.nav,
        m(isPreviousPageAvailable
            ? `button${PaginatedBtx.arrow}${PaginatedBtx['arrow--active']}`
            : `button${PaginatedBtx.arrow}${PaginatedBtx['arrow--inactive']}`, {
            onclick: () => {
                if (isPreviousPageAvailable) {
                    // eslint-disable-next-line no-param-reassign
                    previousPage(activePageIndex);
                }
            },
        }, m(Named.Icon, {
            name: 'chevron-left',
        })),
        m(PaginatedBtx.title, `${unit} ${activePageIndex + 1} of ${pages.length}`),
        m(isNextPageAvailable
            ? `button${PaginatedBtx.arrow}${PaginatedBtx['arrow--active']}`
            : `button${PaginatedBtx.arrow}${PaginatedBtx['arrow--inactive']}`, {
            onclick: () => {
                // eslint-disable-next-line no-param-reassign
                if (isNextPageAvailable) {
                    nextPage(activePageIndex);
                }
            },
        }, m(Named.Icon, {
            name: 'chevron-right',
        }))));

export const PaginatedView = {
    view({
        attrs: {
            activePageIndex = 0,
            pages = [],
            unit = 'Page',
            showBottomNav = true,
            nextPage = () => {},
            previousPage = () => {},
            isPreviousPageAvailable = (activePageIndex > 0),
            isNextPageAvailable = (activePageIndex + 1 < pages.length),
        },
    }) {
        const displayNavigation = pages.length > 1;
        const displayBottomNavigation = showBottomNav && displayNavigation;
        return m('.PaginatedView',
            displayNavigation
                ? m(pageNav, {
                    unit,
                    pages,
                    activePageIndex,
                    nextPage,
                    previousPage,
                    isNextPageAvailable,
                    isPreviousPageAvailable})
                : '',
            pages[activePageIndex],
            displayBottomNavigation
                ? m(pageNav, {
                    unit,
                    pages,
                    activePageIndex,
                    nextPage,
                    previousPage,
                    isNextPageAvailable,
                    isPreviousPageAvailable})
                : '');
    },
};

export default PaginatedView;
