const {
    createBinaryDataFromDataUrl,
    createBinaryDataFromFile,
    createDocument,
    createDocumentPage,
    createDocumentPageFromDataUrl,
    createIdDocumentMetadata,
} = require('#/evident-attributes/encoding/documents');

const {
    simplex,
} = require('#/evident-attributes/encoding/id-scanning');

const {
    deslugAttrType,
    doesAttrTypeRepresentIdScan,
    slugAttrType,
} = require('#/evident-attributes/attrTypes');

const { expose } = require('#/browser-framework/lib-delivery');

const submitClient = require('./submitapi-client');

const AssureSDK = {
    setUp: submitClient.setUp,
    client: submitClient.client,
    ENVIRONMENTS: submitClient.ENVIRONMENTS,
    createBinaryDataFromDataUrl,
    createBinaryDataFromFile,
    createDocument,
    createDocumentPage,
    createDocumentPageFromDataUrl,
    createIdDocumentMetadata,
    deslugAttrType,
    doesAttrTypeRepresentIdScan,
    simplex,
    slugAttrType,
    submitAttributes: function submitAttributes(data, { xhr = submitClient.client } = {}) {
        return xhr({
            method: 'POST',
            url: '/attributeData',
            data: {
                inputs: Object.entries(data).map(([type, value]) => {
                    return {
                        type,
                        value,
                    };
                }),
            },
        });
    },
};

// Delivery
module.exports = AssureSDK;
expose('AssureSDK', AssureSDK);
