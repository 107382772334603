import { m } from '#/browser-framework';

import { MobileSimplexFormFront, MobileSimplexFormPassportIdPage } from './MobileSimplexForms';
import { ChangeDocumentControl } from './ChangeDocumentControl';


// Input form to upload an ID on a device with accomodating screen space.
export const MobileIdScanForm = {
    view({ attrs }) {
        const { q } = attrs;
        const Form = (q.usingPassport())
            ? MobileSimplexFormPassportIdPage
            : MobileSimplexFormFront;

        return m('.MobileIdScanForm',
            m(Form, attrs),
            (q.mayChangeDocumentType) && m(ChangeDocumentControl, { q }));
    },
};
