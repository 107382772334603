import { m } from '#/browser-framework';

// Rule disabled to make text elements easier to manipulate when not wrapping.
/* eslint-disable max-len */


const WHITE = 'fill:#fff';
const RED = 'fill:#f15946';
const GRAY = 'fill:#bcbebf; mix-blend-mode:multiply';

export default {
    view: () => m('svg', {xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 349.83 71.21'}, [
        m('rect', { style: WHITE, x: '19.68', y: '12.63', width: '49.14', height: '49.14', rx: '1.8', ry: '1.8', transform: 'translate(39.27 -20.39) rotate(45)'}),
        m('rect', { style: RED, x: '9.43', y: '12.63', width: '49.14', height: '49.14', rx: '1.8', ry: '1.8', transform: 'translate(36.27 -13.15) rotate(45)'}),
        m('path', { style: GRAY, d: 'M39.13,7.58,10.78,35.93a1.8,1.8,0,0,0,0,2.55L39.13,66.83,67.48,38.48a1.8,1.8,0,0,0,0-2.55Z'}),
        m('path', { style: WHITE, d: 'M128.39,20.22H109.67V31.91H126v10H109.67V54.43h20V64.78H95.06V9.87H129.9Z'}),
        m('path', { style: WHITE, d: 'M157.39,64.78H140.72l-13.11-42.1h15.25l6.32,31.76L156,22.67h14.3Z'}),
        m('path', { style: RED, d: 'M186.54,7.74a8.06,8.06,0,0,1-16.11,0,8.06,8.06,0,0,1,16.11,0Zm-1,57H171.45V22.67h14.06Z'}),
        m('path', { style: RED, d: 'M230.78,6.08V64.78H218.3l-.55-4.66a14,14,0,0,1-11.93,6.08c-10.82,0-16-8.93-16-22.59,0-13,6.87-22.35,17.38-22.35a13.32,13.32,0,0,1,9.48,3.63V4.66ZM216.72,51.9V34.28c-1.66-1.82-3.24-2.92-5.53-2.92-3.95,0-6.87,3.32-6.87,12.32,0,10.27,2.61,12.4,6.08,12.4C212.92,56.09,215,54.74,216.72,51.9Z'}),
        m('path', { style: WHITE, d: 'M274.78,47.79H249c.79,6.79,4,8.37,8.93,8.37,3.24,0,6.16-1.18,9.8-3.71l5.69,7.74a26.12,26.12,0,0,1-16.83,6c-14.77,0-21.88-9.24-21.88-22.28,0-12.48,6.87-22.67,20.3-22.67,12.24,0,20,7.9,20,21.8C275,44.4,274.86,46.37,274.78,47.79ZM261.19,39.1c-.08-5.06-1.42-8.77-5.92-8.77-3.71,0-5.77,2.21-6.32,9.24h12.24Z'}),
        m('path', { style: WHITE, d: 'M316.17,33.89V64.78H302.11V36.42c0-3.87-1.26-4.9-3.16-4.9-2.29,0-4.34,1.66-6.32,4.82V64.78H278.57V22.67h12.24l1,4.74c3.71-4.19,7.66-6.16,12.8-6.16C311.75,21.25,316.17,25.83,316.17,33.89Z'}),
        m('path', { style: WHITE, d: 'M349.83,62.72a22.08,22.08,0,0,1-12,3.48c-10,0-14.61-5.61-14.61-16.12V32.31h-5.45V22.67h5.45V13.75l14.06-1.66V22.67h9.56l-1.42,9.64h-8.14V49.93c0,3.95,1.34,5.21,3.63,5.21A8.33,8.33,0,0,0,345.17,54Z'}),
        m('path', { style: WHITE, d: 'M34.65,46.31h0a2.5,2.5,0,0,1-1.77-.74l-6-6.08A2.5,2.5,0,0,1,30.43,36l4.23,4.29L45.85,29.12a2.5,2.5,0,1,1,3.53,3.54l-13,12.93A2.5,2.5,0,0,1,34.65,46.31Z'}),
    ]),
};
