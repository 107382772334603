import { bem, m, router, spa } from '#/browser-framework';
import { pointable, Snackbar } from '#/browser-framework/comps';

import GoogleButtonSvg from '#/ido-lib/images/google-login-button.svg';


const GoogleLoginButton = {
    view() {
        return m('.GoogleLoginButton',
            m(pointable('img'), {
                src: GoogleButtonSvg,
                onpointerup() {
                    const prefix = deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX;
                    const qs = '?ido_domain_name=googlelocalsvcs.googleauth.thirdparty.idowner.evidentid.com';
                    window.location.assign(
                        `${prefix}/authgoogle${qs}`);
                },
            }));
    },
};

const Hero = {
    btx: bem`Hero
        copy
        highlight
        heading
        subheading
    `,
    view() {
        const { btx } = Hero;

        return m(btx.block,
            m(btx.copy,
                m(btx.heading, 'Take control of', m(btx.highlight, ' your '), 'online identity.'),
                m(btx.subheading,
                    'The power to decide who gets access to your personal information and when.')));
    },
};

const LoginControls = {
    view() {
        return m('.LoginControls',
            `If you've been directed here as part of a request to
                    verify personal information with a business, please
                    sign in with your Google account to get started.`,
            m(GoogleLoginButton),
        );
    },
};

const glsLinks = {
    view() {
        return m('nav.glsLinks',
            m('a', { href: 'https://www.evidentid.com/own-your-identity/' }, 'Learn More'),
            m('span', '|'),
            m('a', { href: 'https://www.evidentid.com/privacy-policy/' }, 'Privacy Policy'));
    },
};

const LandingPage = {
    btx: bem`LandingPage
        hero
        links
        interface
        brand
    `,
    view({
        attrs: {
            viewModel: {
                snackbar,
            },
        },
    }) {
        const { btx } = LandingPage;

        return m(btx.block,
            m(btx.hero,
                m(Hero)),
            m(btx.brand),
            m(btx.interface,
                m(LoginControls)),
            m(btx.links,
                m(glsLinks)),
            m(Snackbar, snackbar));
    },
};


export function googleBizController() {
    router.on('Welcome', '/google-login', () => {
        spa.setView(LandingPage);
    });
}
