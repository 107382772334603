import { m, tx, bem, expose, router, support, harness } from '#/browser-framework';
import { Named } from '#/browser-framework/comps';
import { Controls, PrimaryButton, SecondaryButton } from './InterviewControls';
import { materialLogout } from './materialLogout/materialLogout';
import EvidentWordmarkDarkSvg from '#/browser-framework/images/evident-wordmark-dark.svg';


export const SingleCol = {
    btx: bem`SingleCol header branding menu body`,
    view({
        children,
    }) {
        const { block, body, header, branding } = SingleCol.btx;

        return m(block,
            m(header,
                m(`img${branding}`, {
                    alt: 'Evident ID Logo',
                    src: EvidentWordmarkDarkSvg,
                })),
            m(body, ...children));
    },
};

export const InterviewCard = {
    btx: bem`InterviewCard header wordmark message body logout`,
    view({
        attrs: spa,
        children,
    }) {
        const {
            viewModel: {
                interview: {
                    getSelectedQuestionType,
                    areAllRequirementsMet,
                    interviewModel,
                },
            },
        } = spa;

        if (!interviewModel) {
            return null;
        }

        const {
            content,
        } = interviewModel;

        const done = areAllRequirementsMet();

        const headerStyles = {
            style: {
                'border-bottom': `3px solid ${content.brandingColor}`,
            },
        };

        const messageStyles = {
            style: {
                'text-align': (done)
                    ? 'right'
                    : 'left',
            },
        };

        const { block, header, wordmark, message, logout, body } = InterviewCard.btx;
        const questionType = getSelectedQuestionType();

        if (support.isMobileBrowser && !content.wordmarkUri) {
            return m(SingleCol, m(block, m(tx(body, { done }), children)));
        }

        return m(SingleCol, spa, m(block,
            m(header, harness('interview-card-heading-style', headerStyles),
                (content.wordmarkUri) && m(wordmark, m('img', harness('interview-card-heading-wordmark-image', {
                    src: content.wordmarkUri,
                    alt: content.dbaNameText,
                }))),
                (support.isMobileBrowser)
                    ? m.trust('&nbsp;')
                    : m(message,
                        messageStyles,
                        content.getHeadingText(done)),
                spa.viewModel.user.inGoogleFlow()
                    ? m(logout,
                        m(materialLogout, spa),
                        m(Named.Icon, {name: 'caret-down'}))
                    : ''),
            m(tx(body), expose({done}, (questionType) ? { 'question-type': questionType } : {}),
                children)));
    },
};

export const DefaultQuestionForm = {
    view({
        attrs: {
            maySubmit,
            onSubmit,
            onLastQuestion,
        },
        children,
    }) {
        return m('.DefaultQuestionForm',
            children,
            m(Controls,
                m(SecondaryButton, {
                    onpress() {
                        router.go('/overview');
                    },
                }),
                m(PrimaryButton, harness('submit-question', {
                    disabled: !maySubmit,
                    onpress: onSubmit,
                    onLastQuestion,
                }))));
    },
};

export const SecondaryQuestionForm = {
    view({
        attrs: {
            buttonText,
            isDisabled,
            onPress,
            showSubmit,
            onSubmit,
            onLastQuestion,
        },
        children,
    }) {
        return m('.DefaultQuestionForm',
            children,
            m(Controls,
                m(SecondaryButton, {
                    onpress() {
                        router.go('/overview');
                    },
                }),
                m(PrimaryButton, {
                    disabled: isDisabled,
                    onpress: showSubmit ? onSubmit : onPress,
                    onLastQuestion,
                }, buttonText)));
    },
};

export const QuestionComponent = (c) => {
    return {
        view({
            attrs: spa,
        }) {
            const q = spa.viewModel.interview.getSelectedQuestion();

            return (q) ? m(c, {spa, q}) : null;
        },
    };
};
