import { m } from '#/browser-framework';

import IdScanImageCaptureFrontSvg from '#/ido-lib/images/IdScanImageCaptureFront.svg';
import IdScanImageCaptureBackSvg from '#/ido-lib/images/IdScanImageCaptureBack.svg';
import IdScanTextMessageSentSvg from '#/ido-lib/images/IdScanTextMessageSent.svg';
import IdScanImageFrontSvg from '#/ido-lib/images/IdScanImageFront.svg';
import DelegationOptionMobileSvg from '#/ido-lib/images/DelegationOptionMobile.svg';
import DelegationOptionDesktopSvg from '#/ido-lib/images/DelegationOptionDesktop.svg';

const imgComp = (cls, src) => ({
    view() {
        return m(`img${cls}`, { src });
    },
});

export const IdScanImageCaptureFront = imgComp('.IdScanVisualAid.captureFront', IdScanImageCaptureFrontSvg);
export const IdScanImageCaptureBack = imgComp('.IdScanVisualAid.captureBack', IdScanImageCaptureBackSvg);
export const IdScanImageTextMessageSent = imgComp('.IdScanVisualAid.textMessageSent', IdScanTextMessageSentSvg);
export const IdScanImageFront = imgComp('.IdScanVisualAid.IdScanImageFront', IdScanImageFrontSvg);
export const DelegationOptionMobile = imgComp('.DelegationOptionMobile', DelegationOptionMobileSvg);
export const DelegationOptionDesktop = imgComp('.DelegationOptionDesktop', DelegationOptionDesktopSvg);
