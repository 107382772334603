import { m, harness } from '#/browser-framework';

import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const { value } = q;

        return m(DefaultQuestionForm, {
            maySubmit: value.match(/^\d{7}$/),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(LineInputBlock,
            '7-digit MMC reference number',
            m('input', harness('mmc-reference-number-input', {
                value,
                oninput(e) {
                    q.value = e.target.value.substring(0, 7);
                },
            }))));
    },
};


export default function MmcReferenceNum() {
    const _state = {
        value: '',
        view: Form,
        encode() {
            return {
                [_state.attrType]: {
                    value: _state.value.replace(/\D/g, ''),
                    shareWith: _state.shareWith,
                },
            };
        },
    };

    return _state;
}
