import {strings} from '#/universal-framework';

/*
Declare classnames based on stateful expressions

tx('my-class', { 'disabled': true, selected: false }) --> '.my-class.disabled'
tx('my-class', { 'disabled': false, selected: false }) --> '.my-class'
*/
export function tx(...args) {
    return args.reduce((p, c) => {
        const normalized = (typeof c === 'string')
            ? c.split('.').map((t) => t.trim()).filter((s) => s)
            : Object.keys(c).filter((k) => c[k]);

        return `${p}.${normalized.join('.')}`;
    }, '');
}

/*
BEM-style declaractions of nodes.

const btx = bem`
MyNode
    title
    subtitle
`;


Now this:

m(btx.block,
    m(`h1${btx.title}`, 'Hello, world!'),
    m(`h2${btx.subtitle}`, 'How ya doin'))

yields this:

<div class="MyNode">
    <h1 class="MyNode__title"></h1>
    <h2 class="MyNode__subtitle"></h2>
</div>
*/
export function bem(callSite, ...values) {
    const [block, ...elements] = String
        .raw(callSite, ...values)
        .split(/\s+/)
        .filter((v) => v);

    return elements.reduce((p, e) => {
        p[e] = `.${block}__${e}`;

        return p;
    }, {
        block: `.${block}`,
    });
}

/*
Declares a single CSS class that is a unique expression of state.

pm({ focus: 0, disabled: 0, hover: 0 }) --> ''
pm({ focus: 0, disabled: 0, hover: 1 }) --> 'hover'
pm({ focus: 0, disabled: 1, hover: 0 }) --> 'disabled'
pm({ focus: 0, disabled: 1, hover: 1 }) --> 'disabled-hover'
pm({ focus: 1, disabled: 0, hover: 0 }) --> 'focus'
pm({ focus: 1, disabled: 0, hover: 1 }) --> 'focus-hover'
pm({ focus: 1, disabled: 1, hover: 0 }) --> 'disabled-focus'
pm({ focus: 1, disabled: 1, hover: 0 }) --> 'disabled-focus-hover'

Useful for buttons, fields or other custom controls where you cannot
use native state selectors and need to avoid CSS specificity nightmares.
*/
export function pm(...modifiers) {
    return strings.alphabetize(tx(...modifiers).split('.').slice(1)).join('-');
}


/*
Classify a node as accessible to tests. See the rules for harnesses
in the browser-gui-evaluator README.
*/
export function harness(harnessName, attrs = {}) {
    const enabled = Boolean(deploy.WEB_PUBLIC_EVALUATING);

    if (!attrs['data-harness'] && enabled) {
        attrs['data-harness'] = harnessName;
    }

    return attrs;
}


/*
Augment attributes for a node with data accessible to tests.

Usage with harnesses:

const forTesting = {
    foo: 'bar',
};

m(tx('MyForm'), harness('address-form', expose({}, forTesting)))

// This yields...

<div class="MyForm" data-harness="address-form" data-test-foo="bar">
</div>
*/

export function expose(attrs, data) {
    if (deploy.WEB_PUBLIC_EVALUATING) {
        Object.entries(data).reduce((p, [k, v]) => {
            p[`data-test-${k}`] = v;
            return p;
        }, attrs);
    }
    return attrs;
}
