import { bem, m } from '#/browser-framework';
import { Snackbar } from '#/browser-framework/comps';

import { DelegationOptionDesktop, DelegationOptionMobile } from './images';
import { Controls, SecondaryButton } from './InterviewControls';
import { PhoneDelegationInput } from './PhoneDelegationInput';


const btx = bem`
LargeFormatDelegation
    title
    subtitle
    instructions
    methods
    method
    retryText
`;

const DelegationControls = {
    view({
        attrs: {
            q,
        },
    }) {
        return m(Controls,
            m(SecondaryButton, {
                onpress() {
                    q.cancelDelegation();
                },
            }, 'Never mind, take me back to the request'),
            m('div',
                m(`p${btx.retryText}`, 'Didn\'t get a text? Try again.'),
                m(PhoneDelegationInput, {
                    id: 'id-delegation-phone',
                    delegationController: q.delegate,
                    onDelegationRequest: q.delegateToMobile,
                    buttonLabel: 'Send',
                })));
    },
};


const ContinueHereOption = {
    view({
        attrs: {
            q,
        },
    }) {
        return m(btx.method,
            m(DelegationOptionDesktop),
            m(`h3${btx.subtitle}`, `You can come back here to
            complete the request on this device.`),
            m(`p${btx.instructions}`, `Follow the instructions in the text message
            you received to finish scanning and uploading the
            requested document. Hit the `, m('strong', m('em', 'Save')), ` button on your
            mobile phone, then `,
            m('a', {
                onclick() {
                    q.cancelDelegation();
                },
            }, 'click here to continue on this device.')),
        );
    },
};

const ContinueOnMobileOption = {
    view() {
        return m(btx.method,
            m(DelegationOptionMobile),
            m(`h3${btx.subtitle}`, `Or you can just finish the rest of the
            request directly on your mobile device.`),
            m(`p${btx.instructions}`, `Just follow the instructions to scan and upload
            the requested document from your mobile device, complete
            any other requested steps, and hit the Submit button on
            your mobile device. You can close this page at any time.`),
        );
    },
};


// Input form to upload an ID on a device with accomodating screen space.
export const LargeFormatDelegation = {
    view({ attrs }) {
        const { q } = attrs;

        return m(btx.block,
            m(`h2${btx.title}`, 'We sent you a text! Here\'s what to do next.'),
            m(btx.methods,
                m(ContinueHereOption, attrs),
                m(ContinueOnMobileOption, attrs)),
            m(DelegationControls, attrs),
            m(Snackbar, q.delegate.snackbar));
    },
};
