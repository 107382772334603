import { m, harness } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';

import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';
import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import US_SUBDIVISIONS from '#/ido-lib/states.json';

const STATES_AND_TERRITORIES = US_SUBDIVISIONS
    .sort(([a], [b]) => a.localeCompare(b));

// Include D.C. as a driver's license state. D.C. is not actually a state,
// but D.C. issues its own licenses.

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const { value } = q;

        return m(DefaultQuestionForm, {
            maySubmit: Boolean(STATES_AND_TERRITORIES[value]),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(LineInputBlock,
            'What state issued your driver\'s license?',
            m(Dropdown, harness('dl-state-dropdown', {
                id: 'dl-state',
                disabled: false,
                value,
                options: STATES_AND_TERRITORIES.map(([, expanded]) => {
                    return expanded;
                }),
                select(i) {
                    q.value = i;
                },
            }))));
    },
};


export default function DriversLicenseState() {
    const _state = {
        value: -1,
        view: Form,
        encode() {
            const LETTER_CODE_INDEX = 1;
            return {
                [_state.attrType]: {
                    value: STATES_AND_TERRITORIES[_state.value][LETTER_CODE_INDEX],
                    shareWith: _state.shareWith,
                },
            };
        },
    };

    return _state;
}
