import { m } from '#/browser-framework';

// Parametrizes parents for layout diffing purposes.
export default {
    view({
        attrs,
        children,
    }) {
        const shallow = Object.assign({}, attrs);

        delete shallow.parent;

        return (attrs.parent)
            ? m(attrs.parent, shallow, children)
            : children;
    },
};
