import {m, bem} from '#/browser-framework';


const { block, header, body, link } = bem`PrivacyNotice header body link`;


export const PrivacyNotice = {
    view() {
        return m(block,
            m(`h3${header}`, 'Your privacy is important to us.'),
            m(`p${body}`,
                m.trust(`Often the response shared with the requesting third party will be a
                &ldquo;Yes&rdquo; or &ldquo;No&rdquo; regarding the authenticity of your submission.
                This is done to protect your personal information.`),
                m.trust('&nbsp;'), // Make space explicit and obvious.
                m(`a${link}`, {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: 'https://www.evidentid.com/own-your-identity/',
                }, 'Learn more.')));
    },
};
