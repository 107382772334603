import { router, spa } from '#/browser-framework';

import { setUp, getIdoProfile, tearDown } from '#/ido-lib/idoWebClient';


export function ido() {
    const iface = {};

    iface.email = '';
    iface.authDomain = '';

    iface.isLoggedIn = function isLoggedIn() {
        return Boolean(iface.email);
    };

    iface.inGoogleFlow = function inGoogleFlow() {
        return iface.authDomain.startsWith('googlelocalsvcs.googleauth');
    };

    iface.logIn = function logIn(session) {
        setUp(session, spa.$window);
        return getIdoProfile().then(({ email, authDomain }) => {
            iface.email = email;
            iface.authDomain = authDomain;
        });
    };

    iface.googleLogOut = function googleLogOut() {
        // TODO: Add support for per domain error pages
        return tearDown().then(() => {
            iface.email = iface.authDomain = '';
            if (iface.inGoogleFlow()) {
                router.go('/google-login');
            } else {
                spa.$window.location.href = 'https://www.evidentid.com/registration-not-found-google/';
            }
        });
    };

    return iface;
}
