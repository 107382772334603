import { m, harness, router } from '#/browser-framework';
import { ConsentCheckbox } from '#/browser-framework/comps';
import { Controls, PrimaryButton, SecondaryButton } from '#/ido-lib/views/InterviewControls';

/*
 * A custom checkbox below the FCRA Authorization and Disclosure form.
 */

export const stateResidentNoticeRegEx = /^consent\..+\.state_resident_notice$/;

const AuthorizationCheckbox = {
    view({ attrs: { authorized, onConsentChange } }) {
        return m(ConsentCheckbox, harness('consent-checkbox', {
            id: 'a-and-d',
            checked: authorized,
            onchange: onConsentChange,
            cssclass: 'ConsentQuestion__acceptCheck',
        }), 'I have read and accept this disclosure.');
    },
};

const Form = {
    view({
        attrs: {
            spa,
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const { consented, encode, onRequestFreeCopy, onConsentChange, requestingFreeCopy } = q;
        const { consentHtml, DefaultLegalease, dbaName, dbaNameText } = spa.viewModel.interview.interviewModel.content;

        const consentCheckbox = m('.ConsentQuestion__optionalNotice',
            m('p', { style: { 'margin-bottom': '1rem' } }, `
            California, Minnesota or Oklahoma applicants only: You
            may receive a free copy of any consumer report or
            investigative consumer report obtained on you if you
            check the box below.`),

            m(ConsentCheckbox, harness('request-free-copy-checkbox', {
                id: 'free-report',
                checked: requestingFreeCopy,
                onchange: onRequestFreeCopy,
            }), 'I wish to receive a free copy of the report'));
        return m('.Consent',
            m('h3', q.metadata
                ? q.metadata.title
                : m.trust('Background Check Disclosure &amp; Authorization')),
            m('.ConsentQuestion__language',
                (consentHtml && consentHtml[q.attrType] && m.trust(consentHtml[q.attrType].data))
                || (consentHtml && consentHtml['consent.fcra'] && m.trust(consentHtml['consent.fcra'].data))
                || m(DefaultLegalease, { relyingPartyLegalName: dbaName || dbaNameText }),

                q.attrType === 'consent.fcra.reviewed_disclosure'
                || q.attrType.match(stateResidentNoticeRegEx)
                    ? consentCheckbox
                    : ''),
            m(AuthorizationCheckbox, {
                authorized: consented,
                onConsentChange,
            }),
            m(Controls,
                m(SecondaryButton, {
                    onpress() {
                        router.go('/overview');
                    },
                }),
                m(PrimaryButton, harness('submit-question', {
                    disabled: !consented,
                    onLastQuestion,
                    onpress() {
                        q.submit(encode(q.metadata.inputType));
                    },
                }), 'Authorize')));
    },
};

export default function Consent() {
    const _state = {
        consented: false,
        requestingFreeCopy: false,
        view: Form,
        encode(inputType) {
            const consentSubmission = {
                value: _state.consented,
                shareWith: _state.shareWith,
            };
            const basePayload = {
                'consent.fcra.authorized': consentSubmission,
                'consent.fcra.reviewed_disclosure': consentSubmission,
            };

            (_state.attrType.indexOf('.state_resident_notice') > -1 || _state.attrType === 'consent.fcra.reviewed_disclosure') &&
                Object.assign(basePayload, {
                    'consent.fcra.requested_free_copy_state': {
                        value: _state.requestingFreeCopy,
                        shareWith: _state.shareWith,
                    },
                });

            return Object.assign(basePayload,
                (inputType === 'custom_fcra_marker' || _state.attrType !== 'consent.fcra')
                    ? { [_state.attrType]: consentSubmission }
                    : {});
        },
        onRequestFreeCopy(state) {
            _state.requestingFreeCopy = state;
        },
        onConsentChange(state) {
            _state.consented = state;
        },
        get valid() {
            return _state.consented;
        },
    };

    return _state;
}
