// Let the record show that I am appropriately ashamed of all that
// you are about to witness.

import * as objects from '#/universal-framework/objects';
import { doesAttrTypeRepresentIdScan } from '#/evident-attributes/attrTypes';


const SELFIE_TYPE = 'identity_assurance.document_verification.selfie_to_document.selfie_image';

function curateSelfieStep(idScanAttrs, inputAttributes, hidden) {
    return (SELFIE_TYPE in inputAttributes)
        ? {
            [SELFIE_TYPE]: Object.assign({}, inputAttributes[SELFIE_TYPE], {
                metadata: Object.assign({}, inputAttributes[SELFIE_TYPE].metadata, {
                    hidden,
                    icon: 'camera',
                }),
            }),
        }
        : {};
}

// Transforms ID Scanning input space such that only the ID scan
// is requested to represent all attributes it implicitly populates.
export function curateIdScan(aggregatedRequest) {
    const inputAttributes = aggregatedRequest.input;
    const idScanAttrs = Object.keys(inputAttributes).filter(doesAttrTypeRepresentIdScan);

    if (idScanAttrs.length > 0) {
        // `filtered` contains NO id scanning-fulfilled input attributes.
        // We will add simplified steps on top of this.
        const filtered = objects.keyExclude(inputAttributes, idScanAttrs.concat([
            'core.firstname',
            'core.middlename',
            'core.fullname',
            'core.lastname',
            'core.address.fulladdress',
            'core.dateofbirth',
            'idverify1.driverslicense.guid',
            'driverslicense.state',
            'driverslicense.id',
            SELFIE_TYPE,
        ]));

        const US_RX = /us_issued/;
        const legacyAttrTypes = idScanAttrs.filter((at) => US_RX.test(at));
        const contempotaryAttrTypes = idScanAttrs.filter((at) => !US_RX.test(at));
        const idScanSteps = {};

        const needSelfie = (SELFIE_TYPE in inputAttributes) && !inputAttributes[SELFIE_TYPE].complete;

        // Consume any legacy attributes
        if (legacyAttrTypes.length > 0) {
            const legacyType = 'identity_assurance.document_verification.legacy';
            const frontAttrType = legacyAttrTypes.find((a) => a.indexOf('front') > -1);
            const { title = '', description = '' } = inputAttributes[frontAttrType].metadata || {};

            idScanSteps[legacyType] = {
                attrType: legacyType,
                complete: legacyAttrTypes.every((k) => inputAttributes[k].complete),
                hasError: legacyAttrTypes.some((k) => inputAttributes[k].hasError),
                shareWith: inputAttributes[legacyAttrTypes[0]].shareWith,
                metadata: {
                    docTypeAttr: 'identity_assurance.document_verification.americas.us.document_type',
                    needSelfie,
                    regionPrescribed: 'americas',
                    countryPrescribed: 'us',
                    documentPrescribed: 'drivers_license',
                    isLegacy: true,
                    icon: 'photo-id',
                    title,
                    description,
                },
            };
        }

        // Now on to the hacky bits. We scan over the input attrs ending in document_type,
        // then the ones that don't. The way the data works out allows us to procedurally
        // build input attributes. This method is only one of many you could use, but I
        // chose this one because you can at least see how we build on data that was already
        // set by previous instructions.
        const isDocTypeAttr = (at) => at.endsWith('document_type');

        const docTypeAttrs = contempotaryAttrTypes.filter((a) => isDocTypeAttr(a));
        const imgTypeAttrs = contempotaryAttrTypes.filter((a) => {
            return (
                !isDocTypeAttr(a) &&
                a !== SELFIE_TYPE
            );
        });

        for (const dt of docTypeAttrs) {
            const tokens = dt.split('.');
            const [region, country] = tokens.slice(2, -1);
            const attrType = tokens.slice(0, -1).join('.');
            const { title = '', description = '' } = inputAttributes[dt].metadata || {};

            idScanSteps[attrType] = {
                metadata: {
                    docTypeAttr: dt,
                    needSelfie,
                    icon: 'photo-id',
                    documentPrescribed: false,
                    regionPrescribed: region || false,
                    countryPrescribed: country || false,
                    title,
                    description,
                },
                attrType,

                // Warning: Doing it this way makes it impossible to support multiple .document_type dependencies.
                complete: idScanAttrs.every((at) => inputAttributes[at].complete),
                hasError: idScanAttrs.some((at) => inputAttributes[at].hasError),
                shareWith: inputAttributes[dt].shareWith,
            };
        }

        for (const it of imgTypeAttrs) {
            const tokens = it.split('.');
            const prefixEnd = tokens.findIndex((t) => {
                return ['passport', 'drivers_license', 'id_card'].includes(t);
            });

            const documentPrescribed = tokens[prefixEnd];

            const key = tokens.slice(0, prefixEnd).join('.');

            const metaSource = (documentPrescribed === 'passport')
                ? inputAttributes[it].metadata
                : inputAttributes[imgTypeAttrs.find((a) => a.indexOf('front') > -1)].metadata;

            Object.assign(idScanSteps[key].metadata, {
                documentPrescribed,
            }, (metaSource)
                ? {
                    title: metaSource.title,
                    description: metaSource.description,
                }
                : {});
        }

        const selfieStep = curateSelfieStep(
            contempotaryAttrTypes,
            inputAttributes,
            idScanAttrs.some((dt) => {
                return !inputAttributes[dt].complete;
            }),
        );

        aggregatedRequest.input = Object.assign(filtered, selfieStep, idScanSteps);
    }

    return aggregatedRequest;
}
