import { logger, spa } from '#/browser-framework';
import { Snackbar } from '#/browser-framework/comps';

import { getSessionData, notifyDelegate } from '#/ido-lib/idoWebClient';


export function delegationController(attrType) {
    const iface = {};

    iface.phoneNumber = '';
    iface.textsRemaining = 2;
    iface.sendingText = false;
    iface.snackbar = Snackbar.makeViewModel();

    // For tracking phone number used for delegation flow.
    iface.changePhoneNumber = function changePhoneNumber({ qualifiedValue, value, valid }) {
        iface.phoneNumber = value;
        iface.smsRecipient = qualifiedValue;
        iface.typedValidPhoneNumber = valid;
    };

    iface.maySendText = function maySendText() {
        return (iface.typedValidPhoneNumber && iface.textsRemaining > 0) && !iface.sendingText;
    };


    // Send a text to the given number.
    iface.sendText = spa.redrawAfter(({ authToken, continueUrl } = {}) => {
        if (!iface.typedValidPhoneNumber) {
            return Promise.reject();
        }

        if (iface.textsRemaining > 0) {
            const { location } = spa.$window;
            const { rprId } = getSessionData();

            const vowNotification = (deploy.WEB_PUBLIC_DEVELOPER_MODE)
                ? Promise.resolve()
                : notifyDelegate({
                    method: 'sms',
                    recipient: iface.smsRecipient,
                    authToken,
                    continueUrl: continueUrl || `${location.origin}/?q=${attrType}#!/interview/${rprId}`,
                });

            iface.sendingText = true;
            spa.redraw();

            return vowNotification
                .then(() => {
                    --iface.textsRemaining;
                    logger.info(`${iface.textsRemaining} text(s) remaining`);
                    iface.snackbar.display('Check your phone, we just sent a text.', true);
                })
                .catch((e) => {
                    iface.snackbar.display('Uh oh, we couldn\'t send a text. Please try again in a few minutes.', false);
                    logger.error(e);
                })
                .then(() => {
                    iface.sendingText = false;
                });
        } else {
            iface.snackbar.display(`We already sent a text. If you don\'t see one,
                click "Cancel" and try uploading images here.`);
            return Promise.resolve();
        }
    });

    return iface;
}
