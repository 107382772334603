// Curation means reorganizing dependencies such that they have a 1-to-1
// relationship with form models. This may mean "inventing" input attributes
// that aribitrarily inherit metadata from others. This is an error-prone
// operation that should be phased out as architectual improvements allow
// dynamic dependencies.

import { curateConsent } from './consent';
import { curateIdScan } from './idScanning';
import { curateSelfieWithPayCard } from './selfieComps';
import { curateUtilityProviders } from './utilityProviders';


/**
 * Returns a modified InputAttributeModel with some of the attributes consolidated or
 * added.
 * @param {InputAttributeModel} inputAttributeModel
 */
export function curate(inputAttributeModel) {
    const transformers = [
        curateConsent,
        curateSelfieWithPayCard,
        curateIdScan,
        curateUtilityProviders,
    ];

    return transformers.reduce((p, transform) => transform(p), inputAttributeModel);
}
