import mithril from 'mithril';

import _xhr from './xhr';
import _load from './loader';
import _features from './features';
import _browserLogger from './browserLogger';
import * as _blobs from './blobs';
import * as _userInput from './userInput';
import * as _canvases from './canvases';
import { getInstance } from './spa';


// Export all but what was overridden.
export {
    async,
    arrays,
    b64,
    colors,
    debounce,
    errors,
    fsm,
    json,
    numbers,
    objects,
    strings,
    validation,
} from '#/universal-framework';

export const spa = getInstance(window);
export * from './taxonomy.js';
export const m = mithril;
export const blobs = _blobs;
export const canvases = _canvases;
export const xhr = _xhr;
export const load = _load;
export const router = spa.router;
export const events = spa.events;
export const support = _features(window);
export const logger = _browserLogger;
export const userInput = _userInput;
