import { m, harness, router } from '#/browser-framework';
import { Controls, SecondaryButton, PrimaryButton } from '#/ido-lib/views/InterviewControls';
import { MobileSimplexDropbox } from '#/ido-lib/views/MobileSimplexDropbox';

import * as content from './selfieContent';



export const MobileSelfieForm = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const copy = content[q.copySource];

        return m('.MobileSelfieForm', harness('mobile-selfie-card', {}),
            m('h2', copy.heading),
            m('p', copy.reason),
            m(MobileSimplexDropbox, harness('selfie-dropbox', {
                id: 'selfie-dropbox',
                accept: 'image/*',
                enablePreview: true,
                dataUrl: q.dataUrl,
                onchange(files) {
                    q.stageSelfie(files && files[0]);
                },
            }), 'Take a selfie'),
            m(Controls,
                m(SecondaryButton, {
                    onpress() {
                        router.go('/overview');
                    },
                }),
                m(PrimaryButton, {
                    disabled: !q.isValid(),
                    onLastQuestion,
                    onpress() {
                        q.sendSelfie();
                    },
                }, 'Continue')));
    },
};
