import { emitter } from '#/universal-framework/events';

import { createToken } from './stripe';
import { NotReady, UnknownField, ValidationFailed } from './errors';

// Format currency with awe-inspiring cultural sensitivity.
function formatBalanceDue(currency, amount) {
    const normalizedCurrency = currency.toUpperCase();

    let balance = amount;

    if (/^\d+$/.test(amount)) {
        balance += '.00';
    } else if (/^\d+\.\d$/.test(balance)) {
        balance += '0';
    }

    return (normalizedCurrency === 'USD')
        ? `$${balance}`
        : `${balance} ${normalizedCurrency}`;
}

// Stripe controller used to prepare transactions.
export function paymentController({
    stripe,
    $window,
    currency,
    amount,
    submitPayment,
}) {
    const iface = emitter({ maxHistoryEntries: 0 });

    // The booleans track client-side validation state with Stripe's help.
    iface.cardNumber = false;
    iface.cardExpiry = false;
    iface.cardCvc = false;
    iface.postalCode = false;
    iface.pending = false;

    // Text used to report errors that don't belong to a particular field.
    iface.formError = '';

    // Holds element node references from Stripe's Elements SDK
    iface.nodes = {};

    iface.cardHolderName = '';
    iface.balanceDue = formatBalanceDue(currency, amount);
    iface.stripe = stripe;
    iface.elements = stripe.elements();

    // Cook up a token from Stripe and ask the caller to perform
    // the actual transaction against an outside service.
    iface.pay = () => {
        if (!iface.awaitingPaymentRequest) {
            return Promise.reject(NotReady());
        }

        // For visual feedback.
        iface.pending = true;
        iface.emit('before-payment');

        iface.setElementError('cardExpiry', '');
        iface.setElementError('cardCvc', '');

        return createToken(stripe, iface.nodes.cardNumber, iface.cardHolderName)
            .then((token) => submitPayment(token))
            .then((assertResponse) => {
                if (assertResponse.error) {
                    const { message, param = '' } = assertResponse.error;

                    iface.formError = message;
                    const fieldErrorText = 'Please review this field.';

                    switch (param) {
                    case '':
                        break;

                    case 'exp_month':
                        iface.setElementError('cardExpiry', fieldErrorText);
                        break;

                    case 'cvc':
                    case 'incorrect_cvc':
                        iface.setElementError('cardCvc', fieldErrorText);
                        break;

                    case 'address_zip':
                        iface.setElementError('postalCode', fieldErrorText);
                        break;

                    default:
                        throw UnknownField({
                            extra: {
                                param,
                            },
                        });
                    }

                    iface.pending = false;

                    throw ValidationFailed({
                        extra: {
                            param,
                        },
                    });
                }
            });
    };


    // Set a field's error message. Will not set Stripe element state.
    iface.setElementError = (elem, message) => {
        const errorNode = $window.document.getElementById(`${elem}-error`);

        if (message) {
            errorNode.textContent = message;
        } else {
            // Keeps field height consistent.
            errorNode.innerHTML = '&nbsp;';
        }
    };


    // Place a Stripe element in the DOM. `elem` must be a string
    // used by Stripe Elements (i.e. 'card' or 'postalCode').
    iface.mountElement = (elem) => {
        const el = iface.elements.create(elem);

        // For live field validation
        el.on('change', ({ complete, error }) => {
            iface[elem] = complete;

            if (error && !complete) {
                iface.setElementError(elem, error.message);
            } else {
                iface.setElementError(elem);
            }

            iface.emit('element-changed', elem, error, complete);
        });

        el.mount(`#${elem}`);

        iface.nodes[elem] = el;
    };

    iface.destroyAll = () => {
        for (const [elem, el] of Object.entries(iface.nodes)) {
            el.destroy();

            delete iface[elem];
        }
    };

    // True if we have everything we need to attempt a transaction.
    Object.defineProperty(iface, 'awaitingPaymentRequest', {
        get: () => (
            !iface.pending &&
            iface.cardNumber &&
            iface.cardExpiry &&
            iface.postalCode &&
            iface.cardCvc &&
            iface.cardHolderName.length > 0
        ),
    });


    return iface;
}
