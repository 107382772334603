import {m} from '#/browser-framework';


const option = {
    view: ({attrs: {label, selected, value}}) => {
        return m('option', {
            value,
            selected,
            'class': (selected) ? 'selected' : '',
        }, label);
    },
};

export default {
    makeViewModel: ({
        id,
        placeholder = 'Select one',
        values,
        toLabel = (v) => v,
        onSelectionChanged = () => {},
    }) => {
        const iface = {
            'data-harness': id,
            id,
            placeholder,
            disabled: false,
            options: values.map(toLabel),
            select(index, actual) {
                iface.value = index;
                iface.selection = values[actual];
                onSelectionChanged(iface.selection, index, actual);
            },
            value: 0,
            selection: (placeholder)
                ? null
                : values[0],
            clear() {
                iface.select(0, (iface.placeholder) ? -1 : 0);
            },
        };

        return iface;
    },
    view({attrs: {
        'data-harness': dataharness,
        id,
        extraClass,
        placeholder,
        disabled,
        options,
        onblur = () => {},
        select,
        value,
    }}) {
        const opts = (placeholder)
            ? [placeholder].concat(options)
            : options;

        return m('select.Dropdown', {
            'data-harness': dataharness,
            id,
            disabled,
            class: extraClass,
            name: id,
            value,
            onblur,
            onchange(e) {
                // Tracks the visually selected option, including the placeholder
                const [{index}] = Array.from(e.target.selectedOptions);

                // Tracks the actually selected option, which won't include the placeholder
                const actual = (placeholder)
                    ? index - 1
                    : index;

                select(index, actual);
            },
        }, opts.map((label, i) => {
            return m(option, {
                key: i,
                value: i,
                label,
                selected: value === i,
            });
        }));
    },
};
