import { harness, m } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';


export const CountryDropdown = {
    view({
        attrs: {
            q,
        },
    }) {
        const idTypeDisplay = ({
            'passport': 'passport',
            'id_card': 'ID card',
            'drivers_license': 'driver\'s license',
        })[q.idType] || 'identity document';

        return m('.CountryDropdown',
            m('p', `Where was your ${idTypeDisplay} issued?`),
            m(Dropdown, harness('id-document-country-selection', {
                id: 'country-dropdown',
                placeholder: 'Select a country',
                options: q.countries.map(([, name]) => name),
                select(i, actual) {
                    q.countryIndex = i;
                    q.country = (q.countries[actual])
                        ? q.countries[actual][0]
                        : null;
                },
                value: q.countryIndex,
            })));
    },
};
