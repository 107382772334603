import { m } from '#/browser-framework';

const DefaultLegalease = {
    view({
        attrs: {
            relyingPartyLegalName,
        },
    }) {
        return [
            m('h3', 'APPLICANT DISCLOSURE STATEMENT'),
            m('p', `
                With your application to ${relyingPartyLegalName} (COMPANY) to
                provide services as an independent contractor, employee, or
                volunteer you may have information requested about you from a
                Consumer Reporting Agency (CRA). This information may be
                obtained in the form of a consumer report.
                `),
            m('p', `
                These reports may contain information about your character,
                general reputation, personal characteristics and/or mode of
                living. The types of information that may be obtained
                include, but are not limited to: social security number
                verifications; address history; criminal records checks;
                public court records checks; driving records checks; and
                professional licensing/certification checks. This information
                may be obtained from private and/or public records sources,
                including, as appropriate; governmental agencies, courthouses,
                educational institutions, former employers, or other information
                sources.
                `),
            m('h3', 'APPLICANT AUTHORIZATION OF BACKGROUND INVESTIGATION'),
            m('p', m.trust(`
                I have carefully read, and understand, this Authorization form
                and further acknowledge receipt of the separate document
                entitled &ldquo;A Summary of Your Rights under the Fair
                Credit Reporting Act&rdquo; and the &ldquo;Applicant Disclosure
                Statement&rdquo; and certify that I have read and understand
                both documents.  By my signature below, I consent to the release
                of consumer reports and/or investigative consumer reports
                (&ldquo;Background Reports&rdquo;) prepared by Evident ID Inc.
                located at 3405 Piedmont Rd NE Suite 100 Atlanta, GA 30305 --
                1-877-832-5298 - `),
            m('a', {
                href: 'https://www.evidentid.com/privacy-policy',
            }, 'Privacy Policy'),

            `, to COMPANY and its designated representatives and agents for
            the purpose of determining and maintaining my relationship as an
            independent contractor, employee, or volunteer with COMPANY.
            `),

            m('p', `
            I understand that if COMPANY engages in a relationship with me,
            my consent will apply, and COMPANY may obtain Background
            Reports throughout my relationship with them, if such
            obtainment is permissible under applicable State law and
            COMPANY policy. I also understand that information contained
            in my application, or otherwise disclosed by me may be used
            when ordering the Background Reports and that nothing herein
            shall be construed as an offer of employment or a guarantee
            of a relationship with COMPANY.
            `),
        ];
    },
};

export function contentModel(curatedRequest = {}, identity = {}) {
    // This appears in legalease, so rather than default to a different
    // company name, default to what LOOKS like a software error when
    // rendered so that people ask about the mistake instead of ascribing
    // a role to an assumed entity.
    const dbaName = identity.displayName || '{{COMPANY}}';
    const { idoConfirmationMessage = {} } = identity;

    const state = {
        brandingColor: identity.primaryColor || '#000',
        dbaNameText: dbaName,
        DefaultLegalease,
        consentHtml: identity.consentHtml,
        requestSummaryText: curatedRequest.summary,
        requestDescriptionText: curatedRequest.description,
        finishedHeadingText: 'Your information has been submitted',
        introText: `Action required to complete your ${curatedRequest.summary || 'profile'}`,
        completeStatusHeadingText: (
            idoConfirmationMessage.headingText ||
            `Thank you for submitting your information to ${dbaName}`
        ),
        completeStatusMessageText: (
            idoConfirmationMessage.messageText ||
            'You will be notified once your submission has finished processing.'
        ),
        unfinishedHeadingText: 'is requesting information from you',
        wordmarkUri: identity.wordmarkUri,

        getHeadingText(done) {
            return (done)
                ? state.finishedHeadingText
                : (state.wordmarkUri)
                    ? state.unfinishedHeadingText
                    : `${state.dbaNameText} ${state.unfinishedHeadingText}`;
        },
    };

    return state;
}
