function fileToX(file, functionName) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('error', (e) => {
            reject(e);
        });

        reader.addEventListener('load', () => {
            resolve(reader.result);
        });

        reader[functionName](file);
    });
}

export function downloadFileUsingBinString(string, filename) {
    const blob = new Blob([string], { type: 'octet/stream' });

    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    a.remove();
}

export function fileToDataUrl(file) {
    return fileToX(file, 'readAsDataURL');
}

export function fileToArrayBuffer(file) {
    return fileToX(file, 'readAsArrayBuffer');
}

export function fileToBinaryString(file) {
    return fileToX(file, 'readAsBinaryString');
}

export function dataUrlToByteString(dataUrl) {
    return atob(dataUrl.split(',')[1]);
}

export function bytes(byteString) {
    return byteString.map((_, i) => byteString.charCodeAt(i));
}

export function dataUrltoArrayBuffer(data) {
    const dbsplit = data.split(',');
    const byteString = atob(dbsplit[1]);

    const buffer = new ArrayBuffer(byteString.length);
    const byteArray = new Uint8Array(buffer);

    byteArray.set(bytes(byteString));

    return buffer;
}

export function arrayBufferToDataUrl(ab) {
    return btoa(new Uint8Array(ab).reduce((p, c) => {
        return `${p}${String.fromCharCode(c)}`;
    }, ''));
}

export function dataUrltoBlob(data) {
    const dbsplit = data.split(',');
    const mimeString = dbsplit[0].split(':')[1].split(';')[0];
    const buffer = dataUrltoArrayBuffer(data);

    const bb = (window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder);

    if (bb) {
        const builder = bb();

        builder.append(buffer);

        return builder.getBlob(mimeString);
    } else {
        return new Blob([buffer], {
            'type': mimeString,
        });
    }
}
