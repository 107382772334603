import moment from 'moment';

import { m, harness, router } from '#/browser-framework';

import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';
import LineInputBlock from '#/ido-lib/views/LineInputBlock';

const FORMAT = 'MM/DD/YYYY';

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const { value, encode, isValid } = q;

        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit() {
                q.submit(encode()).then(() => {
                    router.go('/overview');
                });
            },
            onLastQuestion,
        },
        m(LineInputBlock,
            'Enter your birthdate',
            m('input', harness('birthday-input', {
                placeholder: FORMAT,
                value,
                oninput(e) {
                    q.value = e.target.value;
                },
            }))));
    },
};

export default function Birthday() {
    const _state = {
        view: Form,
        value: '',

        encode() {
            const mmt = moment(_state.value, FORMAT);

            return {
                [_state.attrType]: {
                    shareWith: _state.shareWith,
                    value: {
                        $objectType: 'date',
                        year: mmt.year(),
                        day: mmt.date(),
                        month: 1 + mmt.month(),
                    },
                },
            };
        },

        isValid() {
            const mmt = moment(_state.value, 'MM/DD/YYYY', true);

            if (!mmt.isValid()) {
                return false;
            }

            return mmt.isBefore(moment());
        },
    };

    return _state;
}
