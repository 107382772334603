export function fsm(transitionsAsArrays) {
    const transitions = transitionsAsArrays.reduce((p, [state, relative]) => {
        // Automatically creates terminal nodes for states with undeclared transitions.
        for (const terminal of relative.filter((s) => !p.has(s))) {
            p.set(terminal, new Set());
        }

        return p.set(state, new Set(relative));
    }, new Map());

    return (initialState, onTransition) => {
        let state;
        let toCall = () => {};

        const instance = {
            _transitions: transitions,
            get state() {
                return state;
            },
            set state(v) {
                throw new Error(`Direct state change to ${v} not allowed. Use .be()`);
            },
            get listener() {
                return toCall;
            },
            set listener(v) {
                toCall = (typeof v === 'function')
                    ? v
                    : () => {};
            },
            // Check if current state has target child
            mayBe(target) {
                return transitions.get(state).has(target);
            },
            // Transition state to target
            be(target) {
                if (transitions.has(target)) {
                    if (state === undefined || instance.mayBe(target)) {
                        toCall(state, target);

                        state = target;

                        return instance;
                    } else {
                        throw new Error(`Cannot transition from ${state} to ${target}`);
                    }
                } else {
                    throw new Error(`No such state: ${target}`);
                }
            },
        };

        instance.listener = onTransition;
        instance.be(initialState);

        return instance;
    };
}
