import { format } from './strings';

const _cache = {};

/*
Opinionated error space that ignores 'instanceof'-centric
checks in favor of canonical string reason codes, opt-in message
formatting and context prescription via overridable factories.

See errors.test.js for example usage.
*/
export function exception({ reason, message, extra, config } = {}) {
    const rsn = reason || 'unexpected';
    const msg = message || rsn;
    const cfg = config || {};
    const xtra = extra || {};

    const e = new Error((cfg.format)
        ? format(msg, [], xtra)
        : msg);

    e.reason = rsn;
    e.extra = (cfg.format)
        ? Object.entries(xtra).reduce((p, [k, v]) => {
            p[k] = (typeof v === 'string')
                ? format(v, [], xtra)
                : v;

            return p;
        }, {})
        : xtra;

    return e;
}

export function definition(base, baseopts = {}) {
    if (typeof baseopts.reason !== 'string' || !baseopts.reason) {
        throw exception({
            reason: 'errors/no-reason',
            message: 'A string reason must be specified to define an error',
        });
    }

    if (_cache[baseopts.reason]) {
        throw exception({
            reason: 'errors/reason-conflict',
            message: `Reason code ${baseopts.reason} is already taken.`,
        });
    }

    _cache[baseopts.reason] = (overrides = {}) =>
        (base || exception)(Object.assign(
            {}, baseopts, overrides));

    // So that you don't need to build an error to reference its code.
    _cache[baseopts.reason].reason = baseopts.reason;

    return _cache[baseopts.reason];
}

// Function decorator that controls the presense of a keyed error in a Map
// based on promissory results.
//
// "optimistic" because the key will be synchronously removed before the promissory is called,
// and an Error will only be added back to the map if the promissory returns a Promise that
// reaches a rejected state.
export function optimisticAssessor(map, key, promissory) {
    return (...args) => {
        map.delete(key);

        return Promise
            .resolve(promissory(...args))
            .catch((e) => {
                map.set(key, e);
                throw e;
            });
    };
}

// Function decorator: Controls the presense of a keyed error in a Map
// based on promissory results.
//
// "pessimistic" because if the key already exists in the Map holding an Error,
// the error will remain until the promissory returns a Promise that resolves.
export function pessimisticAssessor(map, key, promissory) {
    return (...args) => {
        return Promise
            .resolve(promissory(...args))
            .then(() => map.delete(key))
            .catch((e) => {
                map.set(key, e);
                throw e;
            });
    };
}

export function lookup(reason) {
    return _cache[reason];
}

export const Unexpected = definition(exception, {
    reason: 'unexpected',
    extra: {
        displaySummary: 'Uh oh, something went wrong!',
        displayMessage: `We're currently working on this, and should have things
            back to normal soon. Please try again later.`,
    },
});

export const NetworkOutage = definition(exception, {
    reason: 'network-outage',
    extra: {
        displaySummary: 'Network connection error',
        displayMessage: (
            'There was a connection problem. These are usually ' +
            'intermittent, so please wait a few minutes and try again.'
        ),
    },
});

export const AuthorizationError = definition(exception, {
    reason: 'unauthorized',
    extra: {
        displaySummary: 'You don\'t have permission to use this application.',
        displayMessage: 'Please contact the account administrator for access.',
    },
});

export const CookiesNeeded = definition(exception, {
    reason: 'cookies-needed',
    extra: {
        displaySummary: 'Cookies are disabled.',
        displayMessage: 'You need to enable third-party cookies and data in your browser settings to use this application.',
    },
});

export function raise(factory = Unexpected, overrides = {}) {
    throw factory(overrides);
}

export function withHandlers(handlers, thunk) {
    try {
        return thunk();
    } catch (e) {
        const match = handlers.find(
            ([pred]) => ((typeof pred === 'function')
                ? pred(e)
                : e.reason === pred.reason));

        if (!match) {
            throw e;
        }

        return match[1](e);
    }
}

export function ifCaught(handler, thunk) {
    return withHandlers([[() => true, handler]], thunk);
}
