import { m } from '#/browser-framework';

import SelfieCaptureVisualAidSvg from '#/ido-lib/images/SelfieCaptureVisualAid.svg';

export const vanillaSelfieContent = {
    heading: 'Smile! We need you to take a selfie.',
    reason: (
        `In order to verify your identity, we need to compare a
        photo of you to the photograph on your identity document.`
    ),
    delegationInstructions: (
        `Please enter your phone number below. We'll send a
        text message containing instructions on how to complete your
        selfie image on your mobile device.`
    ),
};


export const paymentCardSelfieContent = {
    heading: 'We need your selfie with a payment card.',
    reason: (
        `In order to verify your identity, we need a photo of
        you holding your payment card. Please take a selfie with your
        face and the details of your card clearly visible.`
    ),
    delegationInstructions: (
        `Please enter your phone number below. We'll send a
        text message containing instructions on how to complete your
        selfie image on your mobile device.`
    ),
};


export const SelfieCaptureVisualAid = {
    view() {
        return m('img.SelfieCaptureVisualAid', {
            src: SelfieCaptureVisualAidSvg,
        });
    },
};
