import { m, tx } from '#/browser-framework';
import { FileDropbox, Named } from '#/browser-framework/comps';
import { MobileSimplexPreview } from './MobileSimplexPreview';


export const MobileSimplexDropbox = {
    view({
        attrs: {
            id,
            enablePreview = true,
            onchange,
            dataUrl,
            capture,
        },
        children,
    }) {
        if ((dataUrl === undefined || dataUrl) && enablePreview) {
            return m(MobileSimplexPreview, {
                dataUrl,
                ondelete() {
                    onchange(null);
                },
            });
        }

        const ready = Boolean(dataUrl);

        const labelVNode = (ready)
            ? [
                m(Named.Icon, { name: 'checkmark' }),
                ' Image ready',
            ]
            : children;

        return m(tx('.MobileSimplexDropbox', { ready }), {
            onclick() {
                FileDropbox.FileInputNode.openFileBrowser(id);
            },
        },
        m(FileDropbox.FileInputNode, {
            id,
            onchange,
            capture,
            accept: 'image/*',
        }),
        m('span.MobileSimplexDropbox__label',
            labelVNode),
        m('span.MobileSimplexDropbox__icon',
            m(Named.Icon, { name: 'camera' })));
    },
};
