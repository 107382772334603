import { m, harness } from '#/browser-framework';

import { LargeFormatIdSimplexControl } from './LargeFormatIdSimplexControl';


export const LargeFormatIdCardDuplexForm = {
    view({
        attrs: {
            q,
        },
    }) {
        const scanner = q.getScanner();
        const docName = (q.metadata.documentPrescribed)
            ? (q.idType === 'id_card')
                ? 'ID Card'
                : 'Driver\'s license'
            : 'ID card or driver\'s license';

        return m('.LargeFormatIdCardDuplexForm',
            m('h2.LargeFormatIdCardDuplexForm__title',
                'I have photos that I can upload'),
            m('p.LargeFormatIdCardDuplexForm__subtitle',
                `Upload photos of the front and
                back sides of your ${docName} on
                a dark surface.`),
            m('.LargeFormatIdCardDuplexForm__duplex',
                m(LargeFormatIdSimplexControl, harness('large-format-id-front-card', {
                    id: 'id-card-front',
                    dataUrl: scanner.front.dataUrl,
                    onchange(files) {
                        scanner.setSimplex(files && files[0], 'front');
                    },
                }), 'Front side'),
                m(LargeFormatIdSimplexControl, harness('large-format-id-back-card', {
                    id: 'id-card-back',
                    dataUrl: scanner.back.dataUrl,
                    onchange(files) {
                        scanner.setSimplex(files && files[0], 'back');
                    },
                }), 'Back side')));
    },
};
