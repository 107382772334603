import base64js from 'base64-js';
import {TextDecoderLite, TextEncoderLite} from 'text-encoder-lite';


// Base64 encoding and decoding of UTF-8 strings.
export function encodeString(str, {urlsafe = false} = {}) {
    const encoded = base64js.fromByteArray(new TextEncoderLite('utf-8').encode(str));

    return (urlsafe)
        ? encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
        : encoded;
}

export function decodeString(str, {urlsafe = false} = {}) {
    // Add removed at end '='
    let b64str = (urlsafe)
        ? str.replace(/\-/g, '+').replace(/\_/g, '/')
        : str;

    if ((str.length % 4) > 0) {
        b64str += Array(5 - str.length % 4).join('=');
    }

    return new TextDecoderLite('utf-8').decode(base64js.toByteArray(b64str));
}
