import {m} from '#/browser-framework';
import primarySpinner from '#/browser-framework/images/primarySpinner.svg';

export default {
    view({attrs: {vars: {text}}}) {
        return m('.svgSpinner',
            m('img.svgSpinner__image',
                { src: primarySpinner }),
            m('.svgSpinner__text', text));
    },
};
