export function toDegrees(rads) {
    return rads * 180 / Math.PI;
}

export function toRadians(degs) {
    return degs * Math.PI / 180;
}

export function inClosedInterval(n, lo, hi) {
    return n >= lo && n <= hi;
}

export function inOpenInterval(n, lo, hi) {
    return n > lo && n < hi;
}

export function inLeftOpenInterval(n, lo, hi) {
    return n > lo && n <= hi;
}

export function inRightOpenInterval(n, lo, hi) {
    return n >= lo && n < hi;
}

// handles negative numbers
export function modulo(x, n) {
    return ((x % n) + n) % n;
}
