
import { contentModel } from './contentModel';


export function interviewModel() {
    const iface = {
        questions: [],
        balance: null,
        consentGiven: false,
        content: contentModel(),
    };

    return iface;
}
