import { fileToDataUrl } from './blobs';

export const preloadImage = (src) => {
    const img = new Image();

    return new Promise((resolve, reject) => {
        img.onerror = (e) => reject(e);
        img.onload = () => resolve(img);
        img.src = src;
    });
};

export const draw2dImage = (ctx, img, {
    dw = img.width,
    dh = img.height,
}) => {
    const scale = Math.min(dw / img.width, dh / img.height);
    const x = (dw / 2) - (img.width / 2) * scale;
    const y = (dh / 2) - (img.height / 2) * scale;

    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
};

export const srt2dPipeline = (ctx, {
    width,
    height,
    radians = 0,
    xOrigin = 0.5,
    yOrigin = 0.5,
    xScale = 1,
    yScale = 1,
    xTranslate = 0,
    yTranslate = 0,
} = {}) => {
    const hw = Math.floor(width * xOrigin);
    const hh = Math.floor(height * yOrigin);

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.translate(hw, hh);
    ctx.scale(xScale, yScale);
    ctx.translate(
        (xScale >= 0) ? xTranslate : -xTranslate,
        (yScale >= 0) ? yTranslate : -yTranslate);
    ctx.rotate(radians);
    ctx.translate(-hw, -hh);
};

export const withImageCopy = (img, f) => {
    const c = document.createElement('canvas');

    c.width = img.width;
    c.height = img.height;

    const ctx = c.getContext('2d');
    ctx.drawImage(img);

    return () => f(c, ctx, ctx.getImageData(0, 0, c.width, c.height));
};

export const applyOneFilter = (imageData, f) => {
    const { data, width, height } = imageData;
    const { length } = data;

    for (let i = 0; i < length; i += 4) {
        f(data, i, width, height);
    }

    return imageData;
};

export const applyAllFilters = (imageData, filters) =>
    filters.reduce((id, f) => applyOneFilter(id, f), imageData);

// Credit:
// https://stackoverflow.com/a/37714937/394397
// https://stackoverflow.com/a/34203206/394397
export const contrastFilter = (contrast) => {
    const shift = (contrast / 100) + 1;
    const intercept = 128 * (1 - shift);

    return (P, i) => {
        P[i] = P[i] * shift + intercept;
        P[i + 1] = P[i + 1] * shift + intercept;
        P[i + 2] = P[i + 2] * shift + intercept;
    };
};

export const brightnessFilter = (amount) => (P, i) => {
    P[i] += amount;
    P[i + 1] += amount;
    P[i + 2] += amount;
};

function newsize(width, height) { // eslint-disable-line max-params
    return {
        width,
        height,
    };
}


/**
* Detect subsampling in loaded image.
* In iOS, larger images than 2M pixels may be subsampled in rendering.
*/
function detectSubsampling(img) {
    const { width: iw, height: ih } = img;

    if (iw * ih > 1048576) { // subsampling may happen over megapixel image
        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = 1;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, -iw + 1, 0);
        // subsampled image becomes half smaller in rendering size.
        // check alpha channel value to confirm image is covering edge pixel or not.
        // if alpha value is 0 image is not covering, hence subsampled.
        return ctx.getImageData(0, 0, 1, 1).data[3] === 0;
    } else {
        return false;
    }
}

/**
* Update the orientation according to the specified rotation angle
*/
function rotate(orientation, angle) {
    const o = {
        // nothing
        1: { 90: 6, 180: 3, 270: 8 },
        // horizontal flip
        2: { 90: 7, 180: 4, 270: 5 },
        // 180 rotate left
        3: { 90: 8, 180: 1, 270: 6 },
        // vertical flip
        4: { 90: 5, 180: 2, 270: 7 },
        // vertical flip + 90 rotate right
        5: { 90: 2, 180: 7, 270: 4 },
        // 90 rotate right
        6: { 90: 3, 180: 8, 270: 1 },
        // horizontal flip + 90 rotate right
        7: { 90: 4, 180: 5, 270: 2 },
        // 90 rotate left
        8: { 90: 1, 180: 6, 270: 3 },
    };
    return o[orientation][angle] ? o[orientation][angle] : orientation;
}

/**
* Transform canvas coordination according to specified frame size and orientation
* Orientation value is from EXIF tag
*/
function transformCoordinate(canvas, width, height, orientation) {
    switch (orientation) {
    case 5:
    case 6:
    case 7:
    case 8:
        canvas.width = height;
        canvas.height = width;
        break;
    default:
        canvas.width = width;
        canvas.height = height;
    }

    const ctx = canvas.getContext('2d');

    switch (orientation) {
    case 1:
        // nothing
        break;
    case 2:
        // horizontal flip
        ctx.translate(width, 0);
        ctx.scale(-1, 1);
        break;
    case 3:
        // 180 rotate left
        ctx.translate(width, height);
        ctx.rotate(Math.PI);
        break;
    case 4:
        // vertical flip
        ctx.translate(0, height);
        ctx.scale(1, -1);
        break;
    case 5:
        // vertical flip + 90 rotate right
        ctx.rotate(0.5 * Math.PI);
        ctx.scale(1, -1);
        break;
    case 6:
        // 90 rotate right
        ctx.rotate(0.5 * Math.PI);
        ctx.translate(0, -height);
        break;
    case 7:
        // horizontal flip + 90 rotate right
        ctx.rotate(0.5 * Math.PI);
        ctx.translate(width, -height);
        ctx.scale(-1, 1);
        break;
    case 8:
        // 90 rotate left
        ctx.rotate(-0.5 * Math.PI);
        ctx.translate(-width, 0);
        break;
    default:
        break;
    }
}

/**
* Detecting vertical squash in loaded image.
* Fixes a bug which squash image vertically while drawing into canvas for some images.
*/
function detectVerticalSquash(img, iw, ih) {
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = ih;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const data = ctx.getImageData(0, 0, 1, ih).data;

    // search image edge pixel position in case it is squashed vertically.
    let sy = 0;
    let ey = ih;
    let py = ih;
    while (py > sy) {
        const alpha = data[(py - 1) * 4 + 3];
        if (alpha === 0) {
            ey = py;
        } else {
            sy = py;
        }
        py = (ey + sy) >> 1;
    }
    const ratio = py / ih;
    return ratio === 0 ? 1 : ratio;
}

export function loadImage(dataUrl) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onerror = reject;
        img.onload = () => resolve(img);

        img.src = dataUrl;
    });
}

export function processImage(file, options = {}) {
    const config = Object.assign({
        exif: {},
        crop: false,
        quality: 80,
        maxW: 2048,
        maxH: 1536,
        rotate: 0,
    }, options);

    return fileToDataUrl(file)
        .then((dataUrl) => {
            return loadImage(dataUrl);
        })
        .then((img) => { // eslint-disable-line complexity
            let orientation = config.exif.Orientation || 1;

            orientation = rotate(orientation, config.rotate);

            let {width, height} = img;

            // replace width and height based on if this is a CW or counter-CW rotation
            const size = (orientation >= 5 && orientation <= 8)
                ? newsize(height, width)
                : newsize(width, height);

            let {width: iw, height: ih} = img;
            ({width, height} = size);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            ctx.save();
            transformCoordinate(canvas, width, height, orientation);

            // over image size
            if (detectSubsampling(img)) {
                iw /= 2;
                ih /= 2;
            }

            const d = 1024; // size of tiling canvas
            let tmpCanvas = document.createElement('canvas');
            tmpCanvas.width = tmpCanvas.height = d;
            let tmpCtx = tmpCanvas.getContext('2d');
            const vertSquashRatio = detectVerticalSquash(img, iw, ih);
            let sy = 0;

            while (sy < ih) {
                const sh = sy + d > ih ? ih - sy : d;
                let sx = 0;
                while (sx < iw) {
                    const sw = sx + d > iw ? iw - sx : d;
                    tmpCtx.clearRect(0, 0, d, d);
                    tmpCtx.drawImage(img, -sx, -sy);
                    const dx = Math.floor(sx * width / iw);
                    const dw = Math.ceil(sw * width / iw);
                    const dy = Math.floor(sy * height / ih / vertSquashRatio);
                    const dh = Math.ceil(sh * height / ih / vertSquashRatio);
                    ctx.drawImage(tmpCanvas, 0, 0, sw, sh, dx, dy, dw, dh);
                    sx += d;
                }
                sy += d;
            }

            ctx.restore();
            tmpCanvas = tmpCtx = null;

            // if rotated width and height data replacing issue
            const newcanvas = document.createElement('canvas');
            newcanvas.width = width;
            newcanvas.height = height;

            const newctx = newcanvas.getContext('2d');
            newctx.drawImage(canvas, 0, 0, width, height);

            const dataUrl = (file.type === 'image/png')
                ? newcanvas.toDataURL(file.type)
                : newcanvas.toDataURL('image/jpeg', (config.quality * 0.01));

            return {
                width: size.width,
                height: size.height,
                dataUrl,
            };
        });
}
