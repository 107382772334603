import { m, load, async, harness } from '#/browser-framework';

let $;

const loadScriptsIfNeeded = async.once(() => {
    const vowJquery = load({
        script: 'https://cdn.evidentid.com/common/jquery-3.3.1.min.js',
        setup(node) {
            node.async = false;
        },
    });

    return vowJquery
        .then(() => {
            return load('https://cdn.evidentid.com/common/intlTelInput-12.1.7.min.js');
        })
        .then(() => {
            $ = window.$;
            $.fn.intlTelInput.loadUtils('https://cdn.evidentid.com/common/intl-tel-input-utils.js');
        });
});


export default {
    oncreate({ attrs, dom }) {
        loadScriptsIfNeeded().then(() => {
            $(dom).intlTelInput();

            if (attrs.value) {
                $(dom).intlTelInput('setNumber', attrs.value);
            }
        });
    },
    view({
        attrs: {
            id = 'phone',
            value,
            onchange,
            placeholder,
            'data-harness': _harness,
        },
    }) {
        return m('input.PhoneNumberInput', harness(_harness, {
            id,
            value,
            placeholder,
            oninput: (e) => {
                if (!$) {
                    return;
                }

                const api = $(e.target);

                const inputValue = e.target.value;
                const valid = api.intlTelInput('isValidNumber');
                const countryCode = api.intlTelInput('getSelectedCountryData').dialCode;

                onchange({
                    value: inputValue,
                    valid,
                    countryCode,
                    qualifiedValue: (valid)
                        ? `+${countryCode || 1} ${inputValue.replace(/\D/g, '')}`
                        : null,
                });
            },
        }));
    },
};
