import { definition } from './errors';


// Extended JSON parser featuring typesafe parsing and Unicode escaping.
const json = {
    UnwantedJsonType: definition(null, {
        reason: 'unwanted-type',
        message: 'Expected type {expected} when parsing JSON document. Got {actual}.',
        config: {
            format: true,
        },
    }),
    ExpectedJsonTypeUnspecified: definition(null, {
        reason: 'unspecified-type',
        message: 'If you want extended JSON parsing, then state the type you want.',
    }),
    safeparse(jsonstr, jstype) {
        if (!jstype) {
            throw json.ExpectedJsonTypeUnspecified();
        }

        try {
            const v = JSON.parse(jsonstr);

            const expected = (typeof jstype === 'function')
                ? jstype(v)
                : typeof v === jstype;

            if (!expected) {
                throw json.UnwantedJsonType({
                    extra: {
                        expected: jstype,
                        actual: typeof v,
                        jsonstr,
                    },
                });
            }

            return [v, null];
        } catch (e) {
            return [null, e];
        }
    },
    escapedStringify(jsValue, { emitUnicode = false, replacer = null, space = null } = {}) {
        const jsonStr = JSON.stringify(jsValue, replacer, space);

        return (emitUnicode)
            ? jsonStr
            : jsonStr.replace(
                /[\u007f-\uffff]/g,
                (c) => `\\u${(`0000${c.charCodeAt(0).toString(16)}`).slice(-4)}`);
    },
    parseAsType(jsonstr, type, fallback) {
        const [val, err] = json.safeparse(jsonstr, type);

        if (err) {
            if (fallback === undefined) {
                throw err;
            } else {
                return fallback;
            }
        }

        return val;
    },
    boolean(jsonstr, fallback) {
        return json.parseAsType(jsonstr, 'boolean', fallback);
    },
    string(jsonstr, fallback) {
        return json.parseAsType(jsonstr, 'string', fallback);
    },
    object(jsonstr, fallback) {
        return json.parseAsType(jsonstr, 'object', fallback);
    },
    number(jsonstr, fallback) {
        return json.parseAsType(jsonstr, 'number', fallback);
    },
    integer(jsonstr, fallback) {
        return Math.floor(json.parseAsType(jsonstr, 'number', fallback));
    },
    array(jsonstr, fallback) {
        return json.parseAsType(jsonstr, (v) => Array.isArray(v), fallback);
    },
};

export default json;
