import { bem, m, router, harness } from '#/browser-framework';
import { AutoComplete, Named } from '#/browser-framework/comps';

import { Controls, PrimaryButton, SecondaryButton } from '#/ido-lib/views/InterviewControls';

const IdleFeedback = {
    view() {
        return null;
    },
};


const UserTypingFeedback = {
    view() {
        return null;
    },
};


const ProviderNotFound = {
    t: bem`ProviderNotFound heading body`,
    view() {
        const { t } = ProviderNotFound;

        return m(t.block,
            m(`h2${t.heading}`,
                m(Named.Icon, {name: 'alert'}), ' Sorry, this utility provider is not available'),
            m(`p${t.body}`,
                `Please try searching for another of your utility providers.
                If none of your providers are available, please contact us
                at `, m('a', { href: 'mailto:support@evidentid.com' }, 'support@evidentid.com'), '.'));
    },
};


const CredentialsForm = {
    t: bem`CredentialsForm field`,
    view({
        attrs: {
            q,
        },
    }) {
        const { t } = CredentialsForm;
        const { displayName } = q.selectedProvider;

        return [
            m('span', `Log in to your ${displayName} account:`),
            m(t.block,
                m(t.field,
                    m('label', `${displayName} username or email`),
                    m('input', harness('username-external-provider-interview-input', {
                        value: q.username,
                        oninput(e) {
                            q.username = e.target.value;
                        },
                    }))),
                m(t.field,
                    m('label', `${displayName} password`),
                    m('input', harness('password-external-provider-interview-input', {
                        type: 'password',
                        value: q.password,
                        oninput(e) {
                            q.password = e.target.value;
                        },
                    })))),
        ];
    },
};


export const ExternalProviderForm = {
    t: bem`ExternalProvider instructions workflow row`,
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const { block, instructions, workflow, row } = ExternalProviderForm.t;

        const feedback = {
            'idle': IdleFeedback,
            'typing': UserTypingFeedback,
            'not-found': ProviderNotFound,
            'found': CredentialsForm,
        };

        return m(block,
            m(instructions,
                'Verify one of your utility providers by logging into your service account.'),
            (q.suggestions) && m(workflow,
                m(row,
                    m('span', 'Search for a utility provider:'),
                    m(AutoComplete, q.suggestions)),
                m(row,
                    m(feedback[q.searchState], { q }))),
            m(Controls,
                m(SecondaryButton, {
                    onpress: () => router.go('/overview'),
                }, 'Do this later'),
                m(PrimaryButton, {
                    disabled: !q.maySubmit(),
                    onpress: q.submit,
                    onLastQuestion,
                }, 'Authorize')));
    },
};
