// Aggregation entails combining service data with metadata
// to simplify an expression of requirements.

import { capitalizeWord } from '#/universal-framework/strings';
import logger from '#/browser-framework/browserLogger';


const errRx = /^error/;

/**
 * Parses the attribute sharing agreement and adds each attributeSharingModel
 * to the provided inputAttributes object.
 * @typedef {Object} attributeSharingModel
 * @property {string} attrType
 * @property {boolean} complete
 * @property {boolean} hasError
 * @property {Array.<string>} shareWith
 *
 * @returns {Object.<string, attributeSharingModel>}
 */
export function extractAttributeSharingModelsFromASA(asa, attributeSharingModels) {
    asa.dependencies.dependentAttributes.forEach((dep) => {
        if (dep.attributeType in attributeSharingModels) {
            const ia = attributeSharingModels[dep.attributeType];
            ia.complete = ia.complete && dep.exists && dep.constraintsMet;
            ia.hasError = ia.hasError && Boolean(asa.fulfilment.status.match(errRx));
            ia.shareWith.push(asa.attribute_source);
        } else {
            attributeSharingModels[dep.attributeType] = {
                attrType: dep.attributeType,
                complete: dep.exists && dep.constraintsMet,
                hasError: Boolean(asa.fulfilment.status.match(errRx)),
                shareWith: (dep.shareWith || []).concat([asa.attribute_source]),
            };
        }
    });

    // Dedupe pass
    for (const meta of Object.values(attributeSharingModels)) {
        meta.shareWith = Array.from(new Set(meta.shareWith || []));
    }

    return attributeSharingModels;
}

/**
 * Returns a new map of attributeModels.  This function creates
 * a metadataModel from the verificationRequest AND adds it to a new object
 * that exends the respective attributeSharingModel, thus creating a new type.
 *
 * NOTE: This should simply create the metadataModel and let the caller
 * decide what to do with the data.  That would avoid the loop and clarify
 * object construction.
 * @param {Object.<string,attributeSharingModel>} attributeSharingModels
 * @param {Object} verificationRequest
 *
 * @typedef {Object} metadataModel
 * @property {string} title
 * @property {string} description
 * @property {string} inputType
 * @property {string} $objectType
 *
 * @typedef {Object} attributeWithMetaData
 * @property {metadataModel} metadata
 *
 * @typedef {attributeSharingModel & attributeWithMetaData} attributeModel
 *
 * @returns {Object.<string,attributeModel}
 */
export function combineMetadata(attributeSharingModels, verificationRequest) {
    return Object.entries(attributeSharingModels).reduce((withMeta, [attrType, attributeSharingModel]) => {
        const metadata = verificationRequest.inputMetadata[attrType] || {};

        const title = metadata.titleString || capitalizeWord(attrType || 'MISSING').replace(/\./g, ' ');
        const description = metadata.descriptionString || '';
        const inputType = metadata.type || 'string';

        withMeta[attrType] = Object.assign({}, attributeSharingModel, {
            metadata: {
                title,
                inputType,
                description,
                icon: (inputType === 'file')
                    ? 'camera'
                    : 'keyboard',
            },
        });

        return withMeta;
    }, {});
}


/**
 * Return a boolean value representing the IDO's aggregated sharing response.
 * @returns {boolean}
 */
export function sharingResponseFromRequest(request) {
    const statuses = new Set(request
        .attribute_sharing_agreements
        .map(({ status }) => status));

    const accepted = statuses.has('idowner-accepted');
    const declined = statuses.has('idowner-declined');

    // Report data inconsistencies upstream
    if (accepted && declined) {
        logger.error(new Error(
            `ASAs in request ${request.id} has a mix of accepted and declined IDO sharing responses`));
    }

    return accepted;
}


/**
 * Transforms a verification request into an intermediate input attribute model
 * NOTE: There is really no good name for this object as its construction is split
 * among many files, and functions whose sequencing is highly coupled.
 * @typedef {Object} InputAttributeModel
 * @property {Object.<string, attributeModel>} input
 * @property {boolean} consent
 * @property {string} id - the verificationRequest.rpr_id
 * @property {string} rpId - the verificationRequest.relyingparty_id
 * @property {*} balance
 * @property {string} summary
 * @property {string} description
 * @property {Object} consentHtml - consentHTML from the identity api response
 * @public
 *
 * @param {*} verificationRequest - modified response from api 'requests/' endpoint (includes consentHtml)
 * @returns {InputAttributeModel}
 */
export function aggregate(verificationRequest) {
    /**
     * @type {Object.<string, attributeSharingModel>}
     */
    const attributeSharingModels = verificationRequest.attribute_sharing_agreements.reduce((accumulatedModels, asa) =>
        extractAttributeSharingModelsFromASA(asa, accumulatedModels), {});

    const attributeModels = combineMetadata(attributeSharingModels, verificationRequest);
    const consent = sharingResponseFromRequest(verificationRequest);

    return Object.assign({ input: attributeModels }, {
        consent,
        id: verificationRequest.rpr_id,
        rpId: verificationRequest.relyingparty_id,
        balance: verificationRequest.idowner_cost,
        summary: verificationRequest.summary,
        description: verificationRequest.description,
        consentHtml: verificationRequest.consentHtml,
    });
}
