import EXIF from 'exif-js';

import features from '#/browser-framework/features';
import * as canvases from '#/browser-framework/canvases';
import * as blobs from '#/browser-framework/blobs';
import * as Acuant from '#/acuant-sdk';

const support = features(window);



// Field used for one page of an identifying document.
export function simplex() {
    const f = {
        errors: [],
        file: null,
        dataUrl: null,
    };

    f.computeDataUrl = () => {
        if (f.file) {
            f.dataUrl = undefined;

            return blobs
                .fileToArrayBuffer(f.file)
                .then((buffer) => {
                    const exif = EXIF.readFromBinaryFile(buffer);

                    const preprocessOnClient = Acuant.shouldPreprocessOnTheClient({
                        cardType: Acuant.ACUANT_CARD_TYPES.DRIVERS_LICENSE_DUPLEX,
                        isiOS: support.isIOS,
                        dpi: (exif.hasOwnProperty('XResolution'))
                            ? exif.XResolution.numerator / exif.XResolution.denominator
                            : 1,
                    });

                    return (preprocessOnClient)
                        ? canvases.processImage(f.file, { exif }).then(({dataUrl}) => dataUrl)
                        : blobs.arrayBufferToDataUrl(buffer);
                })
                .then((dataUrl) => {
                    f.dataUrl = dataUrl;

                    return dataUrl;
                });
        } else {
            f.file = f.dataUrl = null;

            return Promise.resolve(null);
        }
    };

    return f;
}
