import { debounce, spa, m, harness } from '#/browser-framework';
import { AutoComplete } from '#/browser-framework/comps';
import { getUtilityProviders } from '#/ido-lib/idoWebClient';

import { PendingScreen } from '#/ido-lib/views/StatusScreens';

import { ExternalProviderForm } from './ExternalProviderForm';


const ActiveView = {
    view({
        attrs,
    }) {
        return m(attrs.q.workflowView, attrs);
    },
};

export default function externalProvider(inputAttr) {
    const plugin = {};

    plugin.view = ActiveView;
    plugin.workflowView = ExternalProviderForm;

    /*
         idle: User is doing nothing with the provider search.
       typing: User is interacting with the field.
        found: User has stopped typing and what they typed matches a provider.
    not-found: User has stopped typing and what they typed does NOT match a provider.
    */
    plugin.searchState = 'idle';

    plugin.encode = () => {
        return {
            [inputAttr.metadata.submissionAttrType]: {
                shareWith: inputAttr.shareWith,
                value: {
                    $objectType: 'UtilityCredentials',
                    username: plugin.username,
                    password: plugin.password,
                    provider: plugin.selectedProvider.canonicalName,
                },
            },
        };
    };

    plugin.isValid = function isValid() {
        return (
            plugin.username &&
            plugin.password &&
            plugin.selectedProvider
        );
    };

    plugin.attemptLogin = spa.redrawAfter(() => {
        plugin.workflowView = PendingScreen;
        spa.redraw();

        return plugin.submit().then(() => plugin.waitForResponse());
    });

    plugin.scheduleNotFound = debounce(() => {
        plugin.searchState = 'not-found';
        spa.redraw();
    }, 800);

    const vowProviderData = (deploy.WEB_PUBLIC_DEVELOPER_MODE)
        ? Promise.resolve(Array.from('ABC').map((ch) => Object.assign({displayName: `Provider ${ch}`})))
        : getUtilityProviders();

    return vowProviderData.then((providers) => {
        // AutoComplete needs names
        const names = providers.map(({ displayName }) => displayName);

        plugin.suggestions = AutoComplete.makeViewModel({
            onselect(match) {
                plugin.selectedProvider = providers.find((p) => p.displayName === match.origin);

                // Prevent any pending not-found transitions
                plugin.scheduleNotFound.clear();
                plugin.searchState = 'found';

                return (plugin.selectedProvider)
                    ? plugin.selectedProvider.displayName
                    : match.origin;
            },
            computeMatches(needle) {
                // If we get here, the user must be fussing with the field.
                plugin.selectedProvider = null;
                plugin.searchState = 'typing';
                plugin.scheduleNotFound.clear();

                const matches = names
                    .filter((name) => name.toLowerCase().indexOf(needle.toLowerCase()) > -1)
                    .slice(0, 10)
                    .map((name) => ({
                        exploded: [{ text: name }],
                        origin: name,
                    }));

                if (matches.length === 0 && needle) {
                    plugin.scheduleNotFound();
                }

                return matches;
            },
        });

        plugin.suggestions.id = 'ext-providers';
        plugin.suggestions.placeholder = 'Utility providers';
        harness('external-provider-input', plugin.suggestions);

        return plugin;
    });
}
