import { bem, m, harness } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';

import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';

import US_SUBDIVISIONS from '#/ido-lib/states.json';

const STATES_AND_TERRITORIES = US_SUBDIVISIONS
    .sort(([a], [b]) => a.localeCompare(b));

const Form = {
    btx: bem`AddressQuestion
        form
        city
        state
        street
        postcode
    `,
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            metadata: {
                description,
                title,
            },
            isValid,
            encode,
        } = q;

        const {
            btx,
        } = Form;

        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit() {
                q.submit(encode());
            },
            onLastQuestion,
        },
        m(btx.block,
            m('h3', title),
            m('p', description),
            m(btx.form,
                m(btx.street,
                    m('input', harness('street-address-interview-input', {
                        value: q.street,
                        placeholder: 'Street address',
                        oninput(e) {
                            q.street = e.target.value;
                        },
                    }))),
                m(btx.city,
                    m('input', harness('city-address-interview-input', {
                        value: q.city,
                        placeholder: 'City',
                        oninput(e) {
                            q.city = e.target.value;
                        },
                    }))),
                m(btx.state,
                    m(Dropdown, harness('state-address-interview-dropdown', {
                        options: STATES_AND_TERRITORIES.map(([name]) => name),
                        placeholder: 'Select a state or territory',
                        value: q.stateIndex,
                        select(index, actual) {
                            q.stateIndex = index;
                            q.state = STATES_AND_TERRITORIES[actual];
                        },
                    }))),
                m(btx.postcode,
                    m('input', harness('postcode-address-interview-input', {
                        value: q.postcode,
                        placeholder: 'Zip',
                        oninput(e) {
                            q.postcode = e.target.value;
                        },
                    }))))));
    },
};


export default function Address() {
    const iface = {
        view: Form,
        street: '',
        city: '',
        state: [],
        postcode: '',

        encode() {
            const { street, city, state, postcode } = iface;

            return {
                [iface.attrType]: {
                    shareWith: iface.shareWith,
                    value: `${street}, ${city}, ${state[1]} ${postcode}`,
                },
            };
        },

        isValid() {
            const { street, city, state, postcode } = iface;
            return street && city && (state && state.length) && postcode;
        },
    };


    return iface;
}
