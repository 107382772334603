import { bem, m } from '#/browser-framework';
import { contractcomp } from '#/browser-framework/vcomps';
import { lets, types } from '#/universal-framework/functions';

/*
A KeyValue set is a two-column table such that each row has
a heading cell in the left column and a body cell in the right
column, for the intent of showing simple labeled data.
Each row is an instance of KeyValuePair.

e.g. To render the following:
+---------+------------+
| Name:   | John Doe   |
+---------+------------+
| Age:    | 54         |
+---------+------------+
| Height: | 72 in      |
+---------+------------+

Use this vnode:

m(KeyValueSet, {
    pairs: [
        ['Name:', 'John Doe'],
        ['Age:', '54'],
        ['Height:', '72 in'],
    ],
})
*/

const { any, arrayOf } = types;

const KeyValuePair =
    lets(['KeyValuePair'], (displayName) =>
        lets([bem`${displayName} key value`],
            ({ block, key: ktx, value: vtx }) =>
                contractcomp(displayName, { k: any, v: any },
                    ({ k, v }) =>
                        m(`tr${block}`,
                            m(`th${ktx}`, k),
                            m(`td${vtx}`, v)))));

export default lets(['KeyValueSet'], (displayName) =>
    contractcomp(displayName, { pairs: arrayOf(arrayOf(any)) },
        ({ pairs }) =>
            m(`table.${displayName}`,
                m('tbody', pairs.map(([k, v], key) =>
                    m(KeyValuePair, { key, k, v }))))));
