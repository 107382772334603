import { m } from '#/browser-framework';
import { ConsentCheckbox } from '#/browser-framework/comps';
import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';


const PastNamesCheckbox = {
    view({attrs: {pastNames, onChange}}) {
        return m(ConsentCheckbox, {
            checked: pastNames,
            onchange: onChange,
            cssclass: 'PastNameQuestion__acceptCheck',
        }, 'I have a maiden name or previous name to report.');
    },
};

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            first,
            middle,
            last,
            isValid,
            setNames,
            hasPastName,
            setIsName,
        } = q;

        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(PastNamesCheckbox, {
            pastNames: hasPastName,
            onChange: setIsName,
        }),

        m(LineInputBlock,
            m('span', 'Enter your past first name', m('i.fg-danger', '*')),
            m('input', {
                value: first,
                disabled: !hasPastName,
                oninput: (e) => {
                    setNames(e.target.value, middle, last);
                },
            })),
        m(LineInputBlock,
            'Enter your past middle name',
            m('input', {
                value: middle,
                disabled: !hasPastName,
                oninput: (e) => {
                    setNames(first, e.target.value, last);
                },
            })),
        m(LineInputBlock,
            m('span', 'Enter your past last name', m('i.fg-danger', '*')),
            m('input', {
                value: last,
                disabled: !hasPastName,
                oninput: (e) => {
                    q.setNames(first, middle, e.target.value);
                },
            })),

        );
    },
};

export default function pastNameQuestion() {
    const _state = {
        first: '',
        middle: '',
        last: '',
        hasPastName: true,
        view: Form,

        encode() {
            return {
                [_state.attrType]: {
                    shareWith: _state.shareWith,
                    value: {
                        $objectType: 'Name',
                        first: _state.first,
                        middle: _state.middle,
                        last: _state.last,
                    },
                },
            };
        },

        isValid() {
            return (!_state.hasPastName || (_state.first.length > 0 && _state.last.length > 0));
        },

        setIsName() {
            _state.hasPastName = !_state.hasPastName;
            _state.first = '';
            _state.middle = '';
            _state.last = '';
        },

        setNames(first, middle, last) {
            _state.first = first;
            _state.middle = middle;
            _state.last = last;
        },
    };

    return _state;
}
