import { m, harness } from '#/browser-framework';

import { SuccessScreen } from './StatusScreens';


export const SuccessPage = {
    view({ attrs: spa }) {
        const {
            completeStatusHeadingText,
            completeStatusMessageText,
        } = spa.viewModel.interview.interviewModel.content;

        return m(SuccessScreen,
            m('h3', harness('ido-facing-completed-request-heading'), completeStatusHeadingText),
            m('p.RequestComplete__CmsCopy', harness('ido-facing-completed-request-message'),
                completeStatusMessageText),
            m('a[href="https://www.evidentid.com/own-your-identity/"]'));
    },
};
