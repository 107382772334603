import { poll } from '#/universal-framework/async';
import { router, spa } from '#/browser-framework';

import { getTransitionState } from '#/ido-lib/idoWebClient';


let _exclusivePoll;
export function makeTransitionPoll(rprId) {
    if (_exclusivePoll) {
        _exclusivePoll.stop();
    }

    _exclusivePoll = poll({
        peri() {
            return getTransitionState(rprId);
        },
        post({ status, transitionUri }) {
            switch (status) {
            case 'transitionReady':
                _exclusivePoll.stop();
                spa.$window.location.href = transitionUri;
                break;
            case 'transitionNotNeeded':
                _exclusivePoll.stop();
                router.go('/done', { replace: true });
                break;
            case 'transitionPending':
            default:
                break;
            }

            return 1000;
        },
    });

    return _exclusivePoll;
}
