import {m} from '#/browser-framework';
import { getAttributeData } from '#/ido-lib/idoWebClient';
import { purecomp } from '#/browser-framework/vcomps';
import { Dropdown, Named} from '#/browser-framework/comps';
import { bem } from '#/browser-framework/taxonomy';
import { SecondaryQuestionForm } from '../views/Layouts';

const chargeNavBtx = bem`ChargeNav title NavLink NavLink--active NavLink--inactive `;
const chargeInfoBtx = bem`ChargeInfo field label content`;


const ChargeNavigator = () => purecomp(({visibleChargeIndex, charges, increment, decrement}) => m(chargeNavBtx.block,
    m(visibleChargeIndex > 0
        ? `button${chargeNavBtx.NavLink}${chargeNavBtx['NavLink--active']}`
        : `button${chargeNavBtx.NavLink}${chargeNavBtx['NavLink--inactive']}`
        , { onmouseup: () => decrement(visibleChargeIndex)}, 'Previous'),
    m(chargeNavBtx.title, `Charge ${visibleChargeIndex + 1} of ${charges ? charges.length : 1}`),
    m(visibleChargeIndex + 1 < charges.length
        ? `button${chargeNavBtx.NavLink}${chargeNavBtx['NavLink--active']}`
        : `button${chargeNavBtx.NavLink}${chargeNavBtx['NavLink--inactive']}`
        , {onmouseup: () => increment(visibleChargeIndex)}, 'Next')));

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const charge = q.charges[q.visibleChargeIndex];
        const lastIndex = q.charges.length - 1;
        const disablableLabel = `label${charge.rehabilitationNone ? '.disabledRehabQ' : ''}`;
        return m(SecondaryQuestionForm, {
            buttonText: q.visibleChargeIndex === lastIndex ? 'Save' : 'Continue to the next charge',
            isDisabled: lastIndex === q.visibleChargeIndex
                ? !q.isValid(q.charges)
                : !q.chargeChecker(charge),
            showSubmit: q.visibleChargeIndex === lastIndex,
            onLastQuestion,
            onSubmit: () => {
                if (q.isValid(q.charges)) {
                    q.submit();
                }
            },
            onPress: () => q.increment(q.visibleChargeIndex),
        },
        q.charges.length > 1 && m(ChargeNavigator, {
            charges: q.charges,
            visibleChargeIndex: q.visibleChargeIndex,
            increment: q.increment,
            decrement: q.decrement,
        }),
        m(chargeInfoBtx.block,
            m(chargeInfoBtx.field,
                m(chargeInfoBtx.label, 'Charge: '),
                m(chargeInfoBtx.content, charge.chargeName)),
            m(chargeInfoBtx.field,
                m(chargeInfoBtx.label, 'Date: '),
                m(chargeInfoBtx.content, charge.chargeDate))),
        m('label.FullWidthInputBlock',
            m('span', 'How old, in years, were you when the offense took place?'),
            m('input', {
                type: 'number',
                value: charge.ageAtOffense,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'ageAtOffense', parseInt(e.target.value, 10)),
            })),
        m('label.FullWidthInputBlock',
            m('span', 'What is your current or most recent position? How long have you been in your current or most recent Position?'),
            m('textarea', {
                value: charge.lastPosition,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'lastPosition', e.target.value),
            })),
        m('label.FullWidthInputBlock',
            m('span', 'In your own words, can you describe what led to your being charged with the offense? Please include detailed information around who was involved in the offense, what was your role, and any other relevant factors.'),
            m('textarea', {
                value: charge.cause,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'cause', e.target.value),
            })),
        m('span.ChargeInfo__label', 'Please select all rehabilitation efforts that apply (including any required or recommended relevant to Civil Charges):'),
        m('.RehabQs',
            m('.standardEfforts',
                m(disablableLabel,
                    m('input', {
                        type: 'checkbox',
                        checked: charge.rehabilitationParole,
                        onclick: (e) => {
                            charge.rehabilitationNone && (charge.rehabilitationNone = false);
                            q.updateCharge(q.visibleChargeIndex, 'rehabilitationParole', e.target.checked);
                        },
                    }),
                    charge.rehabilitationParole
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'Parole'),
                m(disablableLabel,
                    m('input', {
                        type: 'checkbox',
                        checked: charge.rehabilitationProbation,
                        onclick: (e) => {
                            charge.rehabilitationNone && (charge.rehabilitationNone = false);
                            q.updateCharge(q.visibleChargeIndex, 'rehabilitationProbation', e.target.checked);
                        },
                    }),
                    charge.rehabilitationProbation
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'Probation'),
                m(disablableLabel,
                    m('input', {
                        type: 'checkbox',
                        checked: charge.rehabilitationEducation,
                        onclick: (e) => {
                            charge.rehabilitationNone && (charge.rehabilitationNone = false);
                            q.updateCharge(q.visibleChargeIndex, 'rehabilitationEducation', e.target.checked);
                        },
                    }),
                    charge.rehabilitationEducation
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'Education'),
                m(disablableLabel,
                    m('input', {
                        type: 'checkbox',
                        checked: charge.rehabilitationTraining,
                        onclick: (e) => {
                            charge.rehabilitationNone && (charge.rehabilitationNone = false);
                            q.updateCharge(q.visibleChargeIndex, 'rehabilitationTraining', e.target.checked);
                        },
                    }),
                    charge.rehabilitationTraining
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'Training'),
                m(disablableLabel,
                    m('input', {
                        type: 'checkbox',
                        checked: charge.rehabilitationAlcohol,
                        onclick: (e) => {
                            charge.rehabilitationNone && (charge.rehabilitationNone = false);
                            q.updateCharge(q.visibleChargeIndex, 'rehabilitationAlcohol', e.target.checked);
                        },
                    }),
                    charge.rehabilitationAlcohol
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'Alcohol Treatment'),
                m(disablableLabel,
                    m('input', {
                        type: 'checkbox',
                        checked: charge.rehabilitationDrug,
                        onclick: (e) => {
                            charge.rehabilitationNone && (charge.rehabilitationNone = false);
                            q.updateCharge(q.visibleChargeIndex, 'rehabilitationDrug', e.target.checked);
                        },
                    }),
                    charge.rehabilitationDrug
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'Drug Treatment'),
            ),
            m('.otherEfforts',
                m('label',
                    m('input', {
                        type: 'checkbox',
                        checked: charge.rehabilitationNone,
                        onclick: (e) => {
                            charge.rehabilitationParole = false;
                            charge.rehabilitationProbation = false;
                            charge.rehabilitationEducation = false;
                            charge.rehabilitationTraining = false;
                            charge.rehabilitationAlcohol = false;
                            charge.rehabilitationDrug = false;
                            q.isOtherTreatments[q.visibleChargeIndex] = false;
                            q.updateCharge(q.visibleChargeIndex, 'rehabilitationNone', e.target.checked);
                        },
                    }),
                    charge.rehabilitationNone
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'None'),
                m(disablableLabel,
                    m('input', {
                        type: 'checkbox',
                        checked: q.isOtherTreatments[q.visibleChargeIndex],
                        onclick: (e) => {
                            charge.rehabilitationNone && (charge.rehabilitationNone = false);
                            q.isOtherTreatments[q.visibleChargeIndex] = e.target.checked;
                        },
                    }),
                    q.isOtherTreatments[q.visibleChargeIndex]
                        ? m(Named.Icon, { name: 'checked'})
                        : m(Named.Icon, { name: 'unchecked' }),
                    'Other Treatment/Program (describe below)'),
                m(`textarea${q.isOtherTreatments[q.visibleChargeIndex] === false ? '.disabledRehabQ' : ''}`, {
                    disabled: !q.isOtherTreatments[q.visibleChargeIndex],
                    value: charge.rehabilitationOther,
                    oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'rehabilitationOther', e.target.value),
                }))),
        m('label.FullWidthInputBlock',
            m('span', 'Have you satisfied parole and/or probation requirements, or any requirements necessary to complete for civil offenses? Please detail below.'),
            m('textarea', {
                value: charge.paroleRequirements,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'paroleRequirements', e.target.value),
            })),
        m('label.FullWidthInputBlock',
            m('span', 'Have you received education and/or training related to your offense? Please detail below, including what the program was, how long the program is, and time left to complete the program, if any.'),
            m('textarea', {
                value: charge.offenseTraining,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'offenseTraining', e.target.value),
            })),
        m('label.FullWidthInputBlock',
            m('span', 'Have you participated in alcohol and/or drug treatment programs? Please detail below, including what the program was, how long the program is, and time left to complete the program , if any.'),
            m('textarea', {
                value: charge.substanceTraining,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'substanceTraining', e.target.value),
            })),
        m('label.FullWidthInputBlock',
            m('span', 'What additional efforts have you made to change behavior to avoid future offenses? Please provide details below.'),
            m('textarea', {
                value: charge.additionalEffort,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'additionalEffort', e.target.value),
            })),
        m('label.FullWidthInputBlock',
            m('span', 'Are you out on bond?'),
            m(Dropdown, {
                placeholder: 'Select one',
                disabled: false,
                value: q.bondedIndices[q.visibleChargeIndex],
                options: q.bondedOptions,
                select(i, actual) {
                    q.updateCharge(q.visibleChargeIndex, 'isBonded', Boolean(q.bondedOptions[actual] === 'Yes'));
                    q.bondedIndices[q.visibleChargeIndex] = i;
                },
            })
        ),
        m('label.FullWidthInputBlock',
            m('span', 'Is there any additional information surrounding the offense you would like to add?'),
            m('textarea', {
                value: charge.additionalInformation,
                oninput: (e) => q.updateCharge(q.visibleChargeIndex, 'additionalInformation', e.target.value),
            })),
        q.charges.length > 1 && m(ChargeNavigator, {
            charges: q.charges,
            visibleChargeIndex: q.visibleChargeIndex,
            increment: q.increment,
            decrement: q.decrement,
        }));
    },
};

export default function iaForm() {
    const _state = {
        view: Form,
        visibleChargeIndex: 0,
        bondedIndices: [0],
        isOtherTreatments: [false],
        bondedOptions: ['Yes', 'No'],
        context: null,
        charges: [{
            chargeName: 'loading...',
            chargeDate: 'loading...',
            isBonded: null,
            cause: null,
            ageAtOffense: null,
            lastPosition: null,
            additionalInformation: null,
            additionalEffort: null,
            offenseTraining: null,
            substanceTraining: null,
            paroleRequirements: null,
            parole: false,
            probation: false,
            education: false,
            training: false,
            alcoholTreatment: false,
            drugTreatment: false,
            otherTreatments: null,
        }],
        chargeChecker: (charge) => {
            // chargeChecker returns true if
            // the user has completed all text fields and
            // has selected either 'None' or some rehab effort(s)
            // additionally, it checks to make sure that the rehabilitationOther field isn't a blank string

            return (charge.cause &&
            charge.ageAtOffense &&
            charge.lastPosition &&
            _state.bondedIndices[_state.visibleChargeIndex] > 0 &&
            charge.additionalInformation &&
            charge.additionalEffort &&
            charge.offenseTraining &&
            charge.substanceTraining &&
            charge.paroleRequirements &&
            (charge.rehabilitationNone || (charge.rehabilitationEducation ||
            charge.rehabilitationAlcohol ||
            charge.rehabilitationTraining ||
            charge.rehabilitationParole ||
            charge.rehabilitationProbation ||
            charge.rehabilitationDrug ||
            (_state.isOtherTreatments[_state.visibleChargeIndex] &&
            charge.rehabilitationOther &&
            charge.rehabilitationOther.trim().length))));
        },
        increment: (visibleChargeIndex) => {
            if (_state.visibleChargeIndex + 1 < _state.charges.length) {
                _state.visibleChargeIndex = visibleChargeIndex + 1;
            }
        },
        decrement: (visibleChargeIndex) => {
            if (_state.visibleChargeIndex > 0) {
                _state.visibleChargeIndex = visibleChargeIndex - 1;
            }
        },
        updateCharge: (chargeIndex, chargeField, chargeFieldContent) => {
            if (chargeField in _state.charges[chargeIndex]) {
                _state.charges[chargeIndex][chargeField] = chargeFieldContent;
            }
        },
        encode() {
            /*
            if the user hasnt chosen other
            rehabilitation efforts but has still entered
            information in the field, that information is not encoded
             */
            _state.charges = _state.charges.map((charge) => {
                if (_state.isOtherTreatments[_state.visibleChargeIndex] === false) {
                    charge.rehabilitationOther = null;
                }
                return charge;
            });

            return {
                [_state.attrType]: {
                    value: _state.charges,
                    shareWith: _state.shareWith,
                },
            };
        },
        isValid() {
            const chargeStatus = _state.charges.map((charge) => (_state.chargeChecker(charge)));
            for (const c of chargeStatus) {
                if (!c) {
                    return false;
                }
            }
            return true;
        },
    };

    getAttributeData().then((result) => {
        _state.context = result.attributeData.map((offenseCategory) => (offenseCategory[1].offenses)).flat();

        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'];

        _state.charges = _state.context.map((charge) => ({
            chargeName: charge.specificOffense || 'Offense Not found',
            chargeDate: charge.effectiveDate
                ? `${monthNames[charge.effectiveDate.month - 1]} ${charge.effectiveDate.day}, ${charge.effectiveDate.year}`
                : 'Date Not Found',
            chargeID: charge.id,
            isBonded: null,
            cause: null,
            ageAtOffense: null,
            lastPosition: null,
            additionalInformation: null,
            additionalEffort: null,
            offenseTraining: null,
            substanceTraining: null,
            paroleRequirements: null,
            rehabilitationParole: false,
            rehabilitationProbation: false,
            rehabilitationEducation: false,
            rehabilitationTraining: false,
            rehabilitationAlcohol: false,
            rehabilitationDrug: false,
            rehabilitationNone: false,
            rehabilitationOther: null,
        }));
        _state.bondedIndices = _state.charges.map(() => 0);
        _state.isOtherTreatments = _state.charges.map(() => false);
        m.redraw();
    });

    return _state;
}
