import EXIF from 'exif-js';

import AssureSDK from '#/assure-sdk';
import { blobs, canvases, m, router, spa, support } from '#/browser-framework';
import { delegationController } from '#/ido-lib/delegationController';

import { LargeFormatSelfieDelegation } from './views/LargeFormatSelfieDelegation';
import { MobileSelfieForm } from './views/MobileSelfieForm';

import { InterviewCard } from '#/ido-lib/views/Layouts';
import { PendingScreen } from '#/ido-lib/views/StatusScreens';

const ActiveView = {
    view({
        attrs,
    }) {
        return m(attrs.q.workflowView, attrs);
    },
};


export default function selfie({ attrType, shareWith, metadata }) {
    const plugin = {};

    plugin.delegate = delegationController(attrType);
    plugin.view = ActiveView;
    plugin.dataUrl = null;
    plugin.copySource = metadata.copySource || 'vanillaSelfieContent';
    plugin.workflowView = (support.isMobileBrowser)
        ? MobileSelfieForm
        : LargeFormatSelfieDelegation;

    plugin.delegateToMobile = function delegateToMobile() {
        return plugin.delegate.sendText();
    };

    plugin.cancelDelegation = function cancelDelegation() {
        router.go('/overview');
        spa.$window.location.reload();
    };

    plugin.stageSelfie = spa.redrawAfter((file) => {
        plugin.stagedSubmission = null;

        if (!file) {
            plugin.dataUrl = null;

            return Promise.resolve();
        }


        return blobs
            .fileToArrayBuffer(file)
            .then((buffer) => {
                const exif = EXIF.readFromBinaryFile(buffer);

                return canvases.processImage(file, { exif });
            })
            .then(({ dataUrl }) => {
                plugin.dataUrl = dataUrl;
                plugin.stagedSubmission = AssureSDK.createBinaryDataFromDataUrl(dataUrl);
            });
    });

    plugin.sendSelfie = spa.redrawAfter(() => {
        spa.setView(PendingScreen, {
            layout: InterviewCard,
        });

        return plugin.submit();
    });

    plugin.encode = function encode() {
        return {
            [attrType]: {
                shareWith,
                value: plugin.stagedSubmission,
            },
        };
    };

    plugin.isValid = function isValid() {
        return Boolean(plugin.stagedSubmission);
    };

    return plugin;
}
