import { bem, m } from '#/browser-framework';
import { Snackbar } from '#/browser-framework/comps';

import { PhoneDelegationInput } from '#/ido-lib/views/PhoneDelegationInput';
import { Controls, SecondaryButton } from '#/ido-lib/views/InterviewControls';

import * as content from './selfieContent';


export const LargeFormatSelfieDelegation = {
    view({
        attrs: {
            q,
        },
    }) {
        const btx = bem`LargeFormatSelfieDelegation visualAid instructions`;
        const copy = content[q.copySource];

        return [
            m(btx.block,
                m(btx.visualAid,
                    m(content.SelfieCaptureVisualAid)),
                m(btx.instructions,
                    m('h2', copy.heading),
                    m('p', copy.reason),
                    m('p', copy.delegationInstructions),
                    m(PhoneDelegationInput, {
                        id: 'selfie-delegation-control',
                        delegationController: q.delegate,
                        onDelegationRequest: q.delegateToMobile,
                    }))),
            m(Controls,
                m(SecondaryButton, {
                    onpress: q.cancelDelegation,
                }, 'Go back')),
            m(Snackbar, q.delegate.snackbar),
        ];
    },
};
