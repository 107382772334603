import m from 'mithril';
import flatpickr from 'flatpickr';

import { Named } from '#/browser-framework/comps';


export default {
    oncreate(vnode) {
        vnode.state.fp = flatpickr(vnode.dom.querySelector('input'), {
            allowInput: true,
            animate: false,
            dateFormat: 'm/d/Y',
            clickOpens: false,
            enable: [vnode.attrs.enable],
            mode: 'single',
            onClose: (selectedDate) => {
                if (selectedDate) {
                    vnode.attrs.select(selectedDate[0]);
                }
            },
        });
    },

    onupdate(vnode) {
        if (vnode.state.fp) {
            vnode.state.fp.input = vnode.dom.querySelector('input');
        }
    },

    onremove(vnode) {
        vnode.state.fp = null;
        (window.document.querySelectorAll('.flatpickr-calendar') || []).forEach((n) =>
            n.remove());
    },

    view({
        attrs,
        state,
    }) {
        const inputState = attrs;
        inputState.placeholder = 'mm/dd/yyyy';

        return m('.DatePicker',
            m('input', inputState),
            m(Named.Icon, {
                name: 'calendar',
                onclick: () => {
                    if (state) {
                        state.fp.open();
                    }
                },
            }));
    },
};
