import { m, spa } from '#/browser-framework';

const next = ({
    attrs: {
        contextType = '2d',
        contextAttributes,
        draw,
        sceneVars,
        width,
        height,
    },
    dom,
    state,
}) => {
    if (!state.doubleBuffer) {
        state.doubleBuffer = spa.$window.document.createElement('canvas');
        state.doubleBuffer.width = width;
        state.doubleBuffer.height = height;
        state.doubleBufferCtx = state.doubleBuffer.getContext(contextType, contextAttributes);
    }

    if (typeof draw === 'function') {
        if (state.requestId) {
            spa.$window.cancelAnimationFrame(state.requestId);
        }

        state.requestId = spa.$window.requestAnimationFrame((timestamp) => {
            state.doubleBufferCtx.save();
            draw(state.doubleBufferCtx, sceneVars, timestamp);
            state.doubleBufferCtx.restore();
            dom.getContext(contextType, contextAttributes).drawImage(state.doubleBuffer, 0, 0);
        });
    }
};

export default {
    oncreate: next,
    onupdate: next,
    view({
        attrs: {
            id,
            width,
            height,
        },
    }) {
        const toPass = { id };

        if (width) {
            Object.assign(toPass, { width });
        }

        if (height) {
            Object.assign(toPass, { height });
        }

        return m('canvas.Scene', toPass);
    },
};
