// In this file, a "promissory" is a function that returns a Promise.


// Always return the promise from the first promissory() call.
export function once(promissory) {
    let vow;
    let val;

    const fn = (...args) => {
        if (!vow) {
            vow = Promise.resolve(promissory(...args)).then((v) => {
                val = v;

                return val;
            });
        }

        return vow;
    };

    fn.release = () => {
        vow = val = undefined;
    };

    fn.cache = () => val;

    return fn;
}


export function settleTime(promise) {
    const start = Date.now();
    const callTime = () => Date.now() - start;

    return promise.then(callTime).catch(callTime);
}

// Call fn() immediately after promissory() settles.
export function always(promissory, fn) {
    return (...args) => {
        return Promise
            .resolve(promissory(...args))
            .catch((e) => {
                fn();

                return Promise.reject(e);
            })
            .then((v) => {
                fn();

                return v;
            });
    };
}

// Prevent multiple pending promises caused by promissory() calls.
export function exclusive(promissory) {
    let vow;

    return (...args) => {
        if (!vow) {
            vow = Promise
                .resolve(promissory(...args))
                .catch((e) => {
                    vow = null;

                    throw e;
                })
                .then((v) => {
                    vow = null;

                    return v;
                });
        }

        return vow;
    };
}

export function wrapErrors(promissory) {
    return (...args) => new Promise((resolve, reject) => {
        try {
            resolve(promissory(...args));
        } catch (e) {
            reject(e);
        }
    });
}

export function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}


export const HALT = new Promise((r) => {}); // eslint-disable-line no-unused-vars

function _poll({ peri, post }) {
    let stopped = false;
    let attempt = 0;

    const controller = {
        stop() {
            stopped = true;
        },
        start() {
            ++attempt;

            return Promise.resolve(peri()).then((value) => {
                const backoff = post(value, attempt) || 2000;

                return (stopped)
                    ? value
                    : sleep(backoff).then(controller.start);
            }).catch((e) => {
                stopped = true;

                throw e;
            });
        },
    };

    return controller;
}

/**
 * A wrapper class to a native promise.
 * It can be resolved externally, from outside a handler.
 * ex.: new ExtPromise().resolve();
 */
export class ExtPromise {
    constructor() {
        this._promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        this.then = this._promise.then.bind(this._promise);
        this.catch = this._promise.catch.bind(this._promise);
        this.finally = this._promise.finally.bind(this._promise);
        this[Symbol.toStringTag] = 'Promise';
    }
}

export const poll = _poll;
