import littleLoader from 'little-loader';

const cache = {};


function loadOne(script, setup = () => {}) {
    // We disable eslint rule below because the vendor calls for that identifier.
    if (script in cache) {
        return cache[script];
    }

    cache[script] = new Promise((resolve, reject) => {
        littleLoader(script, {
            setup,
            callback(err) { // eslint-disable-line id-blacklist
                if (err) {
                    delete cache[script];
                    reject(err);
                } else {
                    resolve();
                }
            },
        });
    });

    return cache[script];
}


export default function load(...args) {
    const queries = args.map((arg) => {
        if (typeof arg === 'string') {
            return loadOne(arg);
        } else if (typeof arg === 'object') {
            return loadOne(arg.script, arg.setup);
        } else {
            throw new Error(`Cannot load script using ${arg}`);
        }
    });

    return Promise.all(queries);
}
