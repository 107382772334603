import {m} from '#/browser-framework';


export default {
    view({
        attrs: {id},
        children,
    }) {
        return m('label.LineInputBlock', {for: id}, children);
    },
};
