import {m} from '#/browser-framework';
import AutoCompleteEntries from './AutoCompleteEntries';


const AutoComplete = {
    makeViewModel: ({ onselect, computeMatches, placeholder }) => {
        const self = {
            needle: '',
            placeholder,
            matches: [],
            focused: 0,
            focus(i) {
                self.focused = i;

                return self.focused;
            },
            async changeneedle(v) {
                self.needle = v;
                self.focused = 0;

                if (self.needle === '') {
                    self.matches = [];
                    return;
                }

                self.matches = await Promise.resolve(computeMatches(self.needle));
                m.redraw();
            },
            select(i) {
                self.needle = onselect(self.matches[i]) || '';
                self.matches = [];
            },
        };

        return self;
    },
    view({
        attrs: {
            'data-harness': dataharness,
            id,
            attached = true,
            matches,
            placeholder,
            needle,
            focus,
            select,
            focused,
            changeneedle,
        },
    }) {
        return m('label.AutoComplete', {
            style: (attached)
                ? 'overflow:visible;position:relative'
                : '',
        }, [
            m('input.AutoComplete__input', {
                'data-harness': dataharness,
                id,
                placeholder,
                value: needle,
                autocomplete: 'nope',
                oninput: (e) => {
                    changeneedle(e.target.value);
                },
                onblur: () => focus(-1),
                onkeydown: (e) => {
                    switch (e.keyCode) {
                    // Escape
                    case 27:
                        changeneedle('');
                        break;

                    // Up arrow key
                    case 38:
                        e.preventDefault();
                        focus(Math.max(-1, focused - 1));
                        break;

                    // Down arrow key
                    case 40:
                        e.preventDefault();
                        focus(Math.min(matches.length - 1, focused + 1));
                        break;

                    // Enter and tab keys
                    case 9: case 13:
                        // Allow default behavior in the event the
                        // field is empty.
                        if (e.target.value.length > 0 && matches.length > 0) {
                            e.preventDefault();
                            select(focused);
                        }
                        break;

                    default:
                        break;
                    }
                },
            }),
            (attached) && m(AutoCompleteEntries, {
                id,
                matches,
                focus,
                focused,
                select,
            }),
        ]);
    },
};

export default AutoComplete;
