import m from 'mithril';

import Ballotbox from './Ballotbox';
import toggle from './decorators/toggle';


export default toggle({
    view({attrs: {state}, children}) {
        return m('.LabeledCheckbox',
            m('span.LabeledCheckbox__checkbox', m(Ballotbox, {checked: state})),
            (children.length > 0)
                ? m('span.LabeledCheckbox__label', children)
                : null);
    },
});
