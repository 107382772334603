import {async, m, spa} from '#/browser-framework';
import Emitter from 'quill/core/emitter';

const loadQuill = async.once(() => import('quill'));

const editors = {};

function editor(vnode) {
    return loadQuill().then(({default: Quill}) => {
        const editorId = vnode.attrs.id;
        const attributes = editors[editorId];
        vnode.state.editor = new Quill(`#${editorId}`, {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'header': 1 }, { 'header': 2 }],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'font': [] }],
                    [{ 'align': [] }],
                    ['clean'],
                ],
            },
            placeholder: vnode.attrs.placeholder,
            readOnly: false,
            theme: 'snow',
            debug: 'warn',
        });

        if (attributes.delta) {
            vnode.state.editor.setContents(attributes.delta);
            vnode.state.editor.setSelection(attributes.selection);

            attributes.delta = attributes.selection = null;
        }

        const {onchange = () => {}} = vnode.attrs;

        vnode.state.editor.on('text-change', (...args) => {
            const source = args[args.length - 1];
            const ignoreCallback = attributes.ignoreNextCallback;
            attributes.ignoreNextCallback = false;
            if (source === Emitter.sources.USER && !ignoreCallback) {
                onchange(vnode.dom.querySelector('.ql-editor').innerHTML);
            }
        });
    });
}

export default {
    view({ attrs: { id, onchange, getValue}, placeholder, children}) {
        if (!(id in editors)) {
            editors[id] = {
                _ready: false,
                // prevents recursive refresh
                ignoreNextCallback: false,
                oncreate(...args) {
                    editor(...args).then(() => {
                        editors[id]._ready = true;
                        editors[id].ignoreNextCallback = true;
                        spa.redraw();
                    });
                },
                onremove(vnode) {
                    if (this.editor) {
                        editors[id].delta = this.editor.getContents();
                        editors[id].selection = this.editor.getSelection();

                        vnode.dom
                            .parentElement
                            .querySelectorAll('[class^="ql-"]')
                            .forEach((node) => node.remove());

                        this.editor.off('text-change');

                        this.editor = null;
                    }
                },
                onupdate() {
                    // getValue function allows to update editor content
                    // after it was initialized
                    if (this.editor && getValue && typeof getValue === 'function') {
                        const { value, preventRerender } = getValue();
                        if (!preventRerender) {
                            editors[id].ignoreNextCallback = true;
                            this.editor.root.innerHTML = value;
                        }
                    }
                },
                view() {
                    return m(`.RichTextEditor#${id}`, {
                        style: (editors[id]._ready)
                            ? ''
                            : 'visibility:hidden',
                    }, children);
                },
            };
        }

        return m(editors[id], {id, placeholder, onchange});
    },
};
