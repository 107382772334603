
const HEX = /^#([\da-f]{3}|[\da-f]{6})$/i;
const RGB = /^\s*\d{1,3},\s*\d{1,3},\s*\d{1,3}$/;

export function colorModel() {
    return {
        r: 0,
        g: 0,
        b: 0,
        input: '#000',
        model: 'hex',
    };
}

export function hexStringToDec(hexString) {
    return parseInt((hexString || '').toLowerCase(), 16);
}

export function decToHexString(dec) {
    return dec.toString(16);
}


export function inputStringToColor(colorString) {
    if (HEX.test(colorString)) {
        let inputString = colorString.substring(1);

        if (inputString.length === 3) {
            const [a, b, c] = inputString;

            inputString = `${a}${a}${b}${b}${c}${c}`;
        }

        const dec = hexStringToDec(inputString);

        return {
            model: 'hex',
            r: dec >> 16,
            g: (dec >> 8) & 0xff,
            b: dec & 0xff,
            input: colorString,
        };
    }


    if (RGB.test(colorString)) {
        const [r, g, b] = colorString.split(',').map((tok) => {
            return Math.max(0, Math.min(255, parseInt(tok.replace(/\s/g, ''), 10)));
        });

        return {
            model: 'rgb',
            r, g, b,
            input: colorString,
        };
    }

    return {
        input: colorString,
    };
}

export function toRgb(color) {
    if (!color.model) {
        throw new Error('toRgb called on unknown color');
    } else if (color.model === 'rgb') {
        return Object.assign({}, color);
    }

    return Object.assign({}, color, {
        input: `${color.r},${color.g},${color.b}`,
    });
}

export function toHex(color) {
    if (!color.model) {
        throw new Error('toHex called on unknown color');
    } else if (color.model === 'hex') {
        return Object.assign({}, color);
    }

    const {r, g, b} = color;

    const expand = (ch) => {
        const s = decToHexString(ch);

        return (s.length === 1)
            ? s + s
            : s;
    };

    return Object.assign({}, color, {
        input: `#${expand(r)}${expand(g)}${expand(b)}`,
    });
}
