import m from 'mithril';


export default {
    view({
        attrs: {
            full = true,
        },
        children,
    }) {
        return m('div', {
            'class': `Overlay Overlay--${((full) ? 'full' : 'relative')}`,
        }, children);
    },
};
