// Declared seperately to limit JIT compilation of expressions.
export const patterns = {
    allSpace: /^\s+$/,
    contiguousSpace: /\s+/gmi,
};

// WYSIWYG
export const upper = (s) => s.toUpperCase();
export const lower = (s) => s.toLowerCase();
export const capitalizeWord = (s) => upper(s[0]) + lower(s.substring(1));
export const alphabetize = (list) => list.sort((a, b) => a.localeCompare(b));
export const stripAllWhitespace = (s) => s.replace(patterns.contiguousSpace, '');
export const splitByWhitespace = (s) => s.trim().split(patterns.contiguousSpace);

/*
 * Truncate string to `len` characters, replacing the last three
 * characters of the truncation with '...'. Shorter strings are
 * left alone, and caps <= 3 return only the ellipsis.
 *
 * ellipsis('Hello, world!', 8) === 'Hello...'
 * ellipsis('Hello!', 8) === 'Hello!'
 * ellipsis('Hello!', 0) === '...'
 */
export const ellipsis = (s, len) => ((s.length > len)
    ? `${(len > 3) ? s.substring(0, len - 3) : ''}...`
    : s);

/*
 * Same as above, except the ellipsis leads the string.
 */
export const revellipsis = (s, len) => ((s.length > len)
    ? `...${(len > 3) ? s.substring(s.length - len + 3) : ''}`
    : s);


/*
 * In this variant the ellipsis starts roughly halfway through the string.
 */
export const midellipsis = (s, len) => {
    if (s.length <= len) {
        return s;
    } else {
        const desiredLengthSansEllipsis = len - 3;
        const prefixLength = Math.ceil(desiredLengthSansEllipsis / 2);
        const suffixLength = Math.floor(desiredLengthSansEllipsis / 2);

        return `${s.substring(0, prefixLength)}...${s.substring(s.length - suffixLength)}`;
    }
};



/*
 * Convert string to spinal case, with caveat that all non-word
 * characters become dashes. Useful for converting readable strings
 * to corresponding symbols in data structures with charset limitations.
 *
 * slugify("The eatin's good o'er yonder!") === 'the-eatin-s-good-o-er-yonder-'
 */
export const slugify = (s, r = '-') => lower(s.replace(/\W/g, r));


/*
 * Convert string to Pascal case, stripping all space and punctuation
 *
 * slugify("The artist formerly known as Prince") === 'TheArtistFormerlyKnownAsPrince'
 */
export const pascalcase = (s) => s
    .split(' ')
    .filter(({length: l}) => l)
    .map((nonEmpty) => capitalizeWord(lower(nonEmpty.replace(/[^a-z\d]/ig, ''))))
    .join('');


/*
 * "Naive" title case capitalizes ALL words separated by at least one whitespace
 * character and assumes the result is separated by at most one blank space.
 *
 * Proper title case would account for filler words like "a" or "of", while
 * preserving whitespace characters.
 */
export const naiveTitleCase =
    (s) => s
        .split(/\s+/g)
        .map((t) => capitalizeWord(t)).join(' ');



/*
 * Tagged template literal that will not preserve newlines, indentation,
 * trailing whitespace, leading whitespace, or contiguous whitespace.
 * Contiguous whitespace is replaced with single spaces, a la HTML rules.
 *
 * flatStr`
 *    I am doomed to collapse!
 *        All is one line!
 * ` === 'I am doomed to collapse! All is one line!'
 */
export function flatStr(strings, ...vals) {
    return strings.reduce((p, c, i) => {
        return `${p}${c.replace(patterns.contiguousSpace, ' ')}${vals[i] || ''}`;
    }, '').trim();
}


/*
 * String format function that allows positional and keyword substitution.
 *
 * Automatic and manual positioning are allowed, but not both at the same time.
 * Digits in {} refer to the zero-based index of elements of iterables passed
 * as the second argument.
 *
 *     format('{}{}{}', ['A','B','C']) === 'ABC'
 *     format('{1}{0}{2}', ['A','B','C']) === 'BAC'
 *     format('{}{0}{2}', ['A','B','C']) // throws
 *
 * Second argument of format() may be any iterable.
 *
 *     format('{1}{0}{2}', ['A','B','C']) === 'BAC'
 *     format('{1}{0}{2}', 'ABC') === 'BAC'
 *     format('{1}{0}{2}', (function*(){ yield 'A'; yield 'B'; yield 'C'; })()) === 'BAC'
 *
 * Keyword replacements are okay.
 *
 *     format('{cool}', [], {cool: 'beans'}) === 'beans'
 *
 * Keywords may mix with positional arguments.
 *
 *     format('{cool}{0}{beans}{1}', [' ', '!'], {cool:'cowabunga', beans:'dude'}) === 'cowabunga dude!'
 *
 * Keyword arguments have precedence over positional arguments.
 *
 *     format('{0}', ['A'], {'0': 'Z'}) === 'Z'
 */
const _selector = (k) => new RegExp(`\\{${k}\\}`, 'g');
export function format(str, iterable, kwargs = {}) {
    const manual = /\{[^\}]+\}/.test(str) || Object.keys(kwargs).length > 1;
    const auto = str.indexOf('{}') > -1;

    if (manual && auto) {
        throw new Error('Cannot mix automatic and manual field selection');
    } else if (manual) {
        const arr = Array.from(iterable);
        const keyed = Object.assign(
            Object.entries(arr).reduce((p, [k, v]) => {
                p[k] = v;

                return p;
            }, {}), kwargs);

        return Object.entries(keyed).reduce(
            (p, [k, v]) => p.replace(_selector(k), v), str);
    } else {
        return Array.from(iterable).reduce(
            (p, v) => p.replace('{}', v), str);
    }
}
