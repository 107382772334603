import { bem, logger, m, router, spa } from '#/browser-framework';
import { errors } from '#/stripe-pos';
import { Named } from '#/browser-framework/comps';

import { Controls, PrimaryButton } from './InterviewControls';


const CheckoutFormField = {
    btx: bem`CheckoutFormField feedback icon input port starboard`,
    view({children, attrs: {icon: iconName, label}}) {
        const {block, feedback, icon, input, port, starboard} = CheckoutFormField.btx;

        return m(`label.${block}`,
            m(input,
                m(`span${port}`,
                    m(`span${icon}`, m(Named.Icon, { name: iconName })), label),
                m(`span.${starboard}`, children[0])),
            m(feedback,
                m(`span${port}`),
                m(`span.${starboard}`, children[1])));
    },
};


export const CheckoutForm = {
    oncreate({
        attrs: {
            viewModel: {
                pos,
            },
        },
    }) {
        if (pos) {
            pos.mountElement('cardNumber');
            pos.mountElement('cardExpiry');
            pos.mountElement('cardCvc');
            pos.mountElement('postalCode');
        }
    },
    view({
        attrs: {
            viewModel: {
                pos,
                interview: {
                    interviewModel: {
                        content,
                    },
                },
            },
        },
    }) {
        if (!pos) {
            return null;
        }

        return m('form#pay.CheckoutForm',
            pos.formError && m('.alertNotice', pos.formError),
            m('.CheckoutForm__heading',
                m('.CheckoutForm__summary', content.requestSummaryText),
                m('.CheckoutForm__amount', pos.balanceDue)),
            m('.CheckoutForm__fields',
                m(CheckoutFormField, {icon: 'user', label: 'Name'},
                    m('input', {
                        id: 'cardHolderName',
                        placeholder: 'Ann E. Body',
                        oninput(e) {
                            pos.cardHolderName = e.target.value;
                        },
                    }),
                    m('#cardHolderName-error', m.trust('&nbsp;'))),
                m(CheckoutFormField, {icon: 'credit-card', label: 'Card'},
                    m('#cardNumber'),
                    m('#cardNumber-error', m.trust('&nbsp;'))),
                m(CheckoutFormField, {icon: 'calendar', label: 'Expiry'},
                    m('#cardExpiry'),
                    m('#cardExpiry-error', m.trust('&nbsp;'))),
                m(CheckoutFormField, {icon: 'lock', label: 'CVC'},
                    m('#cardCvc'),
                    m('#cardCvc-error', m.trust('&nbsp;'))),
                m(CheckoutFormField, {icon: 'home', label: 'Zip'},
                    m('#postalCode'),
                    m('#postalCode-error', m.trust('&nbsp;')))),
            m(Controls,
                m(PrimaryButton, {
                    disabled: !pos.awaitingPaymentRequest,
                    onpress() {
                        pos
                            .pay()
                            .then(() => {
                                router.go('/done');
                            })
                            .catch((e) => {
                                if (e.reason !== errors.ValidationFailed().reason) {
                                    spa.error = e;
                                    logger.error(e);
                                    router.go('/error');
                                }
                            });
                    },
                }, `Pay ${pos.balanceDue}`)));
    },
};
