import { m } from '#/browser-framework';

const POINTER_EVENTS = [
    'pointermove',
    'pointerdown',
    'pointerup',
    'pointerover',
    'pointerout',
    'pointerenter',
    'pointerleave',
    'pointercancel',
];

const NODE_CACHE = new WeakMap();

function hook(vnode) {
    const {dom: node, attrs} = vnode;

    if (!NODE_CACHE.has(node)) {
        NODE_CACHE.set(node, {});
    }

    let hasPE = false;

    if (attrs['data-harness']) {
        node.setAttribute('data-harness', attrs['data-harness']);
    }

    for (const event of POINTER_EVENTS) {
        const listener = attrs[`on${event}`];
        const registered = NODE_CACHE.get(node);

        if (registered[event]) {
            node.removeEventListener(event, registered[event]);
            registered[event] = null;
        }

        registered[event] = (e) => {
            listener(e);
            m.redraw();
        };

        if (listener) {
            hasPE = true;
            node.addEventListener(event, registered[event]);
        }
    }

    // Tell PEP to fire events from this node.
    if (hasPE) {
        const ta = attrs.touchAction || 'auto';
        node.style.touchAction = ta;
        node.setAttribute('touch-action', ta);
    }
}

/*
 * Installs touch/click normalization ("pointer") events on the
 * composition. Requires jQuery PEP.
 */
export default function pointable(comp = 'div') {
    return {
        oncreate: hook,
        onupdate: hook,
        onremove: ({dom}) => {
            NODE_CACHE.delete(dom);
        },
        view({attrs, children}) {
            const shallow = Object.assign({}, attrs);

            for (const pe of POINTER_EVENTS) {
                delete shallow[`on${pe}`];
            }

            return m(comp, shallow, children);
        },
    };
}
