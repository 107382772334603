import { bem, router, m, harness } from '#/browser-framework';

import { Controls, PrimaryButton, SecondaryButton } from '#/ido-lib/views/InterviewControls';
import { IdScanImageCaptureFront } from '#/ido-lib/views/images';
import { PhoneDelegationInput } from '#/ido-lib/views/PhoneDelegationInput';

import { LargeFormatIdCardDuplexForm } from './LargeFormatIdCardDuplexForm';
import { LargeFormatPassportForm } from './LargeFormatPassportForm';
import { CountryDropdown } from './CountryDropdown';
import { ChangeDocumentControl } from './ChangeDocumentControl';


const btx = bem`
LargeFormatIdScanForm
    countries
    delegation
    flip
    flipMessage
    form
    instructions
    methods
    subtitle
    title
`;

const DelegationForm = {
    view({
        attrs: {
            q,
        },
    }) {
        return m(btx.delegation,
            m(`h2.${btx.title}`, 'Let me take a photo with my phone'),
            m(`p.${btx.subtitle}`, `We'll send you a text message with
                    instructions to complete your upload via your mobile phone.`),
            m(IdScanImageCaptureFront),
            m(PhoneDelegationInput, {
                id: 'id-delegation-phone',
                delegationController: q.delegate,
                onDelegationRequest: q.delegateToMobile,
            }));
    },
};

const IdScanControls = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        return m(Controls,
            m(SecondaryButton, {
                onpress() {
                    router.go(q.backRoute || '/overview');
                },
            }, 'Do this later'),
            m(PrimaryButton, harness('submit-large-format-id-scan', {
                onLastQuestion,
                onpress() {
                    q.sendId();
                },
                disabled: !q.isValid(),
            }), (q.metadata.needSelfie)
                ? 'Continue'
                : 'Upload'));
    },
};

// Input form to upload an ID on a device with accomodating screen space.
export const LargeFormatIdScanForm = {
    view({ attrs }) {
        const { q } = attrs;
        const Form = (q.usingPassport())
            ? LargeFormatPassportForm
            : LargeFormatIdCardDuplexForm;

        return m(btx.block,
            m(`p${btx.instructions}`,
                JSON.parse(`"${q.metadata.description}"`)),
            m(btx.methods,
                m(btx.form,
                    m(Form, attrs),
                    (q.countries.length > 0) && m(btx.countries, m(CountryDropdown, attrs)),
                    (q.mayChangeDocumentType) && m(ChangeDocumentControl, attrs)),
                m(DelegationForm, attrs)),
            m(IdScanControls, attrs));
    },
};
