import { m } from '#/browser-framework';
import * as jsonTree from 'json-tree-viewer';
import 'json-tree-viewer/libs/jsonTree/jsonTree.css';

function JsonDump({ value }) {
    if (typeof value === 'object') {
        return m('div.JsonViewerWrapper', {
            oncreate(vnode) {
                const treeInstance = jsonTree.create(value, vnode.dom);
                treeInstance.expand();
            },
        });
    }

    return String(value);
}

export default JsonDump;
