import { bem, m } from '#/browser-framework';
import { AutoComplete } from '#/browser-framework/comps';
import { getAcademicProviders } from '#/ido-lib/idoWebClient';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';


const Form = {
    btx: bem`AcademicProviderQuestion form`,
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            metadata: {
                description,
                title,
            },
            autoCompleteModel,
            isValid,
            encode,
        } = q;

        const {
            btx,
        } = Form;

        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit() {
                q.submit(encode());
            },
            onLastQuestion,
        },
        m(btx.block,
            m('h3', title),
            m('p', description),
            m(btx.form,
                m(AutoComplete, autoCompleteModel))));
    },
};


export default function AcademicProvider() {
    const vowData = (deploy.WEB_PUBLIC_DEVELOPER_MODE)
        ? Promise.resolve(Array.from('ABC').map((ch) => Object.assign({ name: `Provider ${ch}` })))
        : getAcademicProviders();

    const iface = {
        view: Form,
        selection: null,
        providers: [],

        autoCompleteModel: AutoComplete.makeViewModel({
            placeholder: 'Type and select a known institution',
            onselect(match) {
                iface.selection = iface.providers.find((p) => p.name === match.origin);

                return (iface.selection)
                    ? iface.selection.name
                    : match.origin;
            },
            computeMatches(needle) {
                // If we get here, the user must be fussing with the field.
                iface.selection = null;

                const names = iface.providers.map(({ name }) => name);
                const matches = names
                    .filter((name) => name.toLowerCase().indexOf(needle.toLowerCase()) > -1)
                    .slice(0, 10)
                    .map((name) => ({
                        exploded: [{ text: name }],
                        origin: name,
                    }));

                return matches;
            },
        }),

        encode() {
            return {
                [iface.attrType]: {
                    shareWith: iface.shareWith,
                    value: iface.selection,
                },
            };
        },

        isValid() {
            return Boolean(iface.selection);
        },
    };

    return vowData.then((providers) => {
        iface.providers = providers;

        return iface;
    });
}
