import m from 'mithril';

import { events as emitter } from '#/browser-framework';
import { async, events, hooks } from '#/universal-framework';

import { hashRouter } from './hashRouter';
import browserLogger from './browserLogger';
import Blank from '#/browser-framework/comps/Blank';

const PassThru = {
    view({ children }) {
        return children;
    },
};

let _spaSingleton;

function reloadOnModuleChange() {
    // This is antithetical to HMR, but will suffice until we
    // make Mithril integration nicer
    if (module.hot) {
        module.hot.addStatusHandler((status) => {
            if (status === 'ready') {
                window.location.reload();
            }
        });
    }
}

function attachEvents(rootElement) {
    const evts = ['click', 'keydown'];
    evts.forEach((name) => {
        rootElement.addEventListener(name, (e) => {
            emitter.emit(`root-${name}`, e);
        });
    });
}

export function createRootElement($window) {
    let root = $window.document.querySelector('#root');

    if (!root) {
        // This DOM node holds the application view.
        root = document.createElement('div');

        root.setAttribute('id', 'root');
        document.body.appendChild(root);
        attachEvents($window.document);
    }

    return root;
}

// Creates a div that holds SPA content.
function mountDom($window, state) {
    const root = createRootElement($window);

    const vRoot = {
        view() {
            return m(state.layout, state,
                m(state.view, state));
        },
    };

    m.mount(root, vRoot);
}


// Binds logger to Sentry if available.
function prepareLogger() {
    const DSN = deploy.WEB_PUBLIC_SENTRY_DSN;
    const ENV = deploy.WEB_PUBLIC_ENVIRONMENT;
    if (DSN) {
        const config = {
            environment: ENV || 'development',
            release: deploy.version,
        };
        return browserLogger.bindSentry(DSN, config);
    }
}


// Creates SPA singleton
function _spaFactory($window) {
    const iface = {
        $window,
        error: null,
        hooks: hooks(
            // Used to allow plugins to add their own
            // styles, route+event handlers and other
            // means to extend the application.
            'declarations',

            // Denotes that everything is ready for the
            // main function may take control.
            'ready',
        ),
        view: Blank,
        viewModel: {},
        vars: {},
        layout: PassThru,
        events: events.emitter(),
        router: hashRouter($window, () => { }),
    };

    iface.redrawAfter = function redrawAfter(fn) {
        return async.always(fn, () => m.redraw());
    };

    iface.redraw = function redraw() {
        m.redraw();
    };

    iface.setView = iface.redrawAfter((mithrilComponent, {layout = PassThru, vars = {}} = {}) => {
        iface.view = mithrilComponent || Blank;
        iface.vars = vars;

        iface.layout = layout;
    });

    iface.start = function start(userInit) {
        return Promise.all([
            mountDom($window, iface),
            reloadOnModuleChange(),
            prepareLogger(),
        ])
            .then(() => {
                return userInit();
            })
            .catch((e) => {
                iface.error = e;
                browserLogger.error(e);
            });
    };

    // Enable diagnostics via console.
    $window.spa = iface;

    return iface;
}


export function getInstance($window) {
    if (!_spaSingleton) {
        _spaSingleton = _spaFactory($window);
    }

    return _spaSingleton;
}
