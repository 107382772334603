import { m } from '#/browser-framework';
import { Spinner } from '#/browser-framework/comps';

import ErrorIcon from '../images/ErrorIcon.svg';
import SuccessIcon from '../images/SuccessIcon.svg';


export const SuccessScreen = {
    view({children}) {
        return m('.WeddingCake',
            m('.WeddingCake__body.SuccessScreen',
                m('img.WeddingCake__ornament', {src: SuccessIcon}),
                m('.WeddingCake__layers', children)));
    },
};

export const ErrorScreen = {
    view({children}) {
        return m('.WeddingCake.ErrorScreen',
            m('.WeddingCake__body',
                m('img.WeddingCake__ornament', {src: ErrorIcon}),
                m('.WeddingCake__layers', children)));
    },
};


export const PendingScreen = {
    view() {
        return m('.WeddingCake',
            m('.WeddingCake__body.PendingScreen',
                m('.PendingScreen__indicator', m(Spinner)),
                m('div', 'Please wait...')));
    },
};
