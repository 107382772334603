export default function features(windowApi) {
    if (!windowApi) {
        throw new Error('No window api provided to features()');
    }

    const cookies = (() => {
        try {
            // Copied from Mondernizr
            if (windowApi.navigator.cookieEnabled) {
                return true;
            }

            windowApi.document.cookie = '___cookie=1';
            const cookieStored = windowApi.document.cookie.indexOf('___cookie=') !== -1;
            windowApi.document.cookie = '___cookie=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';

            return cookieStored;
        } catch (e) {
            return false;
        }
    })();

    const {navigator} = windowApi;

    const userMedia = Boolean(navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function');

    const isInWebAppIOS = (navigator.standalone === true);
    const isInWebAppAndroid = (windowApi.matchMedia('(display-mode: standalone)').matches);


    // Detect type of device.
    const isAndroid = navigator.userAgent.match(/Android/i);
    const isBlackBerry = navigator.userAgent.match(/BlackBerry/i);
    const isIPad = navigator.userAgent.match(/iPad/i);
    const isIPod = navigator.userAgent.match(/iPod/i);
    const isIPhone = navigator.userAgent.match(/iPhone/i);
    const isOperaMini = navigator.userAgent.match(/Opera Mini/i);
    const isMobileWindows = navigator.userAgent.match(/IEMobile/i);
    const isWebOS = navigator.userAgent.match(/webOS/i);
    const isIOS = Boolean(isIPad || isIPhone || isIPod);
    const isMobileBrowser = Boolean(isAndroid || isBlackBerry || isIOS || isOperaMini || isWebOS || isMobileWindows);

    const isInWebAppBrowser = isInWebAppIOS || isInWebAppAndroid;

    // Local storage support.
    let localStorageSupport;
    try {
        const localStorageTestKey = '___test';
        localStorage.setItem(localStorageTestKey, '');
        localStorage.removeItem(localStorageTestKey);

        localStorageSupport = true;
    } catch (e) {
        localStorageSupport = false;
    }


    return Object.freeze({
        cookies,
        userMedia,
        isMobileBrowser,
        isAndroid,
        isBlackBerry,
        isIOS,
        isIPad,
        localStorage: localStorageSupport,
        isIPhone,
        isIPod,
        isMobileWindows,
        isOperaMini,
        isWebOS,
        isInWebAppIOS,
        isInWebAppAndroid,
        isInWebAppBrowser,
    });
}
