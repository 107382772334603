import {m} from '#/browser-framework';

const N_ARMS = 12;

export default {
    view() {
        const arms = (new Array(N_ARMS)).fill('div').map((e) => {
            return m(e);
        });

        return m('.Spinner',
            m('.Spinner__image', arms));
    },
};
