import { router, logger } from '#/browser-framework';
import { deslugAttrType } from '#/evident-attributes';

import { getIdentityAndRequest } from './idoWebClient';
import { buildInterview } from './pipeline';
import { RprNotFound } from '../ido-facing/controllers/errors';


export function interviewController(spa, errorController) {
    const interviewControllerInstance = {};

    interviewControllerInstance.interviewModel = null;

    interviewControllerInstance.selectQuestion = function selectQuestion(questionType) {
        const q = interviewControllerInstance.attrTypeToQuestion(questionType);

        if (q) {
            spa.setView(q.view, { layout: q.layout });
        } else {
            router.go('/overview');
        }
    };

    interviewControllerInstance.getSelectedQuestionType = function getSelectedQuestionType() {
        const q = interviewControllerInstance.getSelectedQuestion();

        return (q)
            ? q.questionType
            : null;
    };

    interviewControllerInstance.getSelectedQuestion = function getSelectedQuestion() {
        return interviewControllerInstance.attrTypeToQuestion(deslugAttrType(router.procure().args[0]));
    };

    // Loads interview model into memory.
    interviewControllerInstance.loadInterview = function loadInterview() {
        const vowIdentityAndRequest = (deploy.WEB_PUBLIC_DEVELOPER_MODE)
            ? Promise.resolve()
            : getIdentityAndRequest();

        return vowIdentityAndRequest
            .then((identityAndRequest) => {
                return buildInterview(identityAndRequest);
            })
            .catch((err) => {
                if (err.reason && err.reason.startsWith('auth/')) {
                    errorController.showError('unauthorized-ido');
                } else if (err.reason === RprNotFound.reason) {
                    // no rpr info in local storage - showing an error and stop processing
                    errorController.showError('unexpected');
                } else {
                    logger.error(err);
                    errorController.showError('unexpected');
                }
            });
    };

    // Route handler decorator used to guarantee that an interview
    // is ready on navigation.
    //
    // Usage: router.on('Title', '/foo', withInterview(() => {...}))
    interviewControllerInstance.withInterview = function withInterview(next) {
        return (...args) => {
            const ensureLocalInterview = (interviewControllerInstance.interviewModel)
                ? Promise.resolve(interviewControllerInstance.interviewModel)
                : interviewControllerInstance.loadInterview().then((localInterviewModel) => {
                    interviewControllerInstance.interviewModel = localInterviewModel;
                });

            ensureLocalInterview.then(() => {
                next(...args);
            });
        };
    };

    interviewControllerInstance.areAllQuestionsAnswered = function areAllQuestionsAnswered() {
        return interviewControllerInstance.interviewModel
            && interviewControllerInstance.interviewModel.questions
            && interviewControllerInstance.interviewModel.questions.every(({ complete }) => complete);
    };

    interviewControllerInstance.onLastQuestion = function onLastQuestion() {
        return interviewControllerInstance.interviewModel
            && interviewControllerInstance.interviewModel.questions
            && interviewControllerInstance.interviewModel.questions.filter(({ complete }) => !complete).length === 1;
    };

    interviewControllerInstance.areAllRequirementsMet = function areAllRequirementsMet() {
        return (
            interviewControllerInstance.areAllQuestionsAnswered() &&
            !interviewControllerInstance.interviewModel.balance &&
            interviewControllerInstance.interviewModel.consentGiven
        );
    };

    interviewControllerInstance.attrTypeToQuestion = function attrTypeToQuestion(needle) {
        return interviewControllerInstance.interviewModel.questions.find(
            ({ attrType }) => needle === attrType);
    };

    return interviewControllerInstance;
}
