import { events, m } from '#/browser-framework';
import SearchInput from './SearchInput';

const eventKeys = {
    up: 'ArrowUp',
    down: 'ArrowDown',
    enter: 'Enter',
};

const SingleOption = {
    view({
        attrs: {
            option,
            chooseCallback,
        },
    }) {
        return m('a.SearchableDropdown__option', {
            class: option.highlighted ? 'highlighted' : '',
            onclick() {
                chooseCallback(option);
            },
        }, option.title);
    },
};

const OptionsList = {
    highlighted: -1,
    keyDownCallback: () => {},
    chooseCallback: () => {},
    oncreate() {
        this.keyDownCallback = (e) => this.keyDownHandler(e);
        events.on('root-keydown', this.keyDownCallback);
    },
    onremove() {
        events.off('root-keydown', this.keyDownCallback);
    },
    highlightExistingOption(event, targetIndex) {
        if (this.options[targetIndex]) {
            this.highlighted = targetIndex;
        }
        event.preventDefault();
    },
    keyDownHandler(e) {
        if (e.key === eventKeys.down) {
            const target = this.highlighted + 1;
            this.highlightExistingOption(e, target);
        } else if (e.key === eventKeys.up) {
            const targetIndex = this.highlighted - 1;
            this.highlightExistingOption(e, targetIndex);
        } else if (e.key === eventKeys.enter) {
            const selected = this.options[this.highlighted];
            this.highlighted = -1;
            if (selected) {
                this.chooseCallback(selected);
            }
        }
        m.redraw();
    },
    resetHighlightedIfNotExists() {
        if (!this.options[this.highlighted]) {
            this.highlighted = -1;
        }
    },
    view({
        state,
        attrs: {
            options,
            keyword,
            chooseCallback,
            shouldHideOptions,
        },
    }) {
        state.chooseCallback = chooseCallback;
        state.options = shouldHideOptions
            ? []
            : options.filter((option) => {
                return keyword
                    ? option.title.toLowerCase().includes(keyword.toLowerCase())
                    : true;
            });
        state.resetHighlightedIfNotExists();

        return m('.SearchableDropdown__options', state.options
            .map((option, index) => {
                option.highlighted = index === state.highlighted;
                return m(SingleOption, {
                    option,
                    chooseCallback,
                });
            }));
    },
};


const Dropdown = {
    searchText: '',
    valueChange: () => {},
    optionCallback(option) {
        this.searchText = '';
        this.valueChange(option);
    },
    view({
        state,
        attrs: {
            value,
            options,
            valueChange,
            onexit,
            hideOptionsUnlessSearched,
        },
    }) {
        this.valueChange = valueChange;
        const shouldHideOptions = hideOptionsUnlessSearched && !state.searchText;
        return m('.SearchableDropdown__submenu', {
            onclick(e) {
                e.stopPropagation();
            },
        }, m('.SearchableDropdown__selected-opened', m(SingleOption, {
            option: value,
            chooseCallback: (option) => this.optionCallback(option),
        }), m('i.fa.fa-sort-down')),
        m(SearchInput, {
            onexit: () => onexit(),
            oninput: (text) => {
                state.searchText = text;
            },
            focusOnCreate: true,
        }),
        m(OptionsList, {
            options,
            keyword: state.searchText,
            shouldHideOptions,
            chooseCallback: (option) => this.optionCallback(option),
        }));
    },
};

const SearchableDropdown = {
    isOpened: false,
    selected: [],
    unselected: [],
    oncreate() {
        this.rootClickSubscription = () => {
            this.isOpened = false;
            m.redraw();
        };
        events.on('root-click', this.rootClickSubscription);
    },
    onremove() {
        events.off('root-click', this.rootClickSubscription);
    },
    toggle(e) {
        this.isOpened = !this.isOpened;
        if (e) {
            e.stopPropagation();
        }
    },
    keyDownHandle(e) {
        const keyNames = Object.values(eventKeys);
        if (keyNames.includes(e.key)) {
            this.toggle(e);
        }
    },
    selectEmptyOption() {
        this.selected = { value: undefined, title: '--' };
    },
    valueChanged(newValue, onSelect) {
        this.isOpened = false;
        const wasSelectionChanged = newValue.value !== this.selected.value;
        if (wasSelectionChanged) {
            this.selected = newValue;
            if (onSelect && typeof onSelect === 'function') {
                onSelect(newValue.value);
            }
        }
        m.redraw();
    },
    /**
     * @callback onSelectCallback
     * @param {string} selectedId
     */

    /**
     * @param {Object} viewParams
     * @param {Object} viewParams.state - state of the component
     * @param {Object} viewParams.attrs - attributes
     * @param {{ value: string, title: string }[]} viewParams.attrs.options
     * @param {boolean} viewParams.attrs.hideOptionsUnlessSearched - options displayed only, when search input is filled
     * @param {string} viewParams.attrs.value
     * @param {onSelectCallback} viewParams.attrs.onSelect
     */
    view({
        state,
        attrs: {
            options,
            value,
            onSelect,
            hideOptionsUnlessSearched,
        },
    }) {
        if (value) {
            const selectedOption = options.find((option) => option.value === value);
            state.selected = selectedOption ? selectedOption : state.selectEmptyOption();
        } else if (!state.selected) {
            state.selectEmptyOption();
        }
        state.unselected = options.filter((option) => option !== state.selected);

        return m('.SearchableDropdown', {
            class: state.isOpened ? 'opened' : '',
        }, m('.SearchableDropdown__selected', {
            tabIndex: 0,
            onclick: (e) => this.toggle(e),
            onkeydown: (e) => this.keyDownHandle(e),
        }, state.selected.title, m('i.fa.fa-sort-down')),
        state.isOpened
            ? m(Dropdown, {
                hideOptionsUnlessSearched,
                options: state.unselected,
                value: state.selected,
                valueChange: (val) => this.valueChanged(val, onSelect),
                onexit: () => this.toggle(),
                onclick: (e) => e.stopPropagation(),
            })
            : null);
    },
};

export default SearchableDropdown;
