import { m } from '#/browser-framework';
import pointable from './decorators/pointable';
import { bem, tx } from '#/browser-framework/taxonomy';

const EntryBem = bem`
    AutoComplete__entries
        match
        particle
`;
const focusedMatchClass = `${EntryBem.match}--focused`;

const AutoCompleteEntries = {
    focus: () => {},
    select: () => {},
    focused: -1,
    onupdate: ({dom, state, attrs}) => {
        // If the number of matches changes, reset scroll position.
        if (state.nMatches !== attrs.matches.length) {
            state.nMatches = attrs.matches.length;
            dom.scrollTop = 0;
        }

        // Scroll with the user when they select items via keyboard.
        const focused = dom.querySelector(focusedMatchClass);

        if (!focused) {
            return;
        }

        const dr = dom.getBoundingClientRect();
        const fr = focused.getBoundingClientRect();

        const bottomOfFocus = fr.y + fr.height;
        const bottomOfEntries = dr.y + dr.height;

        if (bottomOfFocus > bottomOfEntries) {
            // Scroll down until bottom edges of the container and focused element match.
            dom.scrollTop += bottomOfFocus - bottomOfEntries;
        } else if (fr.y < dr.y) {
            // Scroll up until top edges of the container and focused element match.
            dom.scrollTop -= dr.y - fr.y;
        }
    },
    getMatch(match) {
        return match.exploded.map(({text, isMatch}) => {
            const modifier = isMatch ? 'bold' : 'normal';
            return m(`span${EntryBem.particle}--${modifier}`, text);
        });
    },
    getAvailableOptions(matches) {
        return matches.map((match, i) => {
            return m(pointable(tx({
                [EntryBem.match]: true,
                [focusedMatchClass]: this.focused === i,
            })), {
                key: i,
                onpointerenter: () => this.focus(i),
                onmouseup: () => this.select(i),
            }, this.getMatch(match));
        });
    },
    view({
        attrs: {
            id,
            matches,
            focused,
            select,
            focus,
        }}) {
        this.focused = focused;
        this.select = select;
        this.focus = focus;

        return m(EntryBem.block, {
            id: `${id}-entries`,
        }, this.getAvailableOptions(matches));
    },
};

export default AutoCompleteEntries;
