import { m } from '#/browser-framework';


// Rule disabled to make text elements easier to manipulate when not wrapping.
/* eslint-disable max-len */

export default {
    view() {
        return m('svg', {viewBox: '392 305 20 20', width: '100%'},
            m('defs',
                m('path#SVGID_1_[d="M410.6,315.1c0-0.6-0.1-1.3-0.2-1.8H402v3.5h4.8c-0.2,1.1-0.8,2.1-1.8,2.7v2.3h2.9 C409.7,320.2,410.6,317.9,410.6,315.1L410.6,315.1L410.6,315.1z M410.6,315.1"]'),
                m('path#SVGID_3_[d="M402,323.9c2.4,0,4.5-0.8,6-2.2l-2.9-2.3c-0.8,0.5-1.8,0.9-3,0.9c-2.3,0-4.3-1.6-5-3.7h-3v2.3 C395.4,321.9,398.5,323.9,402,323.9L402,323.9L402,323.9z M402,323.9"]'),
                m('path#SVGID_5_[d="M397,316.6c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.6,0.1-1.2,0.3-1.7v-2.3h-3c-0.6,1.2-1,2.6-1,4s0.3,2.8,1,4 L397,316.6L397,316.6L397,316.6z M397,316.6"]'),
                m('path#SVGID_7_[d="M402,309.5c1.3,0,2.5,0.5,3.4,1.3l2.6-2.6c-1.6-1.5-3.6-2.3-6-2.3c-3.5,0-6.6,2-8,5l3,2.3 C397.7,311.1,399.7,309.5,402,309.5L402,309.5L402,309.5z M402,309.5"]')),
            m('clipPath#SVGID_2_',
                m('use', {'xlink:href': '#SVGID_1_', style: 'overflow:visible;'})),
            m('clipPath#SVGID_4_',
                m('use', {'xlink:href': '#SVGID_3_', style: 'overflow:visible;'})),
            m('clipPath#SVGID_6_',
                m('use', {'xlink:href': '#SVGID_5_', style: 'overflow:visible;'})),
            m('clipPath#SVGID_8_',
                m('use', {'xlink:href': '#SVGID_7_', style: 'overflow:visible;'})),
            m('rect', {x: '397', y: '308.3', 'clip-path': 'url(#SVGID_2_)', 'fill': '#3E82F1', width: '18.6', height: '18.5'}),
            m('rect', {x: '389', y: '311.6', 'clip-path': 'url(#SVGID_4_)', 'fill': '#32A753', width: '24', height: '17.3'}),
            m('rect', {x: '388', y: '305.9', 'clip-path': 'url(#SVGID_6_)', 'fill': '#F9BB00', width: '14', height: '18.1'}),
            m('rect', {x: '389', y: '300.9', 'clip-path': 'url(#SVGID_8_)', 'fill': '#E74133', width: '24.1', height: '17.3'}));
    },
};
