let sentry;

/**
 * Return a string or Error object for sentry logging.
 * @param {...string|Error} args
 */
function normalizeVariadicArgs(...args) {
    if (args.length === 1 && args[0] instanceof Error) {
        return args[0];
    }

    return args.map(JSON.stringify).join(' ');
}

/**
 * @param {...string|Error} args
 */
function info(...args) {
    return console.info(...args);
}

/**
 * @param {...string|Error} args
 */
function error(...args) {
    if (sentry) {
        const msg = normalizeVariadicArgs(...args);
        sentry.captureException(msg);
    } else {
        console.error(...args);
    }
}

/**
 * @param {...string|Error} args
 */
function trace(...args) {
    return console.trace(...args);
}

/**
 * @param {...string|Error} args
 */
function debug(...args) {
    return console.debug(...args);
}

/**
 * @param {...string|Error} args
 */
function warn(...args) {
    return console.warn(...args);
}

/**
 * @param {...string|Error} args
 */
function log(...args) {
    return console.log(...args);
}

/**
 * @param {string} email
 */
function bindUserContext(email) {
    if (sentry) {
        sentry.configureScope((scope) => {
            scope.setUser({ email });
        });
    } else {
        warn('Sentry is not present for user context', email);
    }
}

function setSentry(instance) {
    sentry = instance;
}


export default {
    info,
    error,
    trace,
    debug,
    warn,
    log,
    setSentry,
    bindUserContext,
};
