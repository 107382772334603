import { m, harness } from '#/browser-framework';

import { LargeFormatIdSimplexControl } from './LargeFormatIdSimplexControl';


// Input form to upload an ID on a device with accomodating screen space.
export const LargeFormatPassportForm = {
    view({
        attrs: {
            q,
        },
    }) {
        return m('.LargeFormatPassportForm',
            m('h2.LargeFormatPassportForm__title',
                'I already have a photo I can upload'),
            m('p.LargeFormatPassportForm__subtitle',
                `Upload a photo of the identification
                page of your passport against a dark
                surface.`),
            m('.LargeFormatPassportForm__simplex',
                m(LargeFormatIdSimplexControl, harness('large-format-passport-card', {
                    id: 'passport-id-page',
                    dataUrl: q.getScanner().passport.dataUrl,
                    onchange(files) {
                        q.getScanner().stagePassportPage(files && files[0]);
                    },
                }), 'Identification page of passport')));
    },
};
