export default function Action({
    prefix = '',
    names = [],
} = {}) {
    return names.reduce((actionEnum, name) => {
        return Object.defineProperty(actionEnum, name, {
            value: `${prefix}${name}`,
            writable: false,
            enumerable: true,
        });
    }, {});
}
