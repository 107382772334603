import { m, bem } from '#/browser-framework';
import { Named, Spinner } from '#/browser-framework/comps';


export const MobileSimplexPreview = {
    tx: bem`MobileSimplexPreview remove`,
    view({
        attrs: {
            ondelete,
            dataUrl,
        },
    }) {
        const { block, remove } = MobileSimplexPreview.tx;

        const pressToDelete = m(remove, { onclick: ondelete },
            m(Named.Icon, { name: 'delete' }));

        return (dataUrl)
            ? m(block, { style: `background-image: url(${dataUrl})` }, pressToDelete)
            : (dataUrl === undefined)
                ? m(block, m(Spinner), pressToDelete)
                : null;
    },
};
