import { m, harness } from '#/browser-framework';

import { PhoneNumberInput, Button } from '#/browser-framework/comps';


export const PhoneDelegationInput = {
    view({
        attrs: {
            id,
            delegationController,
            onDelegationRequest,
            buttonLabel = 'Start',
        },
    }) {
        return m('.PhoneDelegationInput',
            m(PhoneNumberInput, harness('phone-delegation-input', {
                id,
                value: delegationController.phoneNumber,
                onchange(e) {
                    delegationController.changePhoneNumber(e);
                },
            })),
            m(Button, harness('phone-delegation-submit', {
                flags: {
                    primary: true,
                },
                disabled: !delegationController.maySendText(),
                onpress: onDelegationRequest,
            }), (delegationController.textsRemaining <= 0)
                ? 'Sent!'
                : (delegationController.sendingText)
                    ? 'Sending...'
                    : buttonLabel));
    },
};
