import { contract, signature } from '#/universal-framework/functions';

// Return new component that renders only as a function of attrs and children.
export const purecomp = (f) => ({ view: ({ attrs, children }) => f(attrs, children) });

// Return pure component with contract for vnode function. Same as React.propTypes
export const contractcomp = (displayName, spec, f) =>
    purecomp(contract(f, signature(({ shape, any }) => ({
        domain: [shape(spec), any],
        range: any,
        displayName,
    }))));
