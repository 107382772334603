import { m, router, spa, support } from '#/browser-framework';

import { MobileSimplexDropbox } from '#/ido-lib/views/MobileSimplexDropbox';
import { Controls, PrimaryButton, SecondaryButton } from '#/ido-lib/views/InterviewControls';
import { IdScanImageCaptureFront, IdScanImageCaptureBack } from '#/ido-lib/views/images';


const MobileWizardStep = {
    view({
        attrs: {
            maySubmit,
            oncontinue,
        },
        children,
    }) {
        return m('.MobileWizardStep',
            children,
            m(Controls,
                m(SecondaryButton, {
                    onpress() {
                        router.go('/overview');
                    },
                }, 'Do this later'),
                m(PrimaryButton, {
                    disabled: !maySubmit,
                    onpress: oncontinue,
                }, 'Continue')));
    },
};

const MediaInstructions = {
    view({
        children,
    }) {
        return (support.userMedia)
            ? m('p.IdScanInstructions', children)
            : m('.alertNotice',
                m('strong', 'We cannot access your camera.'),
                m.trust('<br>'),
                'In Safari, click the button at the bottom right, or try a different browser.');
    },
};

const IdCardFormLayout = {
    view({
        attrs: {
            q,
            isFront,
            oncontinue,
        },
    }) {
        const scanner = q.getScanner();

        const capitalized = (isFront) ? 'Front' : 'Back';
        const lcase = capitalized.toLowerCase();

        const visualAid = (isFront) ? IdScanImageCaptureFront : IdScanImageCaptureBack;
        const simplex = (isFront) ? scanner.front : scanner.back;
        const scanFn = spa.redrawAfter((files) => {
            scanner.setSimplex(files && files[0], lcase);
        });

        const docType = (q.idType === 'id_card')
            ? 'ID card'
            : 'driver license';

        return m(MobileWizardStep, {
            maySubmit: simplex.dataUrl && simplex.errors.length === 0,
            oncontinue,
        },
        m(visualAid),
        m('h2.MobileWizardStep__heading', `${capitalized} of ${docType}`),
        m(MediaInstructions,
            `Take a photo of the ${lcase} side of your ${docType} on a dark surface.
            The details on should be clearly visible in the photo.`),
        m(MobileSimplexDropbox, {
            id: `id-simplex-${lcase}`,
            accept: 'image/*',
            enablePreview: true,
            dataUrl: simplex.dataUrl,
            onchange(files) {
                scanFn(files);
            },
        }, `Upload ${lcase} side of ${docType}`),
        (simplex.errors.length > 0) && m('p.fg-danger', simplex.errors.join(' ')));
    },
};


export const MobileSimplexFormBack = {
    view({ attrs: { q } }) {
        return m(IdCardFormLayout, {
            q,
            isFront: false,
            oncontinue() {
                q.sendId();
            },
        });
    },
};

export const MobileSimplexFormFront = {
    view({ attrs: { q } }) {
        return m(IdCardFormLayout, {
            q,
            isFront: true,
            oncontinue() {
                q.workflowView = MobileSimplexFormBack;
                spa.redraw();
            },
        });
    },
};


export const MobileSimplexFormPassportIdPage = {
    view({
        attrs: {
            q,
        },
    }) {
        const scanner = q.getScanner();
        const simplex = scanner.passport;

        return m(MobileWizardStep, {
            maySubmit: simplex.dataUrl && simplex.errors.length === 0,
            oncontinue() {
                q.sendId();
            },
        },
        m(IdScanImageCaptureFront),
        m('h2.MobileWizardStep__heading',
            'Upload the identification page of your passport'),
        m(MediaInstructions,
            `Please take a photo of the identification page
            of your passport against a dark surface`),
        m(MobileSimplexDropbox, {
            id: 'id-simplex-passport',
            accept: 'image/*',
            enablePreview: true,
            dataUrl: simplex.dataUrl,
            onchange(files) {
                scanner.stagePassportPage(files && files[0]);
            },
        }, 'Upload ID page of passport'),
        (simplex.errors.length > 0) && m('p.fg-danger', simplex.errors.join(' ')));
    },
};
