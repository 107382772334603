import { m, bem } from '#/browser-framework';

function executeIfCallback(fn, ...args) {
    if (fn && typeof fn === 'function') {
        fn(...args);
    }
}

const searchBem = bem`
    SearchInput
        input
        icon
`;

const SearchInput = {
    searchText: '',
    /**
     * @param {Object} viewParams
     * @param {string} viewParams.attrs.value - initial component value
     */
    oncreate({ attrs: {
        value,
    }}) {
        this.searchText = value || '';
    },
    /**
     * @callback onInputCallback
     * @param {string} searchText
     */

    /**
     * @param {Object} viewParams
     * @param {Object} viewParams.attrs - attributes
     * @param {string} viewParams.attrs.id - id of input
     * @param {onInputCallback} viewParams.attrs.oninput
     * @param {function} viewParams.attrs.onexit - callback executed after esc button is pressed on empty input
     * @param {string} viewParams.attrs.placeholder - placeholder for search input
     * @param {boolean} viewParams.attrs.focusOnCreate - should focus on input, after creation?
     */
    view({
        attrs: {
            id,
            oninput,
            onexit,
            placeholder,
            focusOnCreate,
        },
    }) {
        const inputAction = () => {
            executeIfCallback(oninput, this.searchText);
            m.redraw();
        };

        return m(searchBem.block,
            m(`input.${searchBem.input}`, {
                oncreate(el) {
                    if (focusOnCreate) {
                        el.dom.focus();
                    }
                },
                id: id ? id : '',
                value: this.searchText,
                placeholder: placeholder ? placeholder : 'Search...',
                autofocus: Boolean(focusOnCreate),
                oninput: (e) => {
                    this.searchText = e.target.value;
                    inputAction();
                },
                onkeydown: (e) => {
                    const evt = e || window.event;
                    const escCharCode = 27;
                    if (evt.keyCode === escCharCode) {
                        if (this.searchText) {
                            this.searchText = '';
                            inputAction();
                        } else {
                            executeIfCallback(onexit);
                        }
                    }
                },
            }),
            m(`i.fa.${searchBem.icon}`, {
                class: this.searchText ? 'fa-times' : 'fa-search',
                onclick: () => {
                    if (this.searchText) {
                        this.searchText = '';
                        inputAction();
                    }
                },
            })
        );
    },
};

export default SearchInput;
