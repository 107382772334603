// Hooks enable plugins by allowing extension to behavior within structured execution flow.
export function hooks(...scopes) {
    const _entries = scopes.reduce((p, name) => {
        p[name] = [];

        return p;
    }, {});

    return {
        on(name, ...fns) {
            _entries[name].push(...fns);
        },
        off(name, ...fn) {
            _entries[name] = _entries[name].reduce((p, f) => p.concat(fn.includes(f) ? [] : [f]), []);
        },
        serial(name, ...args) {
            return _entries[name].reduce((p, fn) => p.then(() => fn(...args)), Promise.resolve());
        },
        concurrent(name, ...args) {
            return Promise.all(_entries[name].map((fn) => Promise.resolve(fn(...args))));
        },
    };
}
