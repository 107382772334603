import Action from '#/store/action';

const key = 'AttributeModule';
const Actions = Action({ prefix: key,
    names: [
        'UPDATE_INPUT_ATTRIBUTE_MODEL',
        'UPDATE_SCHEMA',
        'UPDATE_ROOT_MODEL',
        'UPDATE_SUBMODEL',
    ]});

const Module = {
    key,
    state: {
        /** @type {Object} json schema object model */
        schema: null,
        /** @type InputAttributeModel */
        attributeModel: null,
        rootModel: null,
    },
    actionHandlers: {
        [Actions.UPDATE_INPUT_ATTRIBUTE_MODEL]: ({moduleState, payload}) => {
            moduleState.attributeModel = payload;
        },
        [Actions.UPDATE_SCHEMA]: ({moduleState, payload}) => {
            moduleState.schema = payload;
        },
        [Actions.UPDATE_ROOT_MODEL]: ({moduleState, payload}) => {
            moduleState.rootModel = payload;
        },
        [Actions.UPDATE_SUBMODEL]: ({moduleState, payload}) => {
            moduleState[payload.id] = payload;
        },
    },
};

export default {
    Module,
    Actions,
};
