
import { m, harness } from '#/browser-framework';

import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            metadata: {
                title,
            },
            value,
        } = q;

        return m(DefaultQuestionForm, {
            maySubmit: value.length > 0,
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(LineInputBlock,
            title,
            m('input', harness('dedicated-single-line-input', {
                value,
                oninput(e) {
                    q.value = e.target.value;
                },
            }))));
    },
};


export default function SingleLineText({attrType, shareWith}) {
    const _state = {
        value: '',
        view: Form,
        encode() {
            return {
                [attrType]: {
                    shareWith,
                    value: _state.value,
                },
            };
        },
    };

    return _state;
}
