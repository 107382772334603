function normalizeMethod(method) {
    return (method || 'GET').toUpperCase();
}

function consideredIdempotent(method) {
    const toCheck = normalizeMethod(method);

    return toCheck === 'GET' || toCheck === 'TRACE' || toCheck === 'OPTIONS';
}

function _xhrImpl({
    method = 'GET',
    XMLHttpRequest = window.XMLHttpRequest,
    url,
    user,
    password,
    data,
    config = () => {},
} = {}) {
    const applicableMethod = normalizeMethod(method);
    const xhrObj = new XMLHttpRequest();

    xhrObj.open(
        applicableMethod,
        url,
        true,
        typeof user === 'string' ? user : undefined,
        typeof password === 'string' ? password : undefined
    );

    if (typeof config === 'function') {
        config(xhrObj);
    }

    xhrObj.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    const vowResponse = new Promise((resolve, reject) => {
        xhrObj.onreadystatechange = () => {
            if (xhrObj.readyState === 4) {
                if (xhrObj.status >= 200 && xhrObj.status < 300 || xhrObj.status === 304) {
                    resolve(xhrObj);
                } else {
                    // Rejection includes abort.
                    const xhrError = new Error();
                    xhrError.xhrObj = xhrObj;

                    reject(xhrError);
                }
            }
        };

        xhrObj.send((consideredIdempotent(applicableMethod))
            ? undefined
            : data);
    });

    vowResponse.abort = () => xhrObj.abort();

    return vowResponse;
}

function xhr(o = {}) {
    try {
        return xhr._driver(o);
    } catch (e) {
        return Promise.reject(e);
    }
}

// The driver function is hot-swappable for mocking purposes.
xhr._driver = _xhrImpl;

// Use to create another client with more specialized options.
// Usage: xhr.extend(xhr.extend(xhr, () => {...}), () => {...})
xhr.extend = (baseXhr, override) => {
    return (o = {}) => {
        const overridden = Object.assign({}, o, override(o));

        const oldConfig = (o.config || (() => null));
        const newConfig = (overridden.config || (() => null));

        overridden.config = (xo) => {
            oldConfig(xo);
            newConfig(xo);
        };

        return baseXhr(overridden);
    };
};



/*
 * Decorate XHR function such that all request URIs are prefixed
 * with a prescribed string.
 *
 * Example:
 *
 * xhr({url: '/bar'}) // GET /bar
 *
 * const withPrefix = xhr.decorateUrlPrefix(xhr, '/foo');
 *
 * withPrefix({url: '/bar'}); // GET /foo/bar
 */
xhr.decorateUrlPrefix = (baseXhr, url) => {
    return (o = {}) => {
        o.url = url + o.url;

        return baseXhr(o);
    };
};


/*
 * Decorate XHR function such that all keyword arguments are
 * transformed by an override() function.
 */
xhr.decorateOptions = (baseXhr, override) => {
    return (o = {}) => {
        return baseXhr(override(o));
    };
};


/*
 * Decorate XHR function such that all requests include the same headers
 * (overriding any conflicting headers).
 */
xhr.setHeaders = (xo, headers) => {
    for (const [header, val] of Object.entries(headers)) {
        xo.setRequestHeader(header, val);
    }
};

xhr.decorateComms = (baseXhr, {
    outgoing = (v) => v.data,
    incoming = (v) => v,
    incomingError = (v) => v,
} = {}) => {
    return (o = {}) => {
        try {
            const data = outgoing(o);

            return baseXhr(Object.assign({}, o, {
                data,
            })).then((xo) => {
                return incoming(xo);
            }).catch((xhrError) => {
                throw incomingError(xhrError);
            });
        } catch (e) {
            return Promise.reject(e);
        }
    };
};

const _cache = {};
xhr.decorateCachePolicy = (baseXhr, predicate = () => true) => {
    return (o = {}) => {
        if (consideredIdempotent(o.method) && predicate(o) && o.url in _cache) {
            return Promise.resolve(_cache[o.url]);
        } else {
            return baseXhr(o).then((resp) => {
                _cache[o.url] = resp;

                return resp;
            });
        }
    };
};

// Client supporting full JSON I/O.
xhr.decorateJsonComms = (baseXhr) => {
    // These functions may throw errors freely.
    // The Error objects will be caught by decorateComms.
    const outgoing = (o) => {
        return JSON.stringify(o.data);
    };

    const incoming = (xo) => {
        return JSON.parse(xo.responseText);
    };

    const incomingError = (xhrError) => {
        const respText = xhrError.xhrObj.responseText || '';

        try {
            const message = JSON.parse(respText);
            const err = new Error(respText);

            err.jsonResponse = message;
            err.xhrObj = xhrError.xhrObj;

            return err;
        } catch (e) {
            e.xhrObj = xhrError.xhrObj;

            return e;
        }
    };

    const withComms = xhr.decorateComms(baseXhr, {
        outgoing,
        incoming,
        incomingError,
    });

    const withHeaders = xhr.decorateOptions(withComms, (o) => {
        const old = o.config || (() => {});

        o.config = (xo) => {
            xo.setRequestHeader('Accept', 'application/json, text/*');

            if (!consideredIdempotent(o.method)) {
                xo.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
            }

            old(xo);
        };

        return o;
    });

    return withHeaders;
};

xhr.json = xhr.decorateJsonComms(xhr);

export default xhr;
