import { m, harness } from '#/browser-framework';
import { pointable, Named } from '#/browser-framework/comps';

import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';


const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const { metadata: { description }, showHiddenCharacters, isValid, value } = q;

        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m('.SsnQuestion',
            m(LineInputBlock,
                description,
                m('label.SsnQuestion__input', {for: 'ssn'},
                    m('input', harness('dedicated-ssn-input', {
                        id: 'ssn',
                        type: (showHiddenCharacters)
                            ? 'text'
                            : 'password',
                        value,
                        oninput(e) {
                            q.value = e.target.value;
                        },
                    })),
                    m(pointable(Named.Icon), {
                        name: (showHiddenCharacters)
                            ? 'no-eye'
                            : 'eye',
                        onpointerup: () => {
                            q.showHiddenCharacters = !q.showHiddenCharacters;
                        },
                    })))));
    },
};


export default function Ssn() {
    const _state = {
        value: '',
        view: Form,
        showHiddenCharacters: false,
        encode() {
            return {
                [_state.attrType]: {
                    value: _state.value.replace(/\D/g, ''),
                    shareWith: _state.shareWith,
                },
            };
        },
        isValid() {
            return (
                _state.value.match(/^\d{9}$/) ||
                _state.value.match(/^\d{3}-\d{2}-\d{4}$/) ||
                _state.value.match(/^\d{3}\s+\d{2}\s+\d{4}$/)
            );
        },
    };

    return _state;
}
