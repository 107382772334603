import { m, router, harness, bem, tx } from '#/browser-framework';
import { Named, pointable } from '#/browser-framework/comps';

import Check from '../images/Check.svg';


const btx = bem`InterviewStepTile icon text title description status anchor`;


export const InterviewStepTile = {
    view({
        attrs: {
            question,
        },
    }) {
        const {
            attrType,
            metadata: {
                title,
                icon,
                description,
                required,
            },
            complete,
            hasError,
        } = question;

        const classified = tx(btx.block, {
            error: hasError,
            complete,
        });


        const navigationAttrs = {
            ontouchend(e) {
                e.preventDefault();
            },
            onpointerup(e) {
                e.preventDefault();

                if (!complete) {
                    router.go(`/question/${attrType.replace(/\./g, '-')}`);
                }
            },
        };

        const applied = (complete)
            ? navigationAttrs
            : harness('interview-incomplete-step', navigationAttrs);

        return m(pointable(classified), applied,
            m(btx.icon, m(Named.Icon, {
                name: icon,
            })),
            m(btx.text,
                m(btx.title, title,
                    (required) && m('span.fg-danger', m.trust('&nbsp;*'))),
                m(`span${btx.description}`, m.trust(`${description}`)),
            ),
            m(btx.status,
                (complete) && m('img', { src: Check })));
    },
};
