import { poll } from '#/universal-framework/async';
import { spa, router, xhr } from '#/browser-framework';
import { exception } from '../universal-framework/errors';
import { RprNotFound } from '../ido-facing/controllers/errors';

const _noSession = () => Promise.reject(new Error('Session not established.'));

// Will change to a client if this module is properly set up.
let _makeRequest = _noSession;
let _$window;
const _makeGuestRequest = xhr.extend(xhr.json, (o) => ({
    url: `${deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX}${o.url}`,
}));

// Used to access session data in local storage
const SESSION_STORAGE_KEY = '__idoweb-session';

export function getSessionData() {
    try {
        return JSON.parse(_$window.localStorage.getItem(SESSION_STORAGE_KEY));
    } catch (e) {
        return null;
    }
}

export function setSessionData(session) {
    const { kwargs } = router.procure();

    // Capture canary override.
    if (kwargs.canary) {
        session.canary = kwargs.canary;
    }

    spa.$window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session));
}

export function exchangeSingleUseToken(singleUseToken) {
    return xhr.json({
        method: 'POST',
        url: `${deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX}/auth`,
        config(xo) {
            xo.withCredentials = true;
            xo.setRequestHeader('Authorization', `Bearer ${singleUseToken}`);
        },
    }).then(({ canaryToken }) => canaryToken);
}


// This tells us what forms to generate.
export function getVerificationRequestDetails(requestId) {
    return _makeRequest({
        url: `/requests/${requestId}`,
    });
}

// This tells us about the customer interested in the submission, such as their branding info.
function getIdentity(rpId) {
    return _makeRequest({
        url: `/identity/distinguishedNames?dn=${encodeURIComponent(rpId)}&includeConsentText=true`,
    });
}

/**
 * Fetches verification request details and the rp identity
 * which are needed to make an interview.
 * @typedef {Object} IdentityAndRequest
 * @property {Object} verificationRequest - ido web "verification request" payload
 * @property {Object} identity - rp identity payload
 * @returns {IdentityAndRequest}
 */
export async function getIdentityAndRequest() {
    const sessionData = getSessionData();
    const rprId = sessionData && sessionData.rprId
        ? sessionData.rprId
        : '';
    if (!rprId) {
        throw exception(RprNotFound);
    }

    const verificationRequest = await getVerificationRequestDetails(rprId);
    const identity = await getIdentity(verificationRequest.relyingparty_id);
    return {
        verificationRequest,
        identity,
    };
}

// Returns a list of utility providers against which the IDO may authenticate.
export function getUtilityProviders() {
    return _makeRequest({
        method: 'GET',
        url: '/providers/utility',
    }).then(({ providers }) => providers);
}

export function getAttributeData() {
    return _makeRequest({
        method: 'GET',
        url: '/attributeData',
    }).then((result) => result);
}

export function getAcademicProviders() {
    return _makeRequest({
        method: 'GET',
        url: '/providers/academic',
    }).then(({ providers }) => providers);
}

// Waits for an attribute fulfilment to finish.
export function pollFulfilment(id) {
    return new Promise((resolve, reject) => {
        const p = poll({
            peri() {
                return _makeRequest({
                    method: 'GET',
                    url: `/fulfilment/${id}`,
                })
                    .then(({ status }) => status)
                    .catch(reject);
            },
            listener(value) {
                if (value === 'complete') {
                    p.stop();
                    resolve();
                }
            },
        });

        p.start();
    });
}

// Send another authenticated session to a delegate device that we assume
// belongs to a valid custodian of the same info as the current user.
export function notifyDelegate({
    continueUrl = null,
    recipient,
    method,
    authToken,
}) {
    return _makeRequest({
        method: 'POST',
        url: '/notifications',
        data: {
            notificationMethod: method,
            continueUrl,
            recipient,
            authToken,
        },
    });
}

// Sends a Stripe token upstream to carry out a transaction.
export function submitPayment(token) {
    return _makeRequest({
        method: 'POST',
        url: '/payments',
        data: {
            paymentToken: token.id,
        },
    });
}

// Used to mark an IDO's acceptance in attribute sharing agreements
export function sendConsent(rprId) {
    return _makeRequest({
        method: 'PUT',
        url: `/requests/${rprId}/consent`,
        data: {
            reply: 'accepted',
        },
    });
}

export function getTransitionState(rprId) {
    return _makeRequest({
        method: 'GET',
        url: `/requests/${rprId}/requestTransition`,
    });
}

// Sends attribute values for verification
export function submitWebAttributes(data) {
    return _makeRequest({
        method: 'POST',
        url: '/attributeData',
        data,
    });
}

// Grabs countries for a dropdown. A falsey region will yield all countries.
export function getGoogleRedirectUri() {
    return _makeRequest({
        method: 'GET',
        url: '/authgoogle',
    });
}

// Grabs countries for a dropdown. A falsey region will yield all countries.
export function getCountriesInRegion(region) {
    return _makeRequest({
        method: 'GET',
        url: (region)
            ? `/countryMappings/${region}`
            : '/countryMappings',
    }).then(({countries}) => {
        // [{ '<CODE>': '<NAME>' }, ...] --becomes--> [['<CODE>', '<NAME>'], ...],
        // with blank names removed.
        return countries
            .map((o) => Object.entries(o).pop())
            .filter(([, name]) => Boolean(name))
            .sort((a, b) => a[1].localeCompare(b[1]));
    });
}

export function setSessionSource($window) {
    _$window = $window;
}

// Binds a session to a client for AJAX.
export function setUp(session) {
    setSessionData(session);

    _makeRequest = xhr.extend(xhr.json, (o) => ({
        url: `${deploy.WEB_PUBLIC_IDOWEB_SERVICE_URL_PREFIX}${o.url}`,
        config(xo) {
            xo.withCredentials = true;
            xo.setRequestHeader('X-CSRFToken', session.canary);
        },
    }));
}

/**
 * Undo the side effects from calling setUp.
 * TODO: Determine if this can be called from tearDown.
 */
export function undoSetUp(session) {
    session.canary = undefined;

    if (_$window) {
        _$window.localStorage.removeItem(SESSION_STORAGE_KEY);
    }

    _makeRequest = _noSession;
}

// Destroy service binding.
export function tearDown() {
    if (_$window) {
        _$window.localStorage.removeItem(SESSION_STORAGE_KEY);
    }

    _makeRequest = _noSession;

    // This request unsets the session cookie.
    // Set withCredentials to true so the browser accepts the change.
    return _makeGuestRequest({
        method: 'DELETE',
        url: '/auth',
        config(xo) {
            xo.withCredentials = true;
        },
    });
}


export function getIdoProfile() {
    return _makeRequest({
        method: 'GET',
        url: '/userInfo',
    }).then(({auth_domain: ad, email}) => {
        return {
            email,
            authDomain: ad.domain,
        };
    });
}
