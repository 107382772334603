import m from 'mithril';
import { harness } from './taxonomy';
import { errors, validation, async } from '#/universal-framework';


const { RequiredValue } = validation;

let _autoinc = 0;


/*
State for the smallest user input field without visual considerations.

Validation is asyncronous and bound to normalized web error space.
*/
export function field({
    id,
    validate = () => Promise.resolve(),
    initialValue = '',
} = {}) {
    const _private = {
        id: id || `anon-${String(++_autoinc)}`,
        promise: Promise.resolve(),
        error: null,
        inputlock: null,
        value: initialValue,
    };

    const safeValidate = async.wrapErrors(validate);


    function check() {
        if (_private.inputlock) {
            _private.inputlock.hold(_private.id);
        }

        return safeValidate(_private.value, _private.inputlock)
            .then(() => {
                _private.error = null;

                if (_private.inputlock) {
                    _private.inputlock.release(_private.id);
                }
            }).catch((e) => {
                _private.error = e;
            })
            .then(() => {
                m.redraw();
            });
    }

    const exported = {
        showError: false,
        bindLock(lock) {
            _private.inputlock = lock;

            return exported.refresh();
        },

        refresh() {
            _private.promise = check();

            return _private.promise;
        },

        get promise() {
            return _private.promise;
        },

        get error() {
            return _private.error;
        },

        get errorText() {
            return (_private.error && exported.showError)
                ? _private.error.message
                : '';
        },

        get id() {
            return _private.id;
        },

        set value(v) {
            _private.value = v;
            exported.refresh();
        },

        get value() {
            return _private.value;
        },
    };

    return exported;
}


/*
A inputlock is a syncronization object used for complex form validation.

Submission is blocked by holds. A "hold" is an objection from outside
code that vetos submission attempts. Submission is only allowed if there
are no holds.

Fields passed to this function are bound to the lock, such that all
failed validations add holds.

Non-fields may also add holds to impose external stipulations.

Usage:

    inputlock({
        id: 'my-lock',
        fields: {
            myFieldA: field(...),
            myFieldB: field(...),
            myFieldC: field(...),
        },
        async fire(inputlockInstance) {
            // Try to submit data...
        },
    })

*/
export function inputlock({ id, fields, fire }) {
    const holds = new Set();

    const state = {
        id,
        fields,
        error: null,
        get maySubmit() {
            return holds.size === 0;
        },
        load(dump) {
            for (const [k, v] of Object.entries(dump || {})) {
                fields[k].value = v;
            }
        },
        dump() {
            return Object.keys(fields).reduce((p, c) => {
                p[c] = fields[c].value;

                return p;
            }, {});
        },
        hold(name) {
            holds.add(name);
            m.redraw();
        },
        release(name) {
            holds.delete(name);
            m.redraw();
        },
        synchronize() {
            state.hold(id);

            const promises = Object.keys(fields).map((k) => {
                return fields[k].bindLock(state);
            });

            return Promise.all(promises).then(() => {
                state.release(id);
            });
        },
        submit(scenario) {
            return state
                .synchronize()
                .then(() => {
                    if (!state.maySubmit) {
                        const holdArray = Array.from(holds);

                        throw errors.exception({
                            reason: 'inputlock/has-holds',
                            message: `inputlock engaged with holds: ${holdArray.join(', ')}`,
                            extra: {
                                holds: holdArray,
                            },
                        });
                    }

                    state.error = null;

                    for (const toSilence of Object.values(fields)) {
                        toSilence.showError = false;
                    }

                    state.hold(id);
                    m.redraw();

                    return new Promise((resolve, reject) => {
                        try {
                            resolve(fire(state, scenario));
                        } catch (e) {
                            reject(e);
                        }
                    });
                })
                .catch((e) => {
                    state.error = e;
                })
                .then(() => {
                    state.release(id);
                });
        },
    };

    // Lock submission to discover initial validation state
    // as fields bind to the lock.
    return state.synchronize().then(() => state);
}


export function proxy(lock) {
    const f = field({
        id: `${lock.id}-proxy`,
        initialValue: lock,
        validate(v) {
            return v.maySubmit;
        },
    });

    return new Proxy(f, {
        get(target, k) {
            if (k === 'fields') {
                return target.value.fields;
            } else if (k in target.value.fields) {
                return target.value.fields[k];
            }

            return target[k];
        },
    });
}

export function enterToSubmit(lock) {
    return function keyUpDecorator(attrs = {}) {
        const {onkeyup = () => {}} = attrs;

        return Object.assign({}, attrs, {
            onkeyup(e) {
                if (e.which === 13) {
                    lock.submit();
                }

                onkeyup(e);
            },
        });
    };
}

export function textFieldBehavior(fieldinst, base = {}, beforeInput = () => {}) {
    const newAttrs = Object.assign({}, base, {
        id: fieldinst.id,
        value: fieldinst.value,
        onblur() {
            fieldinst.showError = fieldinst.value.length > 0;
        },
        oninput({ target: { value } }) {
            beforeInput(value);
            fieldinst.showError = false;
            fieldinst.value = value;
        },
    });
    return (base['data-harness'])
        ? harness(base['data-harness'], newAttrs)
        : newAttrs;
}

export const requiredString = (id) => field({
    id,
    initialValue: '',
    validate({ length }) {
        if (length <= 0) {
            throw RequiredValue();
        }
    },
});
