import xhr from '#/browser-framework/xhr';


/*
Used to select a set of possible states or provinces
in which to authenticate a person via an identifying
document.
*/
export const ACUANT_REGIONS = {
    AFRICA: 7,
    AMERICA: 2,
    ASIA: 5,
    AUSTRALIA: 4,
    CANADA: 1,
    EUROPE: 3,
    GENERAL_DOCUMENTS: 6,
    UNITED_STATES: 0,
};


/*
Used to select a validation and verification pipeline.
Each type has different requirements.
*/
export const ACUANT_CARD_TYPES = {
    DRIVERS_LICENSE: 'DriversLicense',
    DRIVERS_LICENSE_DUPLEX: 'DriversLicenseDuplex',
    PASSPORT: 'Passport',
    MEDICAL_CARD: 'MedicalCard',
    BARCODE: 'Barcode',
};


/*
The web services can kick back the images you gave it.
This controls if you keep the colors or convert to
grayscale.
*/
export const ACUANT_REFORMAT_COLOR = {
    KEEP_ORIGINAL: 0,
    MAKE_GRAYSCALE: 1,
};


/*
Pertains to authenticating a person using an
identifying document such as a driver's license
or passport.
*/
export const ACUANT_AUTH_RESULTS = {
    PASSED: 'Passed',
    FAILED: 'Failed',
    ATTENTION: 'Attention',
    UNKNOWN: 'Unknown',
};


/*
Specify where the image came from. This impacts the requirements imposed
on the image by Acuant web services. The requirements are summarized in
the comments, but you should refer to the official docs.
*/
export const ACUANT_IMAGE_SOURCE = {
    // Requires 1250px wide image
    CROPPED_CAMERA: 101,

    // Requires 300 or 600dpi. If image is uncropped, set CropImage = true
    SCANSHELL_OR_TWAIN_SCANNER: 102,

    // SnapShell scanner image. Assumed cropped.
    SNAPSHELL_SCANNER: 103,

    // Allow AcuFill Webservices to resize image. Works independently of CropImage.
    MISC: 105,
};


// References our license with Acuant.
const b64key = btoa(deploy.WEB_PUBLIC_ACUANT_LICENSE_KEY);


// Create authenticated XHR client used to upload binary data.
const req = xhr.extend(xhr, (o) => ({
    url: `${deploy.WEB_PUBLIC_ACUANT_URL_PREFIX}/CardProcessor${o.url}`,
    config(xo) {
        xhr.setHeaders(xo, {
            'Authorization': `LicenseKey ${b64key}`,
            'Content-Type': 'application/octet-stream; charset=utf-8;',
        });
    },
}));

/*
Join positional URL path parameters, filtering out undefined.
Used to make calls to Acuant a little easier to understand.

Usage: condense(true, 100, false, undefined, 70) --> true/100/false/70

For readability, use such that arguments appear to have names.

condense(
    enablePhotonTorpedos,
    torpedoSpeed,
    deployDrStrangelove,
)
*/
const condense = (...a) =>
    a.filter((v) => v !== undefined).map((s) => s.toString()).join('/');


/*
Compare system and image characteristics to decide if it's a good idea to
preprocess an image on the client. This function does NOT tell you the
desired specifications of an image if this function returns true. Refer
to Acuant docs for this.
*/
export function shouldPreprocessOnTheClient({
    isiOS,
    dpi,
    cardType,
}) {
    const { DRIVERS_LICENSE, DRIVERS_LICENSE_DUPLEX, PASSPORT, MEDICAL_CARD, BARCODE } = ACUANT_CARD_TYPES;

    // Certain versions of iOS suffer with large images.
    // We'd rather let Acuants's card processor reject the image than prevent the user
    // from doing anything at all because they happen to own an iOS device.
    if (isiOS) {
        return true;
    }

    // Acuant prefers identifying documents to have a certain quality.
    // If we already know an image meets their specifications, we should
    // not mess with it before submission.
    const IdCardOrPassportIs300dpi = (
        dpi === 300 && [DRIVERS_LICENSE, DRIVERS_LICENSE_DUPLEX, PASSPORT].includes(cardType)
    );

    const MedCardOrBarcodeIs600dpi = (
        dpi === 600 &&
        [MEDICAL_CARD, BARCODE].includes(cardType)
    );

    const imageIsAtMinimumNeededQuality = IdCardOrPassportIs300dpi || MedCardOrBarcodeIs600dpi;

    return !imageIsAtMinimumNeededQuality;
}

function deriveAuthenticationFeedback(results) {
    const {
        AuthenticationResult,
        AuthenticationResultSummary,
    } = results;

    const {
        ATTENTION,
        FAILED,
    } = ACUANT_AUTH_RESULTS;


    return new Promise((resolve, reject) => {
        if ([ATTENTION, FAILED].includes(AuthenticationResult)) {
            const reasons = (AuthenticationResultSummary.length === 0)
                ? 'Authentication failed'
                : AuthenticationResultSummary.join('\n');

            reject(new Error(reasons));
        } else {
            resolve(results);
        }
    });
}


// Returns information about the active license.
export function getApiLicenseDetails() {
    return req({
        url: '/GetLicenseDetails',
    });
}

// Processes only the front side of the driver's license.
export function processDriversLicense(frontImage, {
    region = ACUANT_REGIONS.UNITED_STATES,
    preprocessImage = false,
    procState = -1,
    getFaceImage = false,
    getSignImage = false,
    reformatImage = false,
    reformatImageColor = ACUANT_REFORMAT_COLOR.KEEP_ORIGINAL,
    reformatImageDpi = 150,
    imageSource = ACUANT_IMAGE_SOURCE.MISC,
    wideCharacters = false,
    logTransaction = false,
} = {}) {
    const formData = new FormData();

    formData.append('frontImage', frontImage);

    const autoDetectState = !Number.isInteger(procState) || procState < 0;

    return req({
        method: 'POST',
        data: formData,
        url: `/ProcessDriversLicense/${condense(
            region,
            autoDetectState,
            procState,
            getFaceImage,
            getSignImage,
            reformatImage,
            reformatImageColor,
            reformatImageDpi,
            imageSource,
            preprocessImage,
            wideCharacters,
            logTransaction,
        )}`,
    });
}

// Process both sides of a driver's license at once.
export function processDLDuplex(frontImage, backImage, {
    region = ACUANT_REGIONS.UNITED_STATES,
    preprocessImage = false,
    procState = -1,
    getFaceImage = false,
    getSignImage = false,
    reformatImage = false,
    reformatImageColor = ACUANT_REFORMAT_COLOR.KEEP_ORIGINAL,
    reformatImageDpi = 150,
    imageSource = ACUANT_IMAGE_SOURCE.MISC,
    wideCharacters = false,
    logTransaction = false,
} = {}) {
    const formData = new FormData();

    formData.append('frontImage', frontImage);
    formData.append('backImage', backImage);

    const autoDetectState = !Number.isInteger(procState) || procState < 0;

    return req({
        method: 'POST',
        data: formData,
        url: `/ProcessDLDuplex/${condense(
            region,
            autoDetectState,
            procState,
            getFaceImage,
            getSignImage,
            reformatImage,
            reformatImageColor,
            reformatImageDpi,
            imageSource,
            preprocessImage,
            wideCharacters,
            logTransaction,
        )}`,
    }).then((result) => {
        return deriveAuthenticationFeedback(result);
    });
}

export function processPassport(formData, {
    getFaceImage = false,
    getSignImage = false,
    imageSource = undefined,
    logTransaction = undefined,
    preprocessImage = false,
    reformatImage = false,
    reformatImageColor = ACUANT_REFORMAT_COLOR.KEEP_ORIGINAL,
    reformatImageDpi = 150,
    wideCharacters = undefined,
} = {}) {
    return req({
        method: 'POST',
        url: `/ProcessPassport/${condense(
            getFaceImage,
            getSignImage,
            reformatImage,
            reformatImageColor,
            reformatImageDpi,
            preprocessImage,
            imageSource,
            wideCharacters,
            logTransaction,
        )}`,
        data: formData,
    });
}

export function processMedInsuranceCard(formData, {
    reformatImage = true,
    reformatImageColor = ACUANT_REFORMAT_COLOR.KEEP_ORIGINAL,
    reformatImageDpi = 150,
    preprocessImage,
    logTransaction = undefined,
}) {
    return req({
        method: 'POST',
        url: `/ProcessMedInsuranceCard/${condense(
            reformatImage,
            reformatImageColor,
            reformatImageDpi,
            preprocessImage,
            logTransaction,
        )}`,
        data: formData,
    });
}
