const { default: xhr } = require('#/browser-framework/xhr');
const { decodeString } = require('#/universal-framework/b64');

const {
    decorateOptions,
    decorateComms,
} = xhr;

module.exports = {
    _xhr: undefined,
};

const CAMEL_CASE_BOUNDARY = /([a-z])([A-Z])/g;

const _UNAUTHORIZED_REASON_CODE = 'auth/unauthorized';
const _NO_PROMISE_CTOR_REASON_CODE = 'base/no-promise-ctor';
const _UNINITIALIZED_REASON_CODE = 'base/uninitialized';

const _AUTH_ERROR_REASONS = [
    'auth/api-key-failure',
    'auth/no-credentials-specified',
    'auth/invalid-credentials-specified',
    'auth/token-authentication-failure',
    'auth/no-token-presented',
    'auth/token-verification-failed',
    'auth/token-expired',
    'auth/token-domain-error',
    'auth/token-subject-domain-mismatch',
    'auth/token-invalid-domain-specified',
];

function _parseEmbeddedToken(tok) {
    try {
        return JSON.parse(decodeString(tok.split('.')[1], {
            urlsafe: /[\-\_]/.test(tok),
        }));
    } catch (e) {
        e.reason = _UNAUTHORIZED_REASON_CODE;

        throw e;
    }
}

function _discernAuthErrorReason(xhrJsonError) {
    const { jsonResponse } = xhrJsonError;

    const transformedCode = `auth/${jsonResponse.auth_error.replace(CAMEL_CASE_BOUNDARY, '$1-$2').toLowerCase()}`;

    return (_AUTH_ERROR_REASONS.includes(transformedCode))
        ? transformedCode
        : _UNAUTHORIZED_REASON_CODE;
}

function _throwIfCommonError(xhrJsonError) {
    const e = new Error();
    const { xhrObj: xo } = xhrJsonError;

    if (xo.status === 401) {
        e.reason = _discernAuthErrorReason(xhrJsonError);
    } else if (xo.status >= 500) {
        e.reason = 'service/internal-error';
    } else if (xo.status === 0) {
        e.reason = 'service/cant-connect';
    }

    if (e.reason) {
        throw e;
    }
}

const ENVIRONMENTS = {
    PROD: '',
    DEMO: '.demo',
};

function setUp({
    environment = ENVIRONMENTS.DEMO,
    singleUseToken = null,
    // eslint-disable-next-line no-new-func
    Promise = (Function('return this')()).Promise,
} = {}) {
    if (typeof Promise !== 'function' || typeof Promise.resolve !== 'function' || typeof Promise.reject !== 'function') {
        const e = new Error('Need a Promise constructor, or equivalent');

        e.reason = _NO_PROMISE_CTOR_REASON_CODE;

        throw e;
    }

    module.exports.Promise = Promise;

    const baseUrl = `https://assure.api${environment}.evidentid.com/api/v1`;

    const authenticated = decorateOptions(xhr.json, (o) => {
        const last = o.config || (() => {});

        o.url = `${baseUrl}${o.url}`;

        o.config = (xo) => {
            xo.setRequestHeader('Authorization', `Bearer ${singleUseToken}`);
            last(xo);
        };

        return Object.assign({}, o);
    });

    const withComms = decorateComms(authenticated, {
        incomingError(xhrError) {
            _throwIfCommonError(xhrError);

            return xhrError;
        },
    });

    module.exports._xhr = withComms;

    return module.exports.Promise
        .resolve()
        .then(() => _parseEmbeddedToken(singleUseToken).requestId);
}

function client(...args) {
    if (module.exports._xhr) {
        return module.exports._xhr(...args);
    } else {
        throw Object.assign(new Error('.setUp() not called.'), {
            reason: _UNINITIALIZED_REASON_CODE,
        });
    }
}

if (deploy.WEB_PUBLIC_VARIANT === 'production') {
    Object.assign(module.exports, {
        ENVIRONMENTS,
        setUp,
        client,
    });
} else {
    Object.assign(module.exports, {
        setUp,
        client,
        ENVIRONMENTS,
        CAMEL_CASE_BOUNDARY,
        _UNAUTHORIZED_REASON_CODE,
        _NO_PROMISE_CTOR_REASON_CODE,
        _UNINITIALIZED_REASON_CODE,
        _AUTH_ERROR_REASONS,
        _parseEmbeddedToken,
        _discernAuthErrorReason,
        _throwIfCommonError,
    });
}
