import { m, harness } from '#/browser-framework';
import { PhoneNumberInput } from '#/browser-framework/comps';

import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';


const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const { changePhoneNumber, phoneNumberValid, value } = q;

        return m(DefaultQuestionForm, {
            maySubmit: phoneNumberValid,
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(LineInputBlock, {id: 'phone'},
            m(PhoneNumberInput, harness('dedicated-phone-number-input', {
                id: 'phone',
                value,
                onchange: changePhoneNumber,
            }))));
    },
};


export default function PhoneNumber() {
    const model = {
        view: Form,
        phoneNumberValid: false,
        value: '',
        countryCode: '1',

        encode() {
            const {
                attrType,
                countryCode,
                shareWith,
                value,
            } = model;

            const digits = value.replace(/\D/g, '');

            return {
                [attrType]: {
                    shareWith,
                    value: {
                        $objectType: 'PhoneNumber',
                        country_code: countryCode,
                        area_code: digits.substring(0, 3),
                        prefix: digits.substring(3, 6),
                        line_number: digits.substring(6, 10),
                    },
                },
            };
        },

        changePhoneNumber(data) {
            model.phoneNumberValid = data.valid;
            model.value = data.value;
            model.countryCode = data.countryCode;
        },
    };

    return model;
}
