import { spa } from '#/browser-framework';
import { simplex } from '#/evident-attributes';
import { createBinaryDataFromDataUrl } from '#/evident-attributes/encoding/documents';

export function scanner() {
    const iface = {};

    iface.pages = [];
    iface.isValid = () => iface.pages.every((p) => p.dataUrl);
    iface.weakVerification = () => Promise.resolve(iface.isValid());

    return iface;
}

function buildRegionSegment(region, country) {
    const tokens = [
        'identity_assurance',
        'document_verification',
    ];

    if (region) {
        tokens.push(region);

        if (country) {
            tokens.push(country.toLowerCase());
        }
    }

    return tokens;
}

export function passportScanner() {
    const iface = scanner();

    iface.pages = [simplex()];
    iface.passport = iface.pages[0];

    iface.stagePassportPage = spa.redrawAfter((file) => {
        iface.passport.file = file;

        iface.passport.dataUrl = undefined;
        spa.redraw();

        return iface.passport.computeDataUrl();
    });

    iface.encode = (document, region, country) => {
        const tokens = buildRegionSegment(region, country);

        tokens.push('passport', 'image');

        return {
            [tokens.join('.')]: {
                value: createBinaryDataFromDataUrl(iface.passport.dataUrl),
            },
        };
    };

    return iface;
}

export function duplexScanner() {
    const iface = scanner();

    iface.pages = [simplex(), simplex()];

    iface.front = iface.pages[0];
    iface.back = iface.pages[1];

    iface.setSimplex = spa.redrawAfter((file, side) => {
        iface[side].file = file;

        iface[side].dataUrl = undefined;
        spa.redraw();

        return iface[side].computeDataUrl();
    });

    iface.encode = (document, region, country, isLegacy) => {
        const tokens = buildRegionSegment(region, country);

        tokens.push(document);

        return (isLegacy)
            ? {
                'identity_assurance.document_verification.us_issued.front_image': {
                    value: createBinaryDataFromDataUrl(iface.front.dataUrl),
                },
                'identity_assurance.document_verification.us_issued.back_image': {
                    value: createBinaryDataFromDataUrl(iface.back.dataUrl),
                },
            }
            : {
                [`${tokens.join('.')}.image.front`]: {
                    value: createBinaryDataFromDataUrl(iface.front.dataUrl),
                },
                [`${tokens.join('.')}.image.back`]: {
                    value: createBinaryDataFromDataUrl(iface.back.dataUrl),
                },
            };
    };

    return iface;
}
