import { contract, signature } from './functions';

function flattenKeys(...args) {
    return args.reduce((p, c) => {
        if (Array.isArray(c)) {
            p.push(...flattenKeys(...c));
        } else if (typeof c === 'object') {
            p.push(...flattenKeys(...Object.keys(c)));
        } else {
            p.push(c.toString());
        }

        return p;
    }, []);
}

export function deepKeyExists(dotstring, subject) {
    return dotstring.split('.').reduce(([res, next], key) =>
        [res && key in next, (next || {})[key]], [true, subject])[0];
}

export function keyInclude(subject, ...args) {
    return flattenKeys(...args).reduce((p, c) => {
        p[c] = subject[c];

        return p;
    }, {});
}

export function shallowKeyFilter(subject, keepOnly = (k, v) => Boolean(v)) {
    return Object.entries(subject).reduce((p, [k, v]) => {
        if (keepOnly(k, v)) {
            p[k] = v;
        }

        return p;
    }, {});
}

export function keyExclude(subject, ...args) {
    const selection = new Set(flattenKeys(...args));
    const out = {};

    for (const k of Object.keys(subject)) {
        if (!selection.has(k)) {
            out[k] = subject[k];
        }
    }

    return out;
}

export function factory(...fns) {
    return fns.reduce((o, f) => Object.assign(o, f(o)), {});
}

export const permuteEntries = contract(
    (obj, order) => {
        const mapping = order.reduce((p, k, i) => Object.assign(p, { [k]: i }), {});
        const entries = Object.entries(obj);

        const sortable = entries.filter(([k]) => k in mapping);
        const extra = entries.filter(([k]) => !(k in mapping));

        return {
            permutation: sortable.sort(([a], [b]) => mapping[a] - mapping[b]),
            extra,
        };
    },
    signature(({
        and,
        any,
        arrayOf,
        entry,
        objectOf,
        hasPositiveLength,
        nonEmptyString,
        shape,
    }) => ({
        domain: [
            objectOf(any),
            and(
                arrayOf(nonEmptyString),
                hasPositiveLength),
        ],
        range: shape({
            permutation: arrayOf(entry),
            extra: arrayOf(entry),
        }, {
            allowExtraKeys: false,
        }),
        displayName: 'permuteEntries',
    })));
