import { events, router, spa } from '#/browser-framework';
import { Snackbar, Spinner, SpinnerWithText } from '#/browser-framework/comps';

import { deslugAttrType, doesAttrTypeRepresentIdScan } from '#/evident-attributes';

import { loadStripe, paymentController } from '#/stripe-pos';

import {
    getSessionData,
    sendConsent,
    setSessionSource,
    submitPayment,
} from '#/ido-lib/idoWebClient';

import AttributeModule from '#/ido-lib/store/AttributeModule';
import { interviewController } from '#/ido-lib';
import { AllQuestions } from '#/ido-lib/views/AllQuestions';
import { InterviewCard, SingleCol } from '#/ido-lib/views/Layouts';
import { SuccessPage } from '#/ido-lib/views/SuccessPage';
import { CheckoutForm } from '#/ido-lib/views/CheckoutForm';

import { errorController } from './errors';
import { googleBizController } from './googleBusinessVerification';
import { ido } from './ido';
import { makeTransitionPoll } from './requestTransition';

import Store from '#/store';

let _instance;

export function appController() {
    if (_instance) {
        return _instance;
    }

    const store = Store.getInstance();
    store.addModule(AttributeModule.Module);

    const iface = _instance = {};

    iface.referrer = null;
    iface.errors = errorController();
    iface.interview = interviewController(spa, iface.errors);
    iface.googleDemo = googleBizController();
    iface.user = ido();
    iface.snackbar = Snackbar.makeViewModel();

    setSessionSource(spa.$window);
    const session = getSessionData();

    if (session) {
        iface.user.logIn(session);
    }

    const { withInterview } = iface.interview;


    // Navigate here to inform the user that they are dismissed.
    router.on('Thank you', '/done', withInterview(() => {
        spa.setView(SuccessPage, { layout: InterviewCard });
    }));


    // This is the landing page for the typical use-case.
    router.on('Overview', '/overview', withInterview(() => {
        if (iface.interview.areAllRequirementsMet()) {
            const { rprId } = getSessionData();
            const poller = makeTransitionPoll(rprId);

            spa.setView(Spinner, { layout: SingleCol });
            poller.start();
        } else {
            spa.setView(AllQuestions, { layout: InterviewCard });
        }
    }));

    // Endpoint used to select an interview.
    router.on('', '/interview/:rprId', ([rprId], { q, canary }) => {
        if (rprId && canary) {
            iface.user.logIn({ rprId, canary });

            const landing = (q)
                ? `/question/${q}`
                : '/overview';

            router.go(landing, { replace: true, query: {} });
        } else {
            iface.errors.showError('no-request-id');
        }
    });

    // For backwards compatibility with any existing delegation links.
    router.on('', '/scan/wizard/:rprId', ([rprId], { canary }) => {
        iface.user.logIn({ rprId, canary });

        iface.loadInterview().then(() => {
            const idScanQuestion = spa
                .viewModel
                .questions
                .find(({ complete, attrType }) => {
                    return !complete && doesAttrTypeRepresentIdScan(attrType);
                });

            if (idScanQuestion) {
                router.go(`/interview/${rprId}`, {
                    replace: true,
                    query: {
                        q: idScanQuestion.attrType,
                    },
                });
            } else {
                router.go('/overview', { replace: true });
            }
        });
    });

    // Users visiting legacy routes are directed back to their original
    // links because these deprecated routes cannot capture interview state.
    const directUserToOriginalLink = iface.errors.showError.bind(null, 'direct-to-original-link');
    router.on('', '/scan/front', directUserToOriginalLink);
    router.on('', '/scan/back', directUserToOriginalLink);

    // For now there exists no home page.
    router.on('404', '/', iface.errors.showError.bind(null, 'page-not-found'));

    router.on('Question', '/question/:questionTypeSlug', withInterview(([questionTypeSlug]) => {
        iface.interview.selectQuestion(deslugAttrType(questionTypeSlug));
    }));


    // Fires when the user says they have answered all questions.
    events.on('finished-all-questions', () => {
        if (iface.interview.interviewModel.balance) {
            router.go('/pay');
        } else {
            events.emit('finished-interview');
        }
    });

    // Fires when the user is all done and may now leave the application.
    events.on('finished-interview', () => {
        const { rprId } = getSessionData();
        const poller = makeTransitionPoll(rprId);

        if (iface.user.authDomain.startsWith('googlelocalsvcs')) {
            router.disableBrowserBackButton();
            spa.setView(SpinnerWithText, {
                layout: InterviewCard,
                vars: {text: 'Thank you for your submission. Please hold while your Business Entity background check application is being processed and we direct you to your Business Owner background check application.' }});
        } else {
            spa.setView(Spinner, { layout: SingleCol });
        }

        sendConsent(rprId)
            .then(() => poller.start())
            .catch(() => {
                iface.errors.showError('submission-failed');
            });
    });



    router.on('Checkout', '/pay', withInterview(() => {
        const { interviewModel, areAllQuestionsAnswered } = iface.interview;

        if (areAllQuestionsAnswered() && interviewModel.balance) {
            loadStripe(spa.$window).then((stripe) => {
                iface.pos = paymentController({
                    stripe,
                    $window: spa.$window,
                    currency: interviewModel.balance.currency,
                    amount: interviewModel.balance.amount,
                    submitPayment,
                });

                iface.pos.on('before-payment', spa.redraw);
                iface.pos.on('element-changed', spa.redraw);

                spa.setView(CheckoutForm, { layout: InterviewCard });
            });
        } else {
            router.go('/overview');
        }
    }));

    return iface;
}
