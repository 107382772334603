import { arrays, m, events } from '#/browser-framework';
import { PrivacyNotice } from './PrivacyNotice';
import { InterviewStepTile } from './InterviewStepTile';


// We will eventually accept back end ordering.
const SEMANTIC_ORDER = {
    'core.fullname': 0,
    'core.firstname': 1,
    'core.middlename': 2,
    'core.lastname': 3,
    'core.ssn': 4,
    'core.dateofbirth': 5,
    'consent.fcra': 6,
};


/*
Sort questions by semantic English and UX rules for types, so
that "First Name" appears before "Last Name", for example.

Input:
                       Last Name (core.lastname)
                      First Name (core.firstname)
          Social Security Number (core.ssn)
                         ID Scan (<varies>)
    Authorization and Disclosure (consent.fcra)

Output (Note that the indices above declare type ordering, and other attrs appear at the bottom):

    Authorization and Disclosure
                      First Name
                       Last Name
          Social Security Number
                         ID Scan

*/
function semanticCuratedTypeSort(questions) {
    if (!questions.length) {
        return [];
    }

    // Note that we do a group sort such that the attributes in
    // SEMANTIC_ORDER are sorted seperately from those NOT in SEMANTIC_ORDER.
    // Sorting all of them would yield incorrect results.
    const prefix = questions
        .filter(({ attrType }) => attrType in SEMANTIC_ORDER)
        .sort(arrays.comparator(
            ({ attrType }) => SEMANTIC_ORDER[attrType]));

    return prefix.concat(
        questions.filter(
            ({ attrType }) => !(attrType in SEMANTIC_ORDER)));
}



/*
Group questions into complete and incomplete, then sort each group
using semanticCuratedTypeSort. Complete questions come after incomplete
questions.


Input:
                       Last Name ☑
                      First Name ☑
          Social Security Number ☐
    Authorization and Disclosure ☐
                         ID Scan ☐

Output:

    Authorization and Disclosure ☐
          Social Security Number ☐
                         ID Scan ☐
                      First Name ☑
                       Last Name ☑
*/
export function semanticQuestionSort(questions) {
    const breakout = questions.slice(0);

    const checked = breakout.filter(({ complete }) => complete);
    const unchecked = breakout.filter(({ complete }) => !complete);

    const sortedComplete = semanticCuratedTypeSort(checked);
    const sortedPending = semanticCuratedTypeSort(unchecked);

    return sortedPending.concat(sortedComplete);
}



export const AllQuestions = {
    view({
        attrs: spa,
    }) {
        const {
            interview: {
                areAllQuestionsAnswered,
                interviewModel: {
                    questions,
                    content: {
                        introText,
                        dbaNameText,
                    },
                },
            },
        } = spa.viewModel;

        const steps = semanticQuestionSort(questions);

        if (areAllQuestionsAnswered()) {
            events.emit('finished-all-questions');
        }

        return m('.AllQuestions',
            m('p.faded', introText),
            m('section.InterviewCard__steps',
                steps.map((question, key) => {
                    return (!question.metadata.hidden) && m(InterviewStepTile, {
                        key,
                        question,
                    });
                })),
            steps.filter((step) => (step.metadata.required)).length
                ? m('em',
                    m('span.fg-danger', '*'),
                    ' Required information')
                : '',
            m(PrivacyNotice, {
                dbaNameText,
            }));
    },
};
