import { definition, ifCaught } from './errors';

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isUrl = (url) =>
    ifCaught(
        () => false,
        () => Boolean(new URL(url)));

export function isEmail(eml) {
    return typeof eml === 'string' && eml.length <= 254 && EMAIL_REGEX.test(eml);
}

export const RequiredValue = definition(null, {
    reason: 'validation/value-required',
    message: 'This value is required.',
});

export const NotAnEmail = definition(null, {
    reason: 'validation/invalid-email',
    message: 'Invalid email',
});

export const NotAUrl = definition(null, {
    reason: 'validation/invalid-url',
    message: 'Invalid url',
});
