import { m } from '#/browser-framework';
import { Dropdown } from 'mithril-materialized';


export const materialLogout = {
    view({ attrs: spa}) {
        return m(Dropdown, {
            label: spa.viewModel.user.email,
            items: [{
                label: 'Sign out',
                id: 'logout-button',
            }],
            onchange: () => spa.viewModel.user.googleLogOut(),
        });
    },
};
