import {m, tx} from '#/browser-framework';
import Named from './Named';


export default {
    // TODO:  Remove need for cssclass attribute
    view({attrs: {id, checked, onchange, 'data-harness': dataharness, cssclass = ''}, children}) {
        const selector = tx('ConsentCheckbox', {checked}, cssclass);
        const icon = (checked)
            ? 'checked'
            : 'unchecked';

        const checkboxOptions = {
            id,
            type: 'checkbox',
            style: {
                display: 'none',
            },
            onchange: (e) => {
                onchange(e.target.checked);
            },
        };

        if (checked) {
            checkboxOptions.checked = true;
        }

        return m(`label${selector}`, {for: id, 'data-harness': dataharness},
            m('input', checkboxOptions),
            m(Named.Icon, {name: icon}), ' ', children);
    },
};
