import { m, bem } from '#/browser-framework';

import Named from './Named';


const btx = bem`
    Tooltip
        pointer
        box
        hotspot
        expansion`;

export default {
    expanded: false,
    view({ children, state }) {
        const {
            block,
            box,
            hotspot,
            pointer,
            expansion,
        } = btx;

        return m(block, {
            style: {
                position: 'relative',
                overflow: 'visible',
            },
        },
        m(hotspot, {
            onmouseenter() {
                state.expanded = true;
            },
            onmouseleave() {
                state.expanded = false;
            },
        }, m(Named.Icon, { name: 'tooltip-hotspot' })),
        (state.expanded) && m(expansion, {
            style: {
                position: 'absolute',
            },
        },
        m(pointer),
        m(box, children)));
    },
};
