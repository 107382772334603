import { definition, exception, raise } from './errors';

export const ArityMismatch = definition(exception, {
    reason: 'airity-mismatch',
    message: '{actual} arguments passed to {expected}-arity function {displayName}',
    config: {
        format: true,
    },
});

export const ArgumentRejected = definition(exception, {
    reason: 'argument-rejected',
    message: '{displayName} caller argument {index} rejected. Expected {type}. Got {actual}.',
    config: {
        format: true,
    },
});

export const DomainSpecArityMismatch = definition(exception, {
    reason: 'domain-spec-airity-mismatch',
    message: '{actual} argument(s) passed to arity-{expected} function {displayName}.',
    config: {
        format: true,
    },
});

export const RangeViolation = definition(exception, {
    reason: 'range-violation',
    message: '{displayName} broke its own range contract. Expected {type}. Got {actual}.',
    config: {
        format: true,
    },
});

export const identity = (v) => v;

/*
Late binding sugar for introducing bindings in expressions without
statements, e.g. letc([1, 2, 3, 4], (numbers) => (...))

Benchmark data from https://jsfiddle.net/bm4c107g/3/

Given f := () => null and a := [1, 2, 3, 4, 5, 6, 7, 8, 9]

  letc x 1,620,096,760 ops/sec ±0.97% (97 runs sampled)
  lets x    48,107,187 ops/sec ±0.88% (98 runs sampled)
direct x 1,607,283,753 ops/sec ±1.17% (99 runs sampled)

Takeaway: letc() overhead is not severe. Use lets() (and
therefore the spread operator) sparingly.
*/
export const letc = (a, f) => f(a);
export const lets = (a, f) => f(...a);

// Functional switch-like form
//
// Usage:
//    30 === cases(3, [[1, 'A'], [2, 'B'], [3, 'C']])
//    'fallback' === cases(1, [], 'fallback')
export const cases = (v, a, def) =>
    lets([a.find(([p]) => (v === p))],
        (res) => ((res) ? res[1] : def));

export const firstTrue = (a, def) =>
    cases(true, a.map((p) => [Boolean(p[0]), p[1]]), def);

// Returns a function that can act as an arity-1 decorator if `enabled` is truthy,
// otherwise no-op the decoration with the identity function.
export const conditionalDecorator = (enable, decorate) =>
    ((enable) ? decorate : identity);

// Decorate f such that function g tracks f's arguments independent of f's control flow.
export const tee = (f, g) => (...a) => lets([f(...a), g(...a)], identity);

// Similar to React Proptypes API to smooth learning curve, except that
// values are assumed required by default.
export const types = {};

// Return a validation function with a few characteristics:
//
//     - Returns a boolean
//     - Always has a `optional` key holding another validator that allows `undefined`.
//     - A custom type descriptor string used to build errors when validation fails.
//
// If deploy.WEB_PUBLIC_ENABLE_PREDICATE_TRANSPARANCY is 'true', then typeName will default
// to the function's source code. Disable this if there is any risk that a thrown exception
// might distribute code. Only set it to 'true' when prototyping complex predicates.
types.predicate = (f, typeName = ((deploy.WEB_PUBLIC_ENABLE_PREDICATE_TRANSPARANCY === 'true') ? f.toString() : '<anon>')) =>
    Object.assign(
        (v) => (typeof v !== 'undefined' && Boolean(f(v))),
        {
            typeName,
            optional: Object.assign(
                (v) => (typeof v === 'undefined' || Boolean(f(v))),
                { typeName: `undefined or ${typeName}` }),
        });

// Note there is still a difference between types.any and types.any.optional!
types.any = types.predicate((v) =>
    typeof v !== 'undefined', 'any');

// typeof-based predicates
types.ofType = (t) => types.predicate((v) => (typeof v === t), t);
types.bool = types.ofType('boolean');
types.object = types.ofType('object');
types.string = types.ofType('string');
types.number = types.ofType('number');
types.func = types.ofType('function');
types.symbol = types.ofType('symbol');

// Predicate combinators
types.and = (...predicates) =>
    types.predicate(
        (v) => predicates.every((p) => p(v)),
        `All of ${predicates.map(({ typeName }) => typeName).join(', ')}`);

types.or = (...predicates) =>
    types.predicate(
        (v) => predicates.some((p) => p(v)),
        `Any of ${predicates.map(({ typeName }) => typeName).join(', ')}`);

types.not = (predicate) =>
    types.predicate(
        (v) => !predicate(v),
        `Not ${predicate.typeName}`);

types.oneOf = (vals) =>
    types.predicate(
        (v) => vals.indexOf(v) > -1,
        `One of: '${vals.join('\', \'')}'`);

types.arrayOf = (p) =>
    types.and(
        types.array,
        types.predicate(
            (v) => v.every(p),
            `All elements of type ${p.typeName}`));

// Numerical
types.positive = types.and(
    types.number,
    types.predicate(
        (n) => n > 0,
        'Positive number'));

types.negative = types.and(
    types.number,
    types.predicate(
        (n) => n < 0,
        'Negative number'));

types.nonnegative = types.and(
    types.number,
    types.predicate(
        (n) => n >= 0,
        'Non-negative number'));

types.integer = types.predicate(
    (n) => Number.isInteger(n),
    'Integer');

types.natural = types.and(
    types.positive,
    types.integer);


// Objects
types.instanceOf = (ctor) =>
    types.predicate(
        (v) => v instanceof ctor,
        `Instance of ${ctor.name}`);

types.objectOf = (p) =>
    types.predicate(
        (v) => (
            typeof v === 'object' &&
            v !== null &&
            lets([Object.values(v)], (vals) =>
                vals.length > 0 && vals.every(p))),
        `Object of ${p.typeName}`);

const _formatShapeType = (obj) =>
    `{ ${Object.entries(obj).map(([k, v]) =>
        `${k}: <${v.typeName}>`).join(', ')} }`;

const _formatShapeMessage = (obj, allowExtraKeys) =>
    `Object shape${(allowExtraKeys) ? '' : ' (no extra keys)'}: `
        + `${_formatShapeType(obj)}`;

types.shape = (obj, { allowExtraKeys = true } = {}) =>
    types.predicate(
        (v) =>
            (
                typeof v === 'object' &&
                v !== null &&
                (
                    allowExtraKeys ||
                    Object.keys(v).length === Object.keys(obj).length
                ) &&
                Object
                    .entries(obj)
                    .every(([k, pred]) => pred(v[k]))
            ),
        _formatShapeMessage(obj, allowExtraKeys));

// Casework
types.hasPositiveLength = types.predicate((v) => v.length > 0, 'has non-zero length');
types.isNull = types.predicate((v) => v === null, 'null');
types.nonNullObj = types.and(types.object, types.not(types.isNull));
types.isNaN = types.predicate((v) => isNaN(v), 'NaN');
types.isFinite = types.predicate((v) => isFinite(v), 'finite');
types.array = types.predicate((v) => Array.isArray(v), 'array');
types.truthy = types.predicate((v) => Boolean(v), 'truthy');
types.falsey = types.predicate((v) => !v, 'falsey');
types.hopefully = (p) => types.or(p, types.falsey).optional;
types.nonEmptyString = types.and(types.string, types.hasPositiveLength);
types.nonEmptyArray = types.and(types.array, types.hasPositiveLength);
types.objectPattern = (kpred, vpred) =>
    types.predicate(
        (v) =>
            Object
                .entries(v)
                .every(([k, kv]) => kpred(k) && vpred(kv)),
        `Object with keys ${kpred.typeName} and values ${vpred.typeName}`);

types.entry =
    types.predicate(
        (v) => (
            types.array(v) &&
            v.length === 2 &&
            types.nonEmptyString(v[0])),
        'Entry');


// Type declaration function
export const type = (f) => lets([types], f);
export const signature = (f) => type((...a) => () => f(...a));


// Applies computed runtime contracts to functions. Used in line with
// roadmap that does not include TypeScript/Flow.
export const mandate = (f, negotiate) => {
    return (...args) => {
        const {
            domain,
            range = types.any,
            displayName = (f.name || '<anon>'),
        } = negotiate(...args);

        // Use arguments length against the domain
        // to catch optional arguments.
        if (domain.length < args.length) {
            raise(DomainSpecArityMismatch, {
                extra: {
                    displayName,
                    actual: args.length,
                    expected: domain.length,
                },
            });
        } else if (args.length < f.length) {
            raise(ArityMismatch, {
                extra: {
                    displayName,
                    actual: args.length,
                    expected: f.length,
                },
            });
        } else {
            const violatingArgIndex = args.findIndex((a, i) => !domain[i](a));

            if (violatingArgIndex === -1) {
                const r = f(...args);

                if (!range(r)) {
                    raise(RangeViolation, {
                        extra: {
                            displayName,
                            actual: r,
                            type: range.typeName,
                        },
                    });
                }

                return r;
            } else {
                raise(ArgumentRejected, {
                    extra: {
                        displayName,
                        type: domain[violatingArgIndex].typeName,
                        index: violatingArgIndex,
                        actual: args[violatingArgIndex],
                    },
                });
            }
        }
    };
};

// Use this for most cases! Allows prod builds to opt out of performance hit.
export const contract = conditionalDecorator(
    deploy.WEB_PUBLIC_VARIANT !== 'production',
    mandate);
