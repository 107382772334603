import {m, harness} from '#/browser-framework';

import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';


const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            first,
            middle,
            last,
            isValid,
        } = q;


        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(LineInputBlock,
            m('span', 'Enter first name', m('i.fg-danger', '*')),
            m('input', harness('first-name-in-full-name-input', {
                value: first,
                oninput: (e) => {
                    q.setNames(e.target.value, middle, last);
                },
            }))),
        m(LineInputBlock,
            'Enter middle name',
            m('input', harness('middle-name-in-full-name-input', {
                value: middle,
                oninput: (e) => {
                    q.setNames(first, e.target.value, last);
                },
            }))),
        m(LineInputBlock,
            m('span', 'Enter last name', m('i.fg-danger', '*')),
            m('input', harness('last-name-in-full-name-input', {
                value: last,
                oninput: (e) => {
                    q.setNames(first, middle, e.target.value);
                },
            }))));
    },
};


export default function FullNameQuestion() {
    const _state = {
        first: '',
        middle: '',
        last: '',
        view: Form,

        setNames(first, middle, last) {
            _state.first = first;
            _state.middle = middle;
            _state.last = last;
        },

        encode() {
            return {
                [_state.attrType]: {
                    shareWith: _state.shareWith,
                    value: {
                        $objectType: 'Name',
                        first: _state.first,
                        middle: _state.middle,
                        last: _state.last,
                    },
                },
            };
        },

        isValid() {
            return _state.first.length > 0 && _state.last.length > 0;
        },
    };

    return _state;
}
