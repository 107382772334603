import { logger, router, spa } from '#/browser-framework';
import { doesAttrTypeRepresentIdScan } from '#/evident-attributes/attrTypes';

import Address from './forms/Address';
import AcademicProvider from './forms/AcademicProvider';
import Birthday from './forms/Birthday';
import BulkEmail from './forms/BulkEmail';
import Consent from './forms/Consent';
import Cpr from './forms/Cpr';
import DriversLicenseState from './forms/DriversLicenseState';
import ExternalProvider from './forms/ExternalProvider';
import FileSetUpload from './forms/FileSetUpload';
import FullName from './forms/FullName';
import IA from './forms/IndividualizedAssessment';
import BarLicenseDetails from './forms/BarLicenseDetails';
import IdScan from './forms/IdScan';
import MmcReferenceNum from './forms/MmcReferenceNum';
import PastName from './forms/PastName';
import PhoneNumber from './forms/PhoneNumber';
import Selfie from './forms/Selfie';
import SingleLineText from './forms/SingleLineText';
import Ssn from './forms/Ssn';

import { QuestionComponent, InterviewCard } from '#/ido-lib/views/Layouts';

import { submitWebAttributes } from '#/ido-lib/idoWebClient';

const ALL_QUESTIONS_BY_NAME = {
    AcademicProvider,
    Address,
    BarLicenseDetails,
    Birthday,
    BulkEmail,
    Consent,
    Cpr,
    DriversLicenseState,
    ExternalProvider,
    FileSetUpload,
    FullName,
    IA,
    IdScan,
    MmcReferenceNum,
    PastName,
    PhoneNumber,
    Selfie,
    SingleLineText,
    Ssn,
};


function submitQuestion(componentInterface, payload, {
    continueRoute = '/overview',
} = {}) {
    const toSend = Object.entries(payload).reduce((p, [k, v]) => {
        return Object.assign(p, {
            [k]: Object.assign(v, {
                shareWith: (Array.isArray(v.shareWith))
                    ? v.shareWith
                    : [],
            }),
        });
    }, {});

    const vowCompletion = (deploy.WEB_PUBLIC_DEVELOPER_MODE)
        ? Promise.resolve(logger.info(payload))
        : submitWebAttributes(toSend);

    componentInterface.submitting = true;
    spa.redraw();

    return vowCompletion.then(() => {
        componentInterface.complete = true;

        if (continueRoute) {
            router.go(continueRoute);
        }
    }).catch((e) => {
        componentInterface.submitting = false;

        spa.viewModel.errors.showError('submission-failed');

        throw e;
    }).finally(() => {
        componentInterface.submitting = false;
    });
}

function attributeTypeToFactory(attrType) {
    if (doesAttrTypeRepresentIdScan(attrType)) {
        return IdScan;
    }

    if (attrType.startsWith('consent')) {
        return Consent;
    }

    if (attrType.startsWith('education.postsecondary.academic_institution')) {
        return AcademicProvider;
    }

    if (attrType.startsWith('background.criminal.individualized_assessment')) {
        return IA;
    }

    return ({
        'cpr.input': Cpr,
        'business.full_address': Address,
        'education.postsecondary.academic_institution': AcademicProvider,
        'identity_assurance.address_verification': ExternalProvider,
        'core.fullname': FullName,
        'core.pastname': PastName,
        'core.ssn': Ssn,
        'core.dateofbirth': Birthday,
        'identity.phonenumber': PhoneNumber,
        'driverslicense.state': DriversLicenseState,
        'license.watercraft.us.coast_guard.mmc.reference.number': MmcReferenceNum,
        'identity_assurance.proofing.selfie_with_payment_card': Selfie,
        'identity_assurance.document_verification.selfie_to_document.selfie_image': Selfie,
        'business.employee_list.provided_email_addresses': BulkEmail,
        'license.professional.us.attorney_bar.license_details': BarLicenseDetails,
    })[attrType];
}

function inputTypeToFactory(inputType) {
    if (inputType === 'file' || inputType === 'image') {
        return FileSetUpload;
    }
}

/**
 * Modifies the componentInterface by merging properties from
 * inputAttrSpec, then overwrites the view to be a QuestionComponent.
 *
 * @param {Object} componentInterface
 * @param {function} componentInterface.isValid
 * @param {function} componentInterface.encode
 * @param {Object} componentInterface.view
 * @param {Object} [componentInterface.layout]
 * @param {boolean} [componentInterface.submitting]
 * @param {Object} inputAttrSpec
 * @returns {Object} componentInterface
 */
function applyDefaultQuestionInterface(componentInterface, inputAttrSpec) {
    return Object.assign(componentInterface, inputAttrSpec, {
        view: QuestionComponent(componentInterface.view),
        layout: componentInterface.layout || InterviewCard,
        maySubmit() {
            return componentInterface.isValid() && !componentInterface.submitting;
        },
        submit(opts) {
            return submitQuestion(
                componentInterface,
                componentInterface.encode(),
                opts,
            );
        },
    });
}

/**
 * @param {InputAttributeModel} inputAttribute
 */
export function inputAttrToQuestion(inputAttribute) {
    const promissory = (
        attributeTypeToFactory(inputAttribute.attrType) ||
        inputTypeToFactory(inputAttribute.metadata.inputType) ||
        SingleLineText
    );

    return Promise.resolve(promissory(inputAttribute)).then((component) => {
        const override = applyDefaultQuestionInterface(component, inputAttribute);

        override.questionType = Object.entries(ALL_QUESTIONS_BY_NAME).find(([, ctor]) => {
            return ctor === promissory;
        })[0];

        return override;
    });
}

export function makeWorstCase() {
    const allQuestions = Object.entries(ALL_QUESTIONS_BY_NAME).map(([name, promissory]) => {
        return [name, promissory, {
            // Metadata covers what is consumed by all questions.
            attrType: name,
            metadata: {
                icon: 'eye',
                title: name,
                description: `${name} step`,
                regionPrescribed: false,
                countryPrescribed: false,
                hidden: false,
                needSelfie: true,
                submissionAttrType: 'dummy.type',
            },
        }];
    });

    const promisesForQuestions = allQuestions.map(([name, promissory, ia]) => {
        return Promise.resolve(promissory(ia)).then((iface) => {
            const override = applyDefaultQuestionInterface(iface, ia);

            override.questionType = name;

            return override;
        });
    }, {});

    return Promise.all(promisesForQuestions).then((input) => {
        return {
            input,
            description: 'DEVELOPER MODE',
            summary: 'DEVELOPER MODE',
            balance: {
                amount: '5.00',
                currency: 'USD',
            },
        };
    });
}

export function actuate(curatedRequest) {
    const promisesForQuestions = Object.values(curatedRequest.input).map((ia) => {
        return inputAttrToQuestion(ia);
    });

    return Promise.all(promisesForQuestions).then((questions) => {
        return Object.assign({}, curatedRequest, { input: questions });
    });
}
