import _toggle from './toggle';
import _pointable from './pointable';
import _caseful from './caseful';



/*
 * Metadecorator that reuses decorated components. This protects
 * apps from needless redraws when components are decorated inline
 * in a Mithril component's view function.
 */
const antiAnonCache = new Map();

const antianon = (decorator) => {
    return (comp = 'div', ...args) => {
        if (antiAnonCache.has(comp)) {
            return antiAnonCache.get(comp);
        }

        const decorated = decorator(comp, ...args);

        antiAnonCache.set(comp, decorated);

        return decorated;
    };
};

export const pointable = antianon(_pointable);
export const toggle = antianon(_toggle);
export const caseful = antianon(_caseful);
