
import {m, tx, support, harness} from '#/browser-framework';
import Named from './Named';


function openFileBrowser(id) {
    const i = document.getElementById(id);

    if (i) {
        i.click();
    }
}

// Hidden <input> node that holds files staged for upload.
const FileInputNode = {
    openFileBrowser,
    view({
        attrs: {
            id,
            accept,
            acceptedExtensions = [],
            onchange,
            capture,
        },
    }) {
        const inputAttrs = harness('file-dropbox-input', {
            id,
            multiple: true,
            style: 'display:none',
            name: id,
            type: 'file',
            accept: (accept || acceptedExtensions.map((ext) => `.${ext}`).join(', ')),
            onchange(e) {
                e.preventDefault();
                onchange(Array.from(e.target.files || []));
                e.target.value = null;
            },
        });

        if (capture) {
            inputAttrs.capture = (capture === 'user' || capture === 'environment')
                ? capture
                : true;
        }

        return m('input', inputAttrs);
    },
};


// Uploader for mobile browsers
const MobileUploader = {
    view({
        attrs: {
            id,
            children,
        },
    }) {
        return m(`label.FileDropbox[for=${id}]`, {
            ontouchend(e) {
                e.preventDefault(); // Prevent ghost click
                openFileBrowser(id);
            },
            onclick(e) {
                e.preventDefault();
                openFileBrowser(id);
            },
        }, children, m('span.FileDropbox__icon', m(Named.Icon, { name: 'upload' })));
    },
};


const DesktopUploader = {
    draghover: false,
    view({
        attrs: {
            id,
            onchange,
        },
        state,
        children,
    }) {
        const classes = tx('FileDropbox', {
            draghover: state.draghover,
        });

        return m(`label${classes}[for=${id}]`, {
            ondragover(e) {
                e.preventDefault();
                state.draghover = true;
            },
            ondragleave(e) {
                e.preventDefault();
                state.draghover = false;
            },
            ondragend(e) {
                e.preventDefault();
                state.draghover = false;
            },
            ondrop(e) {
                e.preventDefault();
                const {files} = e.dataTransfer || e.target;

                state.draghover = false;
                onchange(Array.from(files));
            },
        },
        (children.length === 0)
            ? [
                m('span.FileDropbox__icon', m(Named.Icon, {name: 'upload'})),
                m('span.file-dropbox-text', 'Drag a file here or click to browse.'),
            ]
            : children);
    },
};


export default {
    FileInputNode,
    view({
        attrs: {
            id,
            capture,
            onchange,
            acceptedExtensions,
        },
        children,
    }) {
        const control = (support.isMobileBrowser)
            ? MobileUploader
            : DesktopUploader;

        return [
            m(control, {
                id,
                onchange,
            }, children),
            m(FileInputNode, {
                id,
                capture,
                onchange,
                acceptedExtensions,
            }),
        ];
    },
};
