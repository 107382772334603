import {m} from '#/browser-framework';
import EvidentWordmarkDark from './named/EvidentWordmarkDark';
import EvidentWordmarkLight from './named/EvidentWordmarkLight';
import GoogleLogo from './named/GoogleLogo';


const fontawesome = (name) => `i.fa.fa-${name}`;

const fontAwesomeMap = {
    'icon-alert': fontawesome('exclamation-triangle'),
    'icon-arrow-tailed-down': fontawesome('arrow-down'),
    'icon-arrow-tailed-up': fontawesome('arrow-up'),
    'icon-calendar': fontawesome('calendar'),
    'icon-camera': fontawesome('camera'),
    'icon-cancel': fontawesome('times-circle'),
    'icon-caret-down': fontawesome('caret-down'),
    'icon-check-circle-filled': fontawesome('check-circle'),
    'icon-check-circle-outlined': fontawesome('check-circle-o'),
    'icon-checked': fontawesome('check-square'),
    'icon-checkmark': fontawesome('check'),
    'icon-chevron-left': fontawesome('chevron-left'),
    'icon-chevron-up': fontawesome('chevron-up'),
    'icon-chevron-down': fontawesome('chevron-down'),
    'icon-chevron-right': fontawesome('chevron-right'),
    'icon-circle-outlined': fontawesome('circle-o'),
    'icon-copy': fontawesome('files-o'),
    'icon-code': fontawesome('code'),
    'icon-credit-card': fontawesome('credit-card'),
    'icon-crop': fontawesome('crop'),
    'icon-delete': fontawesome('trash'),
    'icon-download': fontawesome('download'),
    'icon-ellipsis': fontawesome('ellipsis-h'),
    'icon-envelope': fontawesome('envelope-o'),
    'icon-external-link': fontawesome('external-link'),
    'icon-eye': fontawesome('eye'),
    'icon-facebook-logo': fontawesome('facebook-official'),
    'icon-file': fontawesome('file'),
    'icon-info-circle': fontawesome('info-circle'),
    'icon-filter': fontawesome('filter'),
    'icon-flip-h': fontawesome('arrows-h'),
    'icon-flip-v': fontawesome('arrows-v'),
    'icon-folder-open': fontawesome('folder-open'),
    'icon-folder': fontawesome('folder'),
    'icon-gears': fontawesome('cogs'),
    'icon-home': fontawesome('home'),
    'icon-image': fontawesome('image'),
    'icon-keyboard': fontawesome('keyboard-o'),
    'icon-link': fontawesome('link'),
    'icon-lock': fontawesome('lock'),
    'icon-minus': fontawesome('minus'),
    'icon-no-eye': fontawesome('eye-slash'),
    'icon-pencil': fontawesome('pencil'),
    'icon-photo-id': fontawesome('id-card'),
    'icon-plus': fontawesome('plus'),
    'icon-rotate-left': fontawesome('rotate-left'),
    'icon-rotate-right': fontawesome('rotate-right'),
    'icon-search': fontawesome('search'),
    'icon-sliders': fontawesome('sliders'),
    'icon-sort-asc': fontawesome('angle-up'),
    'icon-sort-desc': fontawesome('angle-down'),
    'icon-tooltip-hotspot': fontawesome('question-circle-o'),
    'icon-unchecked': fontawesome('square-o'),
    'icon-upload': fontawesome('upload'),
    'icon-unlink': fontawesome('unlink'),
    'icon-user': fontawesome('user'),
    'icon-warning': fontawesome('exclamation-triangle'),
    'icon-x': fontawesome('times'),
    'vector-evident-wordmark-light': EvidentWordmarkLight,
    'vector-evident-wordmark-dark': EvidentWordmarkDark,
    'vector-google-logo': GoogleLogo,
};

const fallback = fontawesome('question-circle');


const Named = {
    view({attrs}) {
        return m('i.Named', m(fontAwesomeMap[attrs.name] || fallback, attrs));
    },
};

Named.Icon = {
    view: ({ attrs }) => m(Named, Object.assign({}, attrs, { name: `icon-${attrs.name}` })),
};

Named.Vector = {
    view: ({ attrs }) => m(Named, Object.assign({}, attrs, { name: `vector-${attrs.name}` })),
};

export default Named;
