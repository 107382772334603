import { m, harness } from '#/browser-framework';
import { pointable } from '#/browser-framework/comps';


export const ChangeDocumentControl = {
    view({
        attrs: {
            q,
        },
    }) {
        const [msg, other, instruction] = (q.usingPassport())
            ? ['No passport?', 'id_card', 'Use a driver\'s license instead.']
            : ['No card?', 'passport', 'Use a passport instead.'];

        return m('span.ChangeDocumentControl',
            msg,
            m.trust('&nbsp;'),
            m(pointable('a'), harness('change-document-type-link', {
                onpointerup() {
                    q.idType = other;
                },
            }), instruction));
    },
};
