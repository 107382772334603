import { m, tx, harness } from '#/browser-framework';
import { FileDropbox, Named, Spinner } from '#/browser-framework/comps';


// Click or touch this to upload one side of an ID.
export const LargeFormatIdSimplexControl = {
    view({
        attrs: {
            id,
            valid,
            onchange,
            dataUrl,
            'data-harness': _harness,
        },
        children,
    }) {
        const comp = tx('LargeFormatIdSimplexControl', {
            success: valid === true,
            fail: valid === false,
        });

        if (dataUrl === undefined) {
            return m(comp,
                m('.MobileSimplexPreview',
                    m(Spinner),
                    m('.MobileSimplexPreview__remove', {
                        onclick() {
                            onchange(null);
                        },
                    }, m(Named.Icon, { name: 'delete' }))),
                m('.LargeFormatIdSimplexControl__label', children));
        } else if (dataUrl === null) {
            return m(comp, harness(_harness), m(FileDropbox, {
                id,
                acceptedExtensions: ['jpg', 'jpeg', 'png'],
                onchange,
            }), m('.LargeFormatIdSimplexControl__label', children));
        } else {
            return m(comp,
                m('.MobileSimplexPreview', { style: `background-image: url(${dataUrl})` },
                    m('.MobileSimplexPreview__remove', {
                        onclick() {
                            onchange(null);
                        },
                    }, m(Named.Icon, { name: 'delete' }))),
                m('.LargeFormatIdSimplexControl__label', children));
        }
    },
};
