import EventEmitter3 from 'eventemitter3';

export function emitter({
    maxHistoryEntries = 10,
    tracer,
} = {}) {
    const E = new EventEmitter3();

    const history = [];
    const nativeEmit = E.emit;
    const _tracer = (typeof tracer === 'function')
        ? tracer
        : () => true;

    E.emit = function emit() {
        if (maxHistoryEntries > 0) {
            history.push([...arguments]);

            if (history.length > maxHistoryEntries) {
                history.shift();
            }
        }

        if (_tracer(...arguments)) {
            nativeEmit.apply(E, arguments);
        }
    };

    E.prescribe = function prescribe(f, { exclusive = true } = {}) {
        return (iface) => {
            for (const [event, handler] of f(iface)) {
                if (exclusive) {
                    E.removeAllListeners(event);
                }

                E.on(event, handler);
            }

            return iface;
        };
    };

    E.getHistory = function getHistory() {
        return history.slice(0);
    };

    return E;
}
