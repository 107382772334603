import { americentrism } from '#/universal-framework/countries';
import { m, router, spa, support } from '#/browser-framework';
import { getCountriesInRegion } from '#/ido-lib/idoWebClient';
import { delegationController } from '#/ido-lib/delegationController';

import { InterviewCard } from '#/ido-lib/views/Layouts';
import { LargeFormatDelegation } from '#/ido-lib/views/LargeFormatDelegation';
import { PendingScreen } from '#/ido-lib/views/StatusScreens';

import { LargeFormatIdScanForm } from './views/LargeFormatIdScanForm';
import { MobileIdScanForm } from './views/MobileIdScanForm';
import { MobileDocumentSelection } from './views/MobileDocumentSelection';
import { passportScanner, duplexScanner } from './scanners';



const ActiveView = {
    view({
        attrs,
    }) {
        return m(attrs.q.workflowView, attrs);
    },
};


function createIdScanQuestion({
    countries,
    inputAttr,
}) {
    const {
        attrType,
        metadata,
        shareWith,
    } = inputAttr;

    const plugin = {};


    Object.assign(plugin, {
        countries: americentrism(countries),
    });

    // Keys in scanners are meant to match possible values of doc types from the curation stage.
    plugin.scanners = {};
    plugin.scanners.passport = passportScanner();
    plugin.scanners.id_card = plugin.scanners.drivers_license = duplexScanner();

    plugin.delegate = delegationController(attrType);
    plugin.idType = metadata.documentPrescribed || 'id_card';
    plugin.country = metadata.countryPrescribed;
    plugin.mayChangeDocumentType = !metadata.documentPrescribed;

    plugin.view = ActiveView;
    plugin.workflowView = (support.isMobileBrowser)
        ? (countries.length > 0)
            ? MobileDocumentSelection
            : MobileIdScanForm
        : LargeFormatIdScanForm;

    plugin.getScanner = function getScanner() {
        return plugin.scanners[plugin.idType];
    };

    plugin.usingPassport = function usingPassport() {
        return plugin.scanners[plugin.idType].pages.length === 1;
    };

    plugin.countrySelected = function countrySelected() {
        return Boolean(plugin.country);
    };

    plugin.delegateToMobile = spa.redrawAfter(() => {
        return plugin.delegate.sendText().then(() => {
            plugin.workflowView = LargeFormatDelegation;
        });
    });

    plugin.cancelDelegation = function cancelDelegation() {
        router.go('/overview');
        spa.$window.location.reload();
    };

    plugin.encode = function encode() {
        const partial = plugin.getScanner().encode(
            plugin.idType,
            metadata.regionPrescribed,
            metadata.countryPrescribed,
            metadata.isLegacy,
        );

        const metadataSubmission = {
            [metadata.docTypeAttr]: {
                shareWith,
                value: {
                    country: plugin.country,
                    id_type: plugin.idType,
                },
            },
        };

        return Object.entries(partial).reduce((p, [k, v]) => {
            p[k] = Object.assign({}, v, {shareWith});

            return p;
        }, (metadata.isLegacy)
            ? {}
            : metadataSubmission);
    };

    plugin.isValid = function isValid() {
        return plugin.getScanner().isValid() && plugin.countrySelected();
    };

    plugin.sendId = function sendId() {
        return plugin.getScanner()
            .weakVerification()
            .then(() => {
                spa.setView(PendingScreen, {
                    layout: InterviewCard,
                });

                return plugin.submit({
                    continueRoute: null,
                });
            })
            .then(() => {
                const selfieAttrType = 'identity_assurance.document_verification.selfie_to_document.selfie_image';
                const q = spa.viewModel.interview.interviewModel.questions.find(({ attrType: a }) => selfieAttrType === a);

                if (metadata.needSelfie && q) {
                    q.metadata.hidden = false;

                    router.go(`/question/${selfieAttrType}`);
                } else {
                    router.go('/overview');
                }
            });
    };

    return plugin;
}

export default function idCardScan(inputAttr) {
    const { regionPrescribed, countryPrescribed } = inputAttr.metadata;

    const vowContent = (deploy.WEB_PUBLIC_DEVELOPER_MODE)
        ? Promise.resolve([['US', 'USA'], ['CA', 'Canada']])
        : (regionPrescribed && countryPrescribed)
            ? Promise.resolve([])
            : getCountriesInRegion(regionPrescribed);

    return vowContent.then((countries) => createIdScanQuestion({
        inputAttr,
        countries,
    }));
}
