import { identity, lets, contract, type } from './functions';

/*
[
    ['CA', 'Canada'],
    ['US', 'United States of America'],
    ...
]
*/
const COUNTRY_LIST = type(({ and, arrayOf, string }) =>
    arrayOf(and(arrayOf(string), (a) => a.length === 2)));

export const loadCountryData = () =>
    import('./data/countries.json');

export const findByCode =
    (countries, needle) =>
        countries.find(([code]) =>
            code.toLowerCase() === needle);

// Places U.S. and Canada up at beginning of list of countries, and alphabetize the rest.
export const americentrism =
    contract(
        (countries) =>
            lets([findByCode(countries, 'us'), findByCode(countries, 'ca')],
                (us, canada) =>
                    [us, canada]
                        .filter(identity)
                        .concat(countries
                            .filter((ref) => ref !== us && ref !== canada)
                            .sort(([, a], [, b]) => a.localeCompare(b)))),
        () => ({
            domain: [COUNTRY_LIST],
            range: COUNTRY_LIST,
        }));
