import load from '#/browser-framework/loader';


let _stripeApi;

export const loadStripe = ($window) => ((_stripeApi)
    ? Promise.resolve(_stripeApi)
    : load('https://js.stripe.com/v3/').then(() => {
        _stripeApi = $window.Stripe(
            deploy.WEB_PUBLIC_STRIPE_PUBLIC_KEY);

        return _stripeApi;
    }));

export const createToken = (stripe, cardNumberNode, cardHolderName) => stripe
    .createToken(cardNumberNode, { name: cardHolderName })
    .then(({ error, token }) => ((error)
        ? Promise.reject(error)
        : token));
