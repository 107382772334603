import { definition, exception } from '#/universal-framework/errors';


export const NotReady = definition(exception, {
    reason: 'not-ready',
    message: 'Cannot pay due to incomplete data or pending transaction',
});

export const UnknownField = definition(exception, {
    reason: 'unknown-field',
    message: 'Unaccounted field-level param {param}',
    config: {
        format: true,
    },
});

export const ValidationFailed = definition(exception, {
    reason: 'validation-failed',
    message: 'Invalid user input: {param}',
    config: {
        format: true,
    },
});
