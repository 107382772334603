import m from 'mithril';

import { Named } from '#/browser-framework/comps';
import { bem } from '#/browser-framework/taxonomy';


const btx = bem`
ChipList
    validChip
    invalidChip
    hiddenInput
    pseudoInput
`;


export default {
    view({
        attrs: {
            chips,
            inputValue,
            prompt,
            onChipClose,
            handleInput,
            isValid,
            handleKeyPress,
            handleBlur,
        },
    }) {
        return (m(btx.block, {
            onclick: () => {
                const input = document.querySelector(btx.hiddenInput);
                if (input) {
                    input.focus();
                }
            },
        },
        chips.map((chip, idx) => {
            return m('span', {
                class: isValid(chip) ? 'ChipList__validChip' : 'ChipList__invalidChip',
            }, chip,
            m(Named.Icon, {
                name: 'x',
                onclick: () => {
                    onChipClose(idx);
                },
            }));
        }),
        document.activeElement === document.querySelector(btx.hiddenInput) || chips.length
            ? m(btx.pseudoInput, inputValue)
            : m('span.pseudoPlaceholder', prompt),
        m(`input${btx.hiddenInput}`, {
            value: inputValue,
            oninput: (e) => {
                e.preventDefault();
                handleInput(e);
            },
            onkeypress: (e) => {
                handleKeyPress(e);
            },
            onblur: (e) => {
                handleBlur(e);
            },
        })));
    },
};
