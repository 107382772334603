import { m } from '#/browser-framework';


export default function caseful(compIfTrue, compIfFalse, predicate) {
    return {
        view({ attrs }) {
            return m(predicate(attrs) ? compIfTrue : compIfFalse, attrs);
        },
    };
}
