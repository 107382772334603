import { harness, m, logger, spa, errors, router } from '#/browser-framework';

import { SingleCol } from '#/ido-lib/views/Layouts';
import { ErrorScreen } from '#/ido-lib/views/StatusScreens';

export const DirectToOriginalLink = errors.definition(null, {
    reason: 'direct-to-original-link',
    extra: {
        displaySummary: 'Please use your original link.',
        displayMessage: `This page is unavailable. Please return to your phone
        or email and click on the link originally sent.`,
    },
});

export const LocalStorageRequired = errors.definition(null, {
    reason: 'local-storage-required',
    extra: {
        displaySummary: 'Local storage required.',
        displayMessage: `You need to enable third-party data in your browser settings
        to use this application. If you are using private mode in Safari, please return
        with private mode disabled.`,
    },
});

export const PageNotFound = errors.definition(null, {
    reason: 'page-not-found',
    extra: {
        displaySummary: 'That doesn\'t seem to be here.',
        displayMessage: 'The page you have requested was not found.',
    },
});

export const SubmissionFailed = errors.definition(null, {
    reason: 'submission-failed',
    extra: {
        displaySummary: 'Submission failed',
        displayMessage: `We could not submit your response at this time.
        Please try again in a few minutes.`,
    },
});

export const UnauthorizedIdo = errors.definition(null, {
    reason: 'unauthorized-ido',
    extra: {
        displaySummary: 'Unauthorized',
        displayMessage: `Please make sure to click the link sent to you
        in a notification to start your session.`,
    },
});

export const RprNotFound = errors.definition(null, {
    reason: 'rpr-not-found',
});


const ErrorPage = {
    view({
        attrs: {
            status: {
                heading,
                body,
            },
        },
    }) {
        return m(ErrorScreen,
            m('h3', harness('ido-facing-error-screen-heading'), heading),
            m('p', harness('ido-facing-error-screen-message'), body));
    },
};

function errorFromExternalReason(reason) {
    const out = errors.exception();

    try {
        const parsed = JSON.parse(atob(reason));

        out.extra.displaySummary = parsed.heading;
        out.extra.displayMessage = parsed.detail;
        out.extra.parsed = parsed;
    } catch (e) {
        logger.error(e);
    }

    return out;
}

// Navigating to the error page is more for when you want to signal
// that the end-user's attempts has reached a terminal state.
// If you do not want to mutate the user's navigation history,
// use showError() instead.
export function goToErrorPage() {
    router.go('/error');
}


// Displays an error without navigating.
export function showError(code = null) {
    if (code) {
        spa.error = errors.exception({
            reason: code,
        });
    }

    let {
        extra: {
            displaySummary: heading,
            displayMessage: body,
        },
    } = errors.Unexpected();

    if (spa.error) {
        heading = spa.error.extra.displaySummary || heading;
        body = spa.error.extra.displayMessage || body;
    }

    spa.status = {
        heading,
        body,
    };

    spa.setView(ErrorPage, {
        layout: SingleCol,
    });
}

export function errorController() {
    /*
     * Displays an error page set by the requester.
     * Usage: /error?reason=<string:errorcode>
     */
    router.on('Error', '/error', (_, { reason }) => {
        if (reason) {
            spa.error = errorFromExternalReason(reason);
        }

        let rejectSession;

        try {
            rejectSession = spa.error.extra.parsed.auth_error === 'OauthInvalidRequestContext';
        } catch (e) {
            rejectSession = false;
        }

        if (rejectSession) {
            spa.viewModel.snackbar.display(spa.error.extra.displayMessage, false);
            spa.viewModel.user.googleLogOut();
        } else {
            showError();
        }
    });

    return {
        showError,
        goToErrorPage,
    };
}
