import clonedeep from 'lodash.clonedeep';

/**
 * @module store
 * The store mediates access to the in memory application state.
 */
/**
 * @typedef {Object} DispatchedContent
 * @property {Object} moduleState state for the module
 * @property {Object} globalState all of the state in the store
 * @property {Object} payload data used to update the state
 */

/**
 * A function that behaves as an AcitonHandler
 * @callback actionHandler
 * @param {DispatchedContent} dispatchedContent
 */

/**
 * @typedef {Object} ModuleHandler
 * @property {String} moduleKey a unique key for the module
 * @property {actionHandler} actionHandler
 */

/**
 * @typedef {Object} ModuleStore
 * @property {String} key a unique key for the module
 * @property {Object} state state for the module
 * @property {Object.<string, actionHandler>} actionHandlers map of action names to actionHandlers
 */

/**
 * @type {Object}
 */
const _data = {};

/**
 * @type {Object.<string, ModuleHandler>}
 */
const _handlerMap = {};

/**
 * @type {Store}
 */
let _INSTANCE;

class Store {
    /**
     * This constructor enforces that the Store class is singleton.
     */
    constructor() {
        if (_INSTANCE === undefined) {
            // eslint-disable-next-line consistent-this
            _INSTANCE = this;
        }
        return _INSTANCE;
    }

    /**
     * @param {String} key the module key
     * @returns {Object} a copy of the data for the key
     */
    getItem(key) {
        // Objects should be full immutable
        // however we rely on convention here
        return Object.freeze(clonedeep(_data[key]));
    }

    /**
     * @param {String} name
     * @param {Object} payload
     */
    dispatch(name, payload) {
        const moduleHandler = _handlerMap[name];

        moduleHandler.actionHandler({
            moduleState: _data[moduleHandler.moduleKey],
            globalState: _data,
            payload });
    }

    /**
     * @param {ModuleStore} module
     */
    addModule(module) {
        _data[module.key] = clonedeep(module.state);
        Object.keys(module.actionHandlers).forEach((actionName) => {
            if (actionName in _handlerMap) {
                throw Error(`Duplicate Action: ${actionName}`);
            }

            _handlerMap[actionName] = {
                moduleKey: module.key,
                actionHandler: module.actionHandlers[actionName],
            };
        });
    }

    /**
     * @param {ModuleStore} module
     */
    deleteModule(module) {
        delete _data[module.key];
        Object.keys(module.actionHandlers).forEach((actionName) => {
            delete _handlerMap[actionName];
        });
    }
}

/**
 * Get a singleton instance of the store.  This is the preferred method
 * to obtain the singleton instance.
 *
 * @returns {Store}
 */
Store.getInstance = () => {
    if (!_INSTANCE) {
        _INSTANCE = new Store();
    }

    return _INSTANCE;
};


export default Store;
