import { m } from '#/browser-framework';
import { Dropdown } from '#/browser-framework/comps';

import LineInputBlock from '#/ido-lib/views/LineInputBlock';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';

import US_SUBDIVISIONS from '#/ido-lib/states.json';


const STATES_AND_TERRITORIES = US_SUBDIVISIONS
    .sort(([a], [b]) => a.localeCompare(b));


const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            barNumber,
            yearAdmitted,
        } = q;

        return m(DefaultQuestionForm, {
            maySubmit: q.isValid(),
            onSubmit: q.submit,
            onLastQuestion,
        },
        m(LineInputBlock,
            m('span', 'Jurisdiction'),
            m(Dropdown, {
                placeholder: 'Select a Jurisdiction',
                id: 'dl-state',
                disabled: false,
                value: q.jurisdictionStateIndex,
                options: STATES_AND_TERRITORIES.map(([, expanded]) => {
                    return expanded;
                }),
                select(i, a) {
                    q.jurisdictionStateIndex = i;
                    // sets jurisdictionState to 2 letter code
                    const LETTER_CODE_INDEX = 1;
                    q.jurisdictionState = STATES_AND_TERRITORIES[a][LETTER_CODE_INDEX];
                },
            })),
        m(LineInputBlock,
            m('span', 'Year admitted to practice law'),
            m('input', {
                value: yearAdmitted,
                oninput: (e) => q.setYearAdmitted(e.target.value),
            })),
        m(LineInputBlock,
            m('span', 'Attorney License Number (if applicable)'),
            m('input', {
                value: barNumber,
                oninput: (e) => q.setBarNumber(e.target.value),
            })));
    },
};

export default function barLicenseDetails() {
    const _state = {
        jurisdictionStateIndex: 0,
        jurisdictionState: '',
        yearAdmitted: null,
        barNumber: null,
        view: Form,

        // the jurisdiction state index is updated in the component
        setBarNumber(barNumber) {
            _state.barNumber = barNumber.trim();
        },

        // Year gets submitted as an integer, but in state it is treated as a string and we convert on submission
        setYearAdmitted(yearAdmitted) {
            _state.yearAdmitted = yearAdmitted.trim();
        },
        encode() {
            return {
                [_state.attrType]: {
                    shareWith: _state.shareWith,
                    value: {
                        $objectType: 'AttorneyLicenseDetails',
                        jurisdiction: _state.jurisdictionState,
                        license_number: _state.barNumber || '',
                        year_admitted: parseInt(_state.yearAdmitted, 10),
                    },
                },
            };
        },

        isValid() {
            // The regex matches any year between 1900 and 2099.
            // The user must enter the year admitted to the bar and the corresponding state to complete the question.
            return _state.jurisdictionStateIndex > 0
                && (/^(19|20)\d{2}$/.test(_state.yearAdmitted));
        },
    };

    return _state;
}
