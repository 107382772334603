import {Button} from '#/browser-framework/comps';
import { m, harness } from '#/browser-framework';


export const SecondaryButton = {
    view({
        attrs: {
            disabled,
            onpress,
            'data-harness': dh,
        },
        children: label,
    }) {
        const attrs = {
            flags: {
                secondary: true,
            },
            disabled,
            onpress,
        };

        return m(Button, dh ? harness(dh, attrs) : attrs, (label.length > 0)
            ? label
            : 'Do this later');
    },
};

export const PrimaryButton = {
    view({
        attrs: {
            onLastQuestion,
            disabled,
            onpress,
            'data-harness': dh,
        },
        children: label,
    }) {
        const attrs = {
            flags: {
                primary: true,
            },
            disabled,
            onpress,
        };

        attrs.onpress = onpress;

        const primaryButtonText = (function getPrimaryButtonText() {
            let text;
            if (onLastQuestion && onLastQuestion()) {
                text = 'Submit and Complete';
            } else if (label.length > 0) {
                text = label;
            } else {
                text = 'Submit';
            }
            return text;
        })();

        return m(Button,
            dh
                ? harness(dh, attrs)
                : attrs,
            primaryButtonText);
    },
};

export const Controls = {
    view({ children }) {
        return m('.InterviewCard__controls', children);
    },
};
