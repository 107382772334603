// See hashRouter.spec.js for docs and usage examples.
import { events as emitter } from '#/browser-framework';

export function hashRouter($window) {
    let _titleBrand = '';
    let _titleSeparator = '';
    let _popState = {};
    let _fallbackRoute;
    let _isBackButtonBlocked = false;
    let _routePreloader;

    const PREFIX = '#!';
    const DEFAULT_ROUTE_TABLE = {
        '/': {
            title: 'Home',
            handler: () => { },
            matcher: /^\/?$/,
        },
    };
    const _routeTable = Object.assign({}, DEFAULT_ROUTE_TABLE);
    const hasHistoryApi = typeof $window.history.pushState === 'function';

    /**
     * Returns the route portion from the current url's hash
     * @returns {string}
     */
    function here() {
        if ($window.location.hash.startsWith(PREFIX)) {
            const route = decodeURIComponent($window.location.hash.slice(PREFIX.length));

            return (route)
                ? (route[0] === '/')
                    ? route
                    : `/${route}`
                : '/';
        } else {
            return null;
        }
    }

    function setPageTitle(title) {
        $window.document.title = (title)
            ? `${_titleBrand}${_titleSeparator}${title}`
            : `${_titleBrand}`;
    }

    function getTableEntryByRoute(fragment) {
        const table = Object.values(_routeTable);
        if (_fallbackRoute) {
            table.push(_fallbackRoute);
        }
        return table
            .find(({ matcher }) => {
                return matcher.test(fragment);
            });
    }

    function buildQueryString(obj = {}) {
        return Object.entries(obj).reduce((p, [k, v]) =>
            `${p}${(p === '') ? '' : '&'}${k}=${encodeURIComponent(v)}`, '');
    }

    /**
     * Rejects a route that is waiting for preloader
     * to be completed
     */
    function rejectPendingRoute() {
        if (_routePreloader) {
            _routePreloader.catch(() => {});
            _routePreloader.reject();
        }
    }

    /**
     * Register a Promise, which will have to be resolved
     * before go function will be fully executed
     * @param {ExtPromise} loader .
     */
    function addRoutePreloader(loader) {
        rejectPendingRoute();
        _routePreloader = loader;
    }

    /**
     * Navigate to the route.
     * @param {string} route The route to navigate to.
     * @param {object} [options]
     * @param {boolean} [options.replace=false] Whether or not to replace history state.
     * @param {Object} [options.query] Query params for building the url.
     * @param {Object} [options.state={}]  The window.history state.
     */
    async function go(route, { replace = false, query, state = {} } = {}) {
        // explicit call of go method automatically enables
        // native back button browser capability
        _isBackButtonBlocked = false;
        emitter.emit('route-change', route);

        if (_routePreloader) {
            try {
                await _routePreloader;
            } catch (e) {
                return;
            }
            _routePreloader = undefined;
        }

        if (route[0] !== '/') {
            throw new Error('You can only navigate to routes starting with `/`.');
        }

        const result = getTableEntryByRoute(route);

        if (!result) {
            throw new Error(`${route} is not in the route table.`);
        }

        const { title } = result;
        let goal = PREFIX + route;

        if (typeof query === 'object') {
            const queryString = buildQueryString(query);

            goal = `?${queryString}${goal}`;
        }

        if (hasHistoryApi) {
            $window.onpopstate({ state });

            setPageTitle(title);

            if (replace) {
                $window.history.replaceState(state, $window.document.title, goal);
            } else {
                $window.history.pushState(state, $window.document.title, goal);
            }
        } else {
            // Side-effect: Query string changes refresh the page.
            $window.location.assign(goal);
        }
    }

    // Registers a route,with clobbering.
    function on(title, route, handler) {
        if (route[0] === '/') {
            // Replace special sings in routes with RegExp patterns:
            // ':route...' > '(.*?)'
            // ':route' > '([^\\/]+)'
            const sanitized = route
                .replace(/:[^\/]+?\.{3}/g, '(.*?)')
                .replace(/:[^\/]+/g, '([^\\/]+)');

            _routeTable[route] = {
                matcher: new RegExp(`^${sanitized}\/?$`),
                title,
                handler,
            };
        } else {
            throw new Error('Routes start with `/`.');
        }
    }

    function registerFallback(title, handler) {
        _fallbackRoute = {
            matcher: /^\/[\S]+$/,
            title,
            handler,
        };
    }

    function procure() {
        const path = here();
        const result = getTableEntryByRoute(path);
        const out = {
            handler: null,
            args: [],
            state: _popState,
            kwargs: ($window.location.search)
                ? $window.location.search.slice(1).split('&').reduce((p, c) => {
                    const [k, v] = c.split('=');

                    p[k] = decodeURIComponent(v);

                    return p;
                }, {})
                : {},
            title: '',
        };

        if (result) {
            const { handler, matcher, title } = result;

            out.handler = handler;
            out.title = title;

            path.replace(matcher, (...replaceArgs) => {
                out.args = replaceArgs
                    .slice(1, -2)
                    .reduce((p, c) => p.concat(c.split('/')), [])
                    .map(decodeURIComponent);
            });
        }

        return out;
    }

    function emit() {
        const res = procure();

        if (res) {
            res.handler(res.args, res.kwargs);
            setPageTitle(res.title);
        }
    }

    function makeRoute(...tokens) {
        return `#!${tokens.map((t) => ((t) ? `/${$window.encodeURIComponent(t)}` : '')).join('')}`;
    }

    function disableBrowserBackButton() {
        _isBackButtonBlocked = true;
    }

    // Initialization routine used to brand navigation endpoints
    // and select a fallback location in the event one is not
    // already available on load time.
    function start({
        titleBrand = '',
        titleSeparator = '',
        fallback,
    }) {
        _titleBrand = titleBrand;
        _titleSeparator = titleSeparator;

        if (hasHistoryApi) {
            let _timer;
            $window.onpopstate = (e) => {
                if (_isBackButtonBlocked) {
                    // skip routing and go back to the previous path.
                    history.go(1);
                    return;
                }
                _popState = e.state;

                _timer = _timer || setTimeout(() => {
                    _timer = null;
                    emit();
                });
            };
        } else {
            $window.onhashchange = emit;
        }

        const route = here();

        if (!route && typeof fallback === 'function') {
            fallback();
        } else {
            go(route);
        }
    }

    const iface = {
        buildQueryString,
        addRoutePreloader,
        go,
        start,
        makeRoute,
        procure,
        registerFallback,
        rejectPendingRoute,
        setPageTitle,
        disableBrowserBackButton,
        on,
        here,
    };

    iface.referrer = here();

    return iface;
}
