import {m} from '#/browser-framework';


export default {
    view({attrs: {id, checked, disabled = false, onchange}}) {
        const checkopts = {
            id,
            checked,
            disabled,
            type: 'checkbox',
            onchange: (e) => {
                onchange(e.target.checked);
            },
        };

        return m('.Toggle',
            m('input.Toggle__input', checkopts),
            m('label.Toggle__button', {for: id}));
    },
};
