import { m, errors } from '#/browser-framework';


export default {
    view({ attrs: { error = errors.exception() }}) {
        return m('.WeddingCake',
            m('h1', error.extra.displaySummary),
            m('p', error.extra.displayMessage));
    },
};
