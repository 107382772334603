import { exception } from '#/universal-framework/errors';
import { fileToDataUrl } from '#/browser-framework/blobs';


export function createDocumentPage(content) {
    return {
        $objectType: 'DocumentPage',
        content,
    };
}

export function createDocument(title, description, content = []) {
    return {
        $objectType: 'CapturedDocument',
        content,
        description,
        title,
    };
}

export function createIdDocumentMetadata(country, idType) {
    return {
        country,
        id_type: idType,
    };
}

export function createBinaryDataFromDataUrl(dataUrl, metadata = { cropped: false }) {
    try {
        if (typeof metadata !== 'object' || typeof dataUrl !== 'string') {
            throw new Error();
        }

        const data = dataUrl.split(',')[1];
        const mimeType = dataUrl.match(/^data:([^;]+)/)[1];

        return {
            $objectType: 'BinaryData',
            metadata,
            data,
            mime_type: mimeType,
        };
    } catch (e) {
        throw exception({
            reason: 'base/bad-arguments',
        });
    }
}

export function createDataUrlFromBinaryData({ data, mime_type: mt }) {
    return `data:${mt};base64,${data}`;
}

export function createBinaryDataFromFile(file, metadata = { cropped: false }) {
    return fileToDataUrl(file).then((dataUrl) => {
        return createBinaryDataFromDataUrl(dataUrl, metadata);
    });
}

export function createDocumentPageFromDataUrl(dataUrl) {
    return createDocumentPage(createBinaryDataFromDataUrl(dataUrl));
}

export function binaryDataToDataUrl(bd) {
    const { data, mime_type: mt } = bd;

    return `data:${mt};base64,${data}`;
}
