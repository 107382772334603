import {m, tx} from '#/browser-framework';

class SnackbarState {
    constructor(delay) {
        this.delay = delay ? delay : 4000;
        this.success = undefined;
    }

    /**
     * @param content
     * @param {Boolean} [success] - true for success,
     * false for failure, undefined for neutral
     */
    display(content, success) {
        this.success = success;
        this.content = content;
    }

    get content() {
        return this._content
            ? this._content
            : null;
    }

    set content(content) {
        this._content = content;
        m.redraw();

        if (content.length > 0 && this.delay) {
            clearTimeout(this._timer);

            this._timer = setTimeout(() => {
                this._content = null;
                m.redraw();
            }, this.delay);
        }
    }

    get open() {
        return Boolean(this._content);
    }
}


class Snackbar {
    constructor({ attrs: model }) {
        this.snackbarModel = model
            ? model
            : Snackbar.makeViewModel();
    }

    view() {
        return m(tx('Snackbar', {
            'Snackbar--open': this.snackbarModel.open,
            'Snackbar--success': this.snackbarModel.success === true,
            'Snackbar--failure': this.snackbarModel.success === false,
        }), this.snackbarModel.content);
    }

    /**
     * Static method, that returns state object
     * @param {Number} [delay] - time in milliseconds, after which snackbar will disappear
     * @return {SnackbarState}
     */
    static makeViewModel(delay) {
        // A simple snackbar shows only a string.
        return new SnackbarState(delay);
    }
}

export default Snackbar;
