import m from 'mithril';

import { tx } from '../taxonomy';
import Named from './Named';
import { bem } from '#/browser-framework';

const collapsibleBem = bem`
    Collapsible
        body
        heading
        label
`;

const Collapsible = {
    view({
        attrs: {
            label,
            inAlertState,
            iconWhenOpen = m(Named.Icon, { name: 'chevron-down' }),
            iconWhenClosed = m(Named.Icon, { name: 'chevron-right' }),
            ontoggle,
            open,
        },
        children,
    }) {
        const toggle = (e) => {
            e.preventDefault();
            ontoggle(open);
        };

        return m(
            tx(collapsibleBem.block, {
                '.Collapsible--open': open,
                '.Collapsible--alert': inAlertState,
            }),
            m(collapsibleBem.heading, {
                onclick: toggle,
                ontouchend: toggle,
            },
            inAlertState ? m(Named.Icon, { name: 'alert' }) : null,
            m(`span${collapsibleBem.label}`, label),
            (open) ? iconWhenOpen : iconWhenClosed),
            (open) && m(collapsibleBem.body, children)
        );
    },
};

export default Collapsible;
