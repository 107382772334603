import { m, harness, validation} from '#/browser-framework';
import { ChipListForm, Button, ConsentCheckbox } from '#/browser-framework/comps';
import { DefaultQuestionForm } from '#/ido-lib/views/Layouts';


const ConfirmationCheckbox = {
    view({ attrs: { confirmed, onConfirmationChange } }) {
        return m(ConsentCheckbox, harness('consent-checkbox', {
            id: 'a-and-d',
            checked: confirmed,
            onchange: onConfirmationChange,
            cssclass: 'ConsentQuestion__acceptCheck',
        }), 'I certify that the information above is complete and accurate.');
    },
};

const Form = {
    view({
        attrs: {
            q,
            spa: {
                viewModel: {
                    interview: {
                        onLastQuestion,
                    },
                },
            },
        },
    }) {
        const {
            metadata: {
                description,
                title,
            },
            isValid,
            chipList,
            handleInput,
            handleKeyPress,
            handleBlur,
            deleteChip,
            deleteAllChips,
            encode,
            errorText,
            isConfirmed,
            onConfirmationChange,
        } = q;

        return m(DefaultQuestionForm, {
            maySubmit: isValid(),
            onSubmit() {
                q.submit(encode());
            },
            onLastQuestion,
        },
        m('.BulkEmailQuestion',
            m('.BulkEmailQuestion__form',
                m('h3', title),
                m('p', m.trust(description)),
                m(Button, {
                    flags: {
                        secondary: true,
                    },
                    onpress: () => deleteAllChips(),
                }, 'Clear Emails'),
                m(ChipListForm, {
                    chips: chipList,
                    inputValue: q.inputValue,
                    prompt: 'Add an email',
                    onChipClose: deleteChip,
                    isValid: validation.isEmail,
                    handleBlur,
                    handleInput,
                    handleKeyPress,
                }),
                m(ConfirmationCheckbox, {
                    confirmed: isConfirmed,
                    onConfirmationChange,
                }),
            ),
            q.showError && m('p.fg-danger', errorText)));
    },
};

export default function BulkEmail() {
    // This regular expression matches all characters that are normally not allowed
    // in email addresses. It exists to break out a list of emails with unknown and
    // even inconsistent delimiters. Since the list of characters allowed in the
    // local part of an email address is a superset of those allowed in the domain,
    // all characters not in the local list are matched.

    // You can learn more about the RFC email standard here:
    // https://tools.ietf.org/html/rfc3696#page-5

    const DELIMITER_REGEX = /[^a-zA-Z0-9\@\^\.\|\?\*\+\!\{\$#%&'\-\/=?_`}~]+/g;
    const _state = {
        view: Form,
        chipList: [],
        inputValue: '',
        isConfirmed: false,

        onConfirmationChange(state) {
            _state.isConfirmed = state;
        },
        deleteChip: (idx) => {
            _state.chipList.splice(idx, 1);
        },
        addChips: (chipContent) => {
            _state.chipList.push(...chipContent.split(DELIMITER_REGEX));
            _state.inputValue = '';
        },
        deleteAllChips: () => {
            _state.chipList = [];
        },
        handleKeyPress: (e) => {
            switch (e.keyCode) {
            case 13: // Enter
                if (_state.inputValue) {
                    e.preventDefault();
                    _state.chipList.push(..._state.inputValue.trim().split(DELIMITER_REGEX));
                    _state.inputValue = '';
                }
                break;
            default:
            }
        },
        handleInput: (e) => {
            _state.inputValue = e.target.value;
            if (e.target.value.match(DELIMITER_REGEX)) {
                _state.chipList.push(..._state.inputValue.trim().split(DELIMITER_REGEX).filter((string) => (string.length)));
                _state.inputValue = '';
            }
        },
        handleBlur: (e) => {
            const trimmedInput = e.target.value.trim();
            if (trimmedInput.length > 0) {
                _state.chipList.push(trimmedInput);
                _state.inputValue = '';
            }
        },
        encode: () => {
            const withoutBlanks = _state.chipList.filter((e) => e.length > 0);
            const withoutBlanksOrDuplicates = Array.from(new Set(withoutBlanks));

            return {
                [_state.attrType]: {
                    value: withoutBlanksOrDuplicates.join('\n'),
                    shareWith: _state.shareWith,
                },
            };
        },

        isValid: () => {
            for (const chip of _state.chipList) {
                if (!validation.isEmail(chip) || _state.inputValue) {
                    return false;
                }
            }
            return _state.isConfirmed;
        },
    };

    return _state;
}
